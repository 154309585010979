import React from 'react';
import { resolve } from "inversify-react";
import { RouterStore } from "mobx-react-router";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { OtcAdsType, OtcDeal, AppOtcAdPriceExtraPercentDirectionChoices } from "../../utils/graphql";
import { Api } from "../../utils/api";
import { processRequestError, divideNumberByPieces } from "../../utils/utilities";

import { Button } from "../../components/Button";

import { CoinBaze } from "../../stores/CoinBaze";
import { AuthStore } from "../../stores/AuthStore";

interface IOTCIndexProps extends WithTranslation {
}

interface IOTCIndexState {
    ads?: OtcAdsType;
    loading: boolean;
    loadingId?: string;
    deals: OtcDeal[];
}

class OTCIndex extends React.Component<IOTCIndexProps, IOTCIndexState> {
    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(RouterStore)
    declare protected readonly routerStore: RouterStore;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(CoinBaze)
    declare protected readonly coinBaze: CoinBaze;

    state: IOTCIndexState = {
        loading: false,
        deals: []
    }

    componentDidMount() {
        this.loadAds();

        setInterval(() => this.loadAds(), 2000);
    }

    loadAds = async () => {
        this.setState({ ads: await this.api.getOtcAds() });
    }

    loadDeals = async () => {
        this.setState({ deals: await this.api.getMyOtcDeals() });
    }

    onEdit = async (id: string) => {
        this.coinBaze.setEditAdFromIndex(id);
        this.routerStore.push(`/otc/ads/`);
    }

    onStartDeal = async (id: string) => {
        this.setState({ loading: true, loadingId: id });
        try {
            await this.loadDeals();
            if (this.state.deals.some((currentValue) => {
                return currentValue.ad?.id === id && (currentValue.state === 'CREATED' || currentValue.state === 'ACCEPTED' || currentValue.state === 'PAID');
            })) {
                let dealId;
                this.state.deals.forEach((currentValue) => {
                    if (currentValue.ad?.id === id) {
                        dealId = currentValue.id;
                    }
                })
                this.routerStore.push(`/otc/deals/${dealId}`);
                return;
            }
            const dealId = await this.api.otcStartDeal(id);
            this.routerStore.push(`/otc/deals/${dealId}`);
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false, loadingId: undefined });
        }
    }

    render() {
        const { ads, loading, loadingId } = this.state;
        const { t } = this.props;

        // console.log(ads?.buy.every((element) => element.status !== 'ACTIVE'));

        return (
            <div className="tabs__content active">
                <div className="main-content otc-index-content column">
                    <div className="main-block">
                        <h2 className="operation-title">{t('otc.index.withdraw-balance')}</h2>
                        <div className="operation operation_head">
                            <div className="operation__wrap">
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.seller')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.payment-method')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.description')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.commission')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.amount')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="operation-wrap row-head scroll-wrap">
                            {(ads?.buy.length) === 0 || ads?.buy.every((element) => element.status !== 'ACTIVE') ? 
                                    <div className="no-ads">
                                        {t('otc.index.no-ads')}
                                    </div>
                                : null    
                            }
                            {ads?.buy.filter(_i=>(_i.status==='ACTIVE')).map(ad => (
                                <div className="operation" key={ad.id}>
                                    <div className="operation__wrap">
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.seller')}</span>
                                            <span className="operation__value">{ad.user.name}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.payment-method')}</span>
                                            <span className="operation__value">{ad.paymentMethod}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.description')}</span>
                                            <span className="operation__value">{ad.description === '' ? `...` : ad.description}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.commission')}</span>
                                            {ad.priceExtraPercent !== "0.00" ?
                                                <>
                                                    <span
                                                    className={`operation__value ${ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'commission-customer' : ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'commission-creator' : null}`}
                                                    data-tooltip-id="commission-direction-tooltip"
                                                    data-tooltip-content={`${ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'Вы доплачиваете' : ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'Доплата вам' : null }`}>
                                                        {ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? '-' : null}{parseFloat(ad.priceExtraPercent) % 1000}%</span>
                                                    <Tooltip className="commission-tooltip" id="commission-direction-tooltip" />
                                                </> : 
                                                <span className="operation__value">0.0%</span>
                                            }
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.amount')}</span>
                                            <span className="operation__value">{divideNumberByPieces(ad.minSum)} - {divideNumberByPieces(ad.maxSum)} {ad.currency}</span>
                                        </div>
                                        <div className="operation__btn-wrap">
                                            {(ad.user?.id === this.authStore.profile?.id) ? 
                                                <Button
                                                    kind='edit'
                                                    disabled={loading}
                                                    loading={loadingId === ad.id}
                                                    onClick={() => this.onEdit(ad.id)}
                                                >
                                                    {t('otc.index.btn-edit')}
                                                </Button>
                                                : 
                                                <Button
                                                    kind='fourthly'
                                                    disabled={loading}
                                                    loading={loadingId === ad.id}
                                                    onClick={() => this.onStartDeal(ad.id)}
                                                >
                                                    {t('otc.index.btn-sell')}
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="main-block">
                        <h2 className="operation-title">{t('otc.index.top-up-balance')}</h2>
                        <div className="operation operation_head">
                            <div className="operation__wrap">
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.seller')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.payment-method')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.description')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.commission')}</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">{t('otc.index.amount')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="operation-wrap row-head scroll-wrap">
                            {(ads?.sell.length) === 0 || ads?.sell.every((element) => element.status !== 'ACTIVE')? 
                                    <div className="no-ads">
                                        {t('otc.index.no-ads')}
                                    </div>
                                : null    
                            }
                            {ads?.sell.filter(_i=>(_i.status==='ACTIVE')).map(ad => (
                                <div className="operation" key={ad.id}>
                                    <div className="operation__wrap">
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.seller')}</span>
                                            <span className="operation__value">{ad.user.name}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.payment-method')}</span>
                                            <span className="operation__value">{ad.paymentMethod}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.description')}</span>
                                            <span className="operation__value">{ad.description === '' ? `...` : ad.description}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.commission')}</span>
                                            <span className={`operation__value ${ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'commission-customer' : ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'commission-creator' : null}`} data-tooltip-id="commission-direction-tooltip" data-tooltip-content={`${ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'Вы доплачиваете' : ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'Доплата вам' : null }`}>{ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? '-' : null}{ad.priceExtraPercent}%</span>
                                            <Tooltip className="commission-tooltip" id="commission-direction-tooltip" />
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.index.amount')}</span>
                                            <span className="operation__value">{divideNumberByPieces(ad.minSum)} - {divideNumberByPieces(ad.maxSum)} {ad.currency}</span>
                                        </div>
                                        <div className="operation__btn-wrap">
                                            {(ad.user?.id === this.authStore.profile?.id) ? 
                                                <Button kind='edit' disabled={loading} loading={loadingId === ad.id} onClick={() => this.onEdit(ad.id)}>{t('otc.index.btn-edit')}</Button>
                                                : 
                                                <Button kind='primary' disabled={loading} loading={loadingId === ad.id} onClick={() => this.onStartDeal(ad.id)}>{t('otc.index.btn-buy')}</Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OTCIndex);
