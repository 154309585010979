module.exports = {
    "main": {
        "list-why": {
            "one": "Cash and non-cash rubles, Bitcoin, USDT",
            "two": "Withdrawal to Sberbank, Tinkov, Alfa-bank cards",
            "three": "Minimum commissions from 0.1% to 0.2% per transaction",
            "four": "The exchange is fully operational around the clock"
        },
        "btn-sign-up": "Sign up",
        "promo-sign-up": "Sign up an account right now",
        "btn-sign-in": "Sign in",
        "exchange-intended": {
            "title": "Who is our exchange intended for?",
            "list": {
                "one": {
                    "title": "Trader",
                    "list": {
                        "one": "The only exchange with the ability to replenish or withdraw the balance in cash",
                        "two": "We work all over Russia",
                        "three": "Withdrawal of rubles to cards/accounts of Sberbank, Tinkov, Alfabank, VTB",
                        "four": "The commission is only 0.1% per transaction"
                    }
                },
                "two": {
                    "title": "Miner",
                    "list": {
                        "one": "By selling your cryptocurrency on the stock exchange, you are guaranteed to receive the highest rate and avoid unnecessary commissions from intermediaries",
                        "two": "A comfortable, automated transaction without getting up from the computer",
                        "three": "Receiving cash at the cash desk, any regions, cards, accounts, cashins"
                    }
                },
                "three": {
                    "title": "Exchanger",
                    "list": {
                        "one": "Several integration options",
                        "two": "Low commission for sending cryptocurrencies",
                        "three": "Many exchangers are already making money with the exchange",
                        "four": "Contact us to find out about possible integration options"
                    }
                }
            }
        }
    },
    "otc": {
        "tabs": {
            "my-invitation": "My ads",
            "my-trades": "My trades",
            "my-chat": "Chats"
        },
        "index": {
            "top-up-balance": "Deposite balance exchange",
            "withdraw-balance": "Withdrawal balance exchange",
            "seller": "Seller",
            "payment-method": "Payment method",
            "description": "Conditions",
            "commission": "Commission",
            "amount": "Amount",
            "no-ads": "No active ads",
            "btn-buy": "Buy",
            "btn-sell": "Sell",
            "btn-edit": "Edit"
        },
        "my-ads": {
            "title": "My ads",
            "seller": "Sellet",
            "buyer": "Buyer",
            "payment-method": "Payment method",
            "commission": "Commission",
            "amount": "Amount",
            "status": "Status",
            "status-active": "Active",
            "status-no-active": "No acitve",
            "btn-edit": "Edit",
            "ad-changing": "Editing",
            "btn-open": "Turn on",
            "btn-close": "Turn off",
            "btn-delete": "Delete",
            "you-sure": "Are you sure?",
            "deal-complited": "Trade completed",
            "no-my-ads": "There are no ads for you yet",
            "ad-info": {
                "creature-title": "Create ad",
                "edit-title": "Edit ad",
                "direction": "Direction",
                "buy": "Buy",
                "sell": "Sale",
                "currency": "Currency",
                "min-amount": "Min amount",
                "max-amount": "Max amount",
                "payment-method": "Payment method",
                "payment-details": "Payment details",
                "description": "Conditions",
                "commission": "Commission",
                "btn-cancel": "Cancel",
                "btn-save": "Publish"
            },
            "message": {
                "ad-created": "Ad created",
                "ad-edited": "Ad edited",
                "ad-delete": "Ad deleted",
                "ad-open": "Ad turn on",
                "ad-close": "Ad turn off"
            }
        },
        "my-deals": {
            "tabs": {
                "active": "Active",
                "completed": "Completed",
                "canceled": "Canceled"
            }
        },
        "my-deal-ad": {
            "title": "Ad",
            "seller": "Sellet",
            "buyer": "Buyer",
            "payment-method": "Payment method: ",
            "commission": "Commission",
            "amount": "Cmount",
            "btn-sell": "Sell",
            "btn-buy": "Buy",
            "btn-paid": "Paid",
            "btn-arbitration": "Problem/Arbitration",
            "btn-cancel": "Cancel offer",
            "wait-pay": "Expect payment...",
            "wait-offer": "Expect an offer...",
            "condition": "Condition",
            "status": {
                "title": "Status",
                "buy": {
                    "get": "Get:",
                    "get-commission": "To be paid:",
                    "payment-details": "Payment details:",
                    "to-pay": "To be paid:",
                    "amount": "Amount to be received:",
                    "btn-payment-received": "Payment received",
                    "confirmation-payment": "Wait for the payment confirmation..."
                },
                "sell": {

                },
                "completed": "Trade completed",
                "cancelled": "Trade canceled"
            },
            "message": {
                
            }
        },
        "chat": {
            "send-to-message": "Write message...",
            "list": "List chats"
        },
        "deals": {
            "date-and-time": "Date and time",
            "id": "ID",
            "seller": "Seller",
            "buyer": "Buyer",
            "status": {
                "title": "Status",
                "active": "Active",
                "completed": "Completed",
                "canceled": "Cancelled",
                "arbitrage": "Arbitration"
            },
            "no-my-deals-cancelled": "No cancelled trades",
            "no-my-deals-completed": "No completed trades",
            "no-my-deals-active": "No active trades",
            "amount": "Amount transfer",
            "payment-method": "Payment method",
            "commission": "Commission",
            "get-on-balance": "Get on balance",
            "filter": {
                "search": "Search",
                "interval": "Interval date:",
                "amount": "Amount:",
                "btn-apply": "Apply"
            }
        }
    },
    "exchange": {
        "by-market": "By market",
        "limit": "Limit",
        "buy": "Buy",
        "sell": "Sell",
        "amount": "Amount",
        "quotation": "Quotation",
        "total": "Total",
        "sale": "Sale",
        "purchase": "Purchase",
        "chart": {
            "title": "Chart"
        },
        "open-voucher": {
            "title": "Open orders",
            "sell": "Sell",
            "buy": "Buy",
            "direction": "Direction",
            "type": "Type",
            "price": "Price",
            "volume": "Volume",
            "data": "Date and time",
            "button-cancel": "Cancel"
        },
        "history": {
            "title": "History"
        },
        "message": {
            "info": {
                "max-amount": "Max sum"
            },
            "error": {
                "volume-order": "Invalid volume order",
                "count-order": "Invalid amount order",
                "course-ranges": "Going beyond the course ranges (15% in both directions)"
            },
            "success": {
                "created-order": "Order created"
            }
        }
    },
    "signin": {
        "no-account": "No account?",
        "title": "Sign in",
        "sign-up": "Sign up",
        "or-sign-in-up": "Or sign in/sign up with:"
    },
    "sign-up": {
        "title": "Sign up",
        "no-account": "Already have an account?"
    },
    "header": {
        "exchange": "Exchange",
        "assets": "Assets",
        "profile": "Profile",
        "sigin": "Log in",
        "register": "Sign up"
    },
    "footer": {
        "telegram-bot": "Telegram",
        "help": "Customer Service"
    },
    "assets": {
        "tabs": {
            "replenishment": "Deposite",
            "withdrawal": "Withdrawal"
        },
        "type-operation": {
            "replenishment": "Deposite in",
            "withdrawal": "Withdrawal in" 
        },
        "deposite-means": "Deposit method",
        "withdrawal-means": "Withdrawal method",
        "deposit-options": {
            "maxcode": "Deposit Maxcode",
            "exmocode": "Deposit ExmoCode"
        },
        "withdrawal-options": {
            "maxcode": "Withdrawal MaxCode",
            "exmocode": "Withdrawal ExmoCode"
        },
        "withdrawal-sum": "Withdrawal amount",
        "condition": {
            "title": "Conditions",
            "description": "Free"
        },
        "code-replenishment": "The code deposit",
        "button-replenishment": "To deposit",
        "button-withdrawal": "To withdrawal",
        "history-replenishments": {
            "title": "History of deposites",
            "time": "Time",
            "number": "Id",
            "sum": "Amount",
            "adderess": "Address",
            "status": "Status",
            "code-status": {
                "success": "Confirmed",
                "warning": "Cancelled",
                "wait": "Waiting"
            }
        },
        "history-withdrawal": {
            "title": "History of withdrawal",
            "time": "Time",
            "code": "Code",
            "sum": "Amount",
            "adderess": "Address",
            "status": "Status",
            "code-status": {
                "redeemed": "Activated",
                "created": "Copy"
            },
            "copy-code": "The code is copied"
        },
        "cryptocurrency-deposit-options": {
            "first": "Use your wallet to make a deposit to the balance of the exchange.",
            "second": "Copy the address below into your wallet, then enter the credit amount, and afterwards confirm transfer.",
            "third": "As the amount will be transferred, will replenish your balance on the exchange."
        },
        "min-amount-deposit": "Minimum amount deposit:",
        "scan-qr-code": "Or scan the QR code for payment.",
        "cryptocurrency-withdrawal-options": {
            "first": "Enter the address, amount and confirmation code from SMS / Google Authenticator, then click 'To withdrawal'. And wait for the application to be confirmed within 30 minutes."
        },
        "adderess-withdrawal": "Adderess withdrawal",
        "amount-withdrawal": "Amount withdrawal",
        "warning-withdrawal-info": "Attention! For Bech32 addresses (starting with bc1) the withdrawal fee is always 0.0004 BTC, regardless of the volume.",
        "warning-withdrawal-info-usdt": "This is the USDT wallet address in TRC20 format.  Be careful!",
        "warning-deposit-info": "This is the USDT deposit address in TRC20 format.  Be careful!",
        "copy-adderess": "The address is copied",
        "partner": {
            "withdrawal": "Withdrawal from partner account",
            "hint": "To ruble account"
        },
        "tabs-partner": "Partner Program",
        "message": {
            "bid-created": "Bid created",
            "transferred-to-ruble": "Transferred to ruble account"
        },
        "no-assets-deposit": "Empty deposit history",
        "no-assets-withdrawal": "Empty withdrawal history"
    },
    "status-server": {
        "assets": {
            "success-replenishment": "The deposit was made successfully",
            "success-deposit-code": "The deposit code was created, see the withdrawal history"
        }
    },
    "profile": {
        "title": "Account",
        "button-edit": "Edit",
        "button-cancel": "Cancel",
        "button-open": "Open",
        "button-close": "Close",
        "button-turn-on": "Turn on",
        "button-turn-off": "Turn off",
        "button-save": "Save",
        "options": {
            "nickname": {
                "title": "Nickname",
                "description": "Your nickname:"
            },
            "phone": {
                "title": "Phone",
                "description": "Your phone number:",
                "description-not-specified": "Add phone number"
            },
            "password": {
                "title": "Password",
                "description": "You have the option to change your password"
            },
            "2FA": {
                "title": "2FA",
                "description": "You have the option to change the 2FA"
            },
            "telegram": {
                "title": "Telegram",
                "description": "Your Telegram: ",
                "description-not-specified": "Add your Telegram"
            },
            "partner": {
                "title": "Partner Program",
                "description": "Your referral link:"
            },
            "email-confirm": {
                "title": "Your account was verified",
                "description": "With this, we will protect your account using both your password and phone number as well as email."
            }
        },
        "tabs": {
            "current": "Current",
            "new": "New",
            "new-replay": "New replay",
            "nickname": {
                "title": "Nickname"
            },
            "phone": {
                "title": "Phone"
            },
            "password": {
                "title": "Password"
            },
            "2FA": {
                "title": "2FA",
                "description": "Scan the QR code using Google Authenticator to enable 2FA",
                "description-additinal": "Or use a textual secret: ",
                "code": "Code 2FA"
            },
            "partner": {
                "title": "Partner Program",
                "description-options": {
                    "first": "You can get 0.1% of every transaction of your referrals, so invite your colleagues and friends!",
                    "second": "You can tell about working with MaxBit exchange to your referrals!"
                },
                "your-link": "Your referral link",
                "your-link-text": "Your referral link with the text",
                "link-text": "Get up to 50% of the commission of your referrals by registering using my link!"
            }
        },
        "exit": "Log out of account",
        "copy-success": "Link copied"
    },
    "message": {
        "no-auth-pages": "Log in to your account to view the page"
    }
};