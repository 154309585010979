import React from 'react';
import { OperationLogType } from "../utils/graphql";
import { DateTime } from "luxon";
import { tf, divideNumberByPieces} from "../utils/utilities";
import { toast } from "react-toastify";

import { withTranslation } from 'react-i18next';

interface IHistoryWithdrawRubProps {
    operations?: OperationLogType[];
    operationsExmoCode: any;
    t: any;
}

interface IHistoryWithdrawRubState {
}

class HistoryWithdrawRub extends React.Component<IHistoryWithdrawRubProps, IHistoryWithdrawRubState> {
    render() {
        const { t, operations, operationsExmoCode } = this.props;

        return (
            <div className="table withdrawal scroll-wrap">
                <div className="table__wrap">
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>{t('assets.history-withdrawal.time')}</span>
                         </div>
                            <div className="table__field"><span>{t('assets.history-withdrawal.code')}</span></div>
                            <div className="table__field"><span>{t('assets.history-withdrawal.sum')}</span></div>
                            <div className="table__field"><span>{t('assets.history-withdrawal.status')}</span></div>
                    </div>
                    {/* gfdger */}
                    <div className=" scroll-wrap">
                        {(operations?.length) === 0 ? 
                            <div className="no-assets">
                                {t('assets.no-assets-withdrawal')}
                            </div>
                            : null    
                        }
                        {operations?.map(w => (
                            <div className="table__row" key={w.id}>
                                <div className="table__field"><span>{DateTime.fromISO(w.datetime).toFormat('D t')}</span></div>
                                <div className="table__field code"><span>{w.destination}</span></div>
                                <div className="table__field table__field_col">
                                    <span>{divideNumberByPieces(tf(w.value, 2))}</span>
                                    {/*<span className="info-text">(+0,5% комиссия)</span>*/}
                                </div>
                                {w.status === null && <div className='table__field no-redeemed' onClick={() => {
                                    navigator.clipboard.writeText(w.destination);
                                    toast.success(`${t('assets.history-withdrawal.copy-code')}`);
                                }}><span>{t('assets.history-withdrawal.code-status.created')}</span></div>}
                                {w.status === true && <div className='table__field success'><span>{t('assets.history-withdrawal.code-status.redeemed')}</span></div>}
                            </div>
                        ))}
                        {operationsExmoCode?.map((element, index) => (
                            <div className="table__row" key={index}>
                                <div className="table__field"><span>{DateTime.fromISO(element.createdAt).toFormat('D t')}</span></div>
                                <div className="table__field"><span>{element.code}</span></div>
                                <div className="table__field table__field_col">
                                        <span>{divideNumberByPieces(tf(element.amount, 2))}</span>
                                    {/*<span className="info-text">(+0,5% комиссия)</span>*/}
                                </div>
                                {element.status === 'created' && <div className='table__field info-text' onClick={() => {
                                    navigator.clipboard.writeText(element.code)
                                    toast.success(`${t('assets.history-withdrawal.copy-code')}`);
                                    }}><span>{t('assets.history-withdrawal.code-status.created')}</span></div>}
                                {element.status === 'redeemed' && <div className='table__field success'><span>{t('assets.history-withdrawal.code-status.redeemed')}</span></div>}
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(HistoryWithdrawRub);

