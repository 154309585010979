import React from 'react';
import { DateTime } from "luxon";
import { withTranslation } from 'react-i18next';

import { OperationLogType } from "../utils/graphql";
import { tf } from "../utils/utilities";

interface IHistoryWithdrawProps {
    operations?: OperationLogType[];
    mantissa: number;
    withoutDestination?: boolean;
    t: any;
}

interface IHistoryWithdrawState {
}

class HistoryWithdraw extends React.Component<IHistoryWithdrawProps, IHistoryWithdrawState> {
    render() {
        const { operations, mantissa, withoutDestination, t } = this.props;

        return (
            <div className="table withdrawal withdrawal--btc scroll-wrap">
                <div className="table__wrap">
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>{t('assets.history-withdrawal.time')}</span>
                        </div>
                        {!withoutDestination && <div className="table__field"><span>{t('assets.history-withdrawal.adderess')}</span></div>}
                        <div className="table__field"><span>TxId</span></div>
                        <div className="table__field"><span>{t('assets.history-withdrawal.sum')}</span></div>
                        <div className="table__field"><span>{t('assets.history-withdrawal.status')}</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {(operations?.length) === 0 ? 
                            <div className="no-assets">
                                {t('assets.no-assets-withdrawal')}
                            </div>
                            : null    
                        }
                        {operations?.map(w => (
                            <div className="table__row" key={w.id}>
                                <div className="table__field"><span>{DateTime.fromISO(w.datetime).toFormat('D t')}</span></div>
                                {!withoutDestination && <div className="table__field"><span>{w.destination}</span></div>}
                                <div className="table__field"><span>{w.txid}</span></div>
                                <div className="table__field table__field_col">
                                    <span>{tf(w.value, mantissa)}</span>
                                </div>
                                {w.status === true && <div className="table__field success"><span>{t('assets.history-replenishments.code-status.success')}</span></div>}
                                {w.status === false && <div className="table__field warning"><span>{t('assets.history-replenishments.code-status.warning')}</span></div>}
                                {w.status === null && <div className="table__field info-text"><span>{t('assets.history-replenishments.code-status.wait')}</span></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(HistoryWithdraw);
