import { GraphQLClient } from "graphql-request";
import { getSdk, Sdk, SdkFunctionWrapper } from "./graphql";
import { API_HOST, API_PROTO } from "./const";
import { interfaces } from "inversify";

export class Api {
    client: GraphQLClient;
    sdk: Sdk;

    constructor(token?: string) {
        this.client = new GraphQLClient(`${API_PROTO}://${API_HOST}/graphql`);
        this.client.setHeader('Authorization', token ? `JWT ${token}` : null);
        this.sdk = getSdk(this.client);
    }

    async tokenAuthTg(id: number, first_name: string, username: string, photo_url: string, auth_date: number, hash: string) {
        const res = await this.sdk.tokenAuthTg({ id, firstName: first_name, username, photoUrl: photo_url, authDate: auth_date, hash });
        return res.tokenAuthTg.token;
    }

    async tokenAuth(email: string, password: string, otpCode: string) {
        const res = await this.sdk.tokenAuth({ email, password, otpCode });
        return res.tokenAuth.token;
    }

    async register(name: string, email: string, password: string, referrer?: string) {
        const res = await this.sdk.register({ name, email, password, referrer });
    }

    async passwordReset(email: string) {
        const res = await this.sdk.passwordReset({ email })
    }

    async getProfile() {
        const res = await this.sdk.getProfile();
        return res.profile;
    }

    async updateName(name: string) {
        await this.sdk.updateName({ name });
    }

    async updatePhone(phone: string) {
        await this.sdk.updatePhone({ phone });
    }

    async updatePassword(oldPassword: string, newPassword: string) {
        await this.sdk.updatePassword({ oldPassword, newPassword });
    }

    async toggle2fa(code: string) {
        await this.sdk.toggle2fa({ code });
    }

    async updateTg(tgUsername: string, inviterTgUsername: string) {
        await this.sdk.updateTg({ tgUsername, inviterTgUsername });
    }

    async redeemExmoCode(code: string) {
        await this.sdk.redeemExmoCode({code});
    }

    async checkExmoCode(code: string) {
        await this.sdk.checkExmoCode({code});
    }

    async createExmoCode(currency: string, amount: number) {
        await this.sdk.createExmoCode({currency, amount});
    }

    async getMyExmoCode() {
        const res = await this.sdk.getMyExmoCodes();
        return res.myExmoCodes;
    }

    async getOperations(currency: string) {
        const res = await this.sdk.getOperations({ currency });
        return res.getOperations;
    }

    async depositRub(code: string) {
        await this.sdk.depositRub({ code });
    }

    async withdrawRub(amount: string, otpCode: string) {
        await this.sdk.withdrawRub({ amount, otpCode });
    }
    async depositKzt(code: string) {
        await this.sdk.depositKzt({ code });
    }
    async withdrawKzt(amount: string, otpCode: string) {
        await this.sdk.withdrawKzt({ amount, otpCode });
    }
    async depositUzs(code: string) {
        await this.sdk.depositUzs({ code });
    }
    async withdrawUzs(amount: string, otpCode: string) {
        await this.sdk.withdrawUzs({ amount, otpCode });
    }

    async depositAed(code: string) {
        await this.sdk.depositAed({ code });
    }
    async withdrawAed(amount: string, otpCode: string) {
        await this.sdk.withdrawAed({ amount, otpCode });
    }

    async withdrawPartner(amount: string, otpCode: string) {
        await this.sdk.withdrawPartner({ amount, otpCode });
    }

    async withdrawBtc(amount: string, address: string, otpCode: string) {
        await this.sdk.withdrawBtc({ amount, address, otpCode });
    }

    async withdrawXmr(amount: string, address: string, otpCode: string) {
        await this.sdk.withdrawXmr({ amount, address, otpCode });
    }
    
    async withdrawLtc(amount: string, address: string, otpCode: string) {
        await this.sdk.withdrawLtc({ amount, address, otpCode });
    }

    async withdrawUsdt(amount: string, address: string, otpCode: string) {
        await this.sdk.withdrawUsdt({ amount, address, otpCode });
    }

    async withdrawFnk(amount: string, address: string, otpCode: string) {
        await this.sdk.withdrawFnk({ amount, address, otpCode });
    }

    async has2FA(email: string) {
        const res = await this.sdk.has2FA({ email });
        return res.has2fa;
    }

    async otcCreateAd(action: 'buy' | 'sell', currency: string, minSum: string, maxSum: string, paymentMethod: string, description: string, priceExtraPercent: string, bankCardNumber: string, priceExtraPercentDirection: string) {
        await this.sdk.otcCreateAd({ action, currency, minSum, maxSum, paymentMethod, description, priceExtraPercent, bankCardNumber, priceExtraPercentDirection });
    }

    async otcEditAd(id: string, action: 'buy' | 'sell', currency: string ,  minSum: string, maxSum: string, paymentMethod: string, description: string, priceExtraPercent: string, bankCardNumber: string, priceExtraPercentDirection: string) {
        await this.sdk.otcEditAd({ id, currency, action, minSum, maxSum, paymentMethod, description, priceExtraPercent, bankCardNumber, priceExtraPercentDirection });
    }

    async otcSetActiveAd(pk: string, isActive: boolean) {
        await this.sdk.otcSetActiveAd({ pk, isActive});
    }

    async otcDeleteAd(pk: string) {
        await this.sdk.otcDeleteAd({ pk });
    }

    async getMyOtcAds() {
        const res = await this.sdk.getMyOtcAds();
        return res.myAds;
    }

    async getOtcAds() {
        const res = await this.sdk.getOtcAds();
        return res.ads;
    }

    async otcStartDeal(adId: string) {
        const res = await this.sdk.otcStartDeal({ adId });
        return res.otcStartDeal.dealId;
    }

    async getMyOtcDeals(startDate?: string, endDate?: string, minAmount?: number, maxAmount?: number) {
        const res = await this.sdk.getMyOtcDeals({startDate, endDate, minAmount, maxAmount});
        return res.myDeals;
    }

    async getDeal(pk: string) {
        const res = await this.sdk.getDeal({ pk });
        return res.deal;
    }

    async otcDealOffer(pk: string, amount: string, bankCardNumber: string, paymentMethod: string) {
        await this.sdk.otcDealOffer({ pk, amount, bankCardNumber, paymentMethod });
    }

    async otcDealAccept(pk: string) {
        await this.sdk.otcDealAccept({ pk });
    }

    async otcDealDecline(pk: string) {
        await this.sdk.otcDealDecline({ pk });
    }

    async otcDealPaid(pk: string) {
        await this.sdk.otcDealPaid({ pk });
    }

    async otcDealComplete(pk: string) {
        await this.sdk.otcDealComplete({ pk });
    }

    async otcDealCancel(pk: string) {
        await this.sdk.otcDealCancel({ pk });
    }

    async otcDealArbitrage(pk: string) {
        await this.sdk.otcDealArbitrage({ pk });
    }

    async indexPage() {
        const res = await this.sdk.getIndexInfo();
        return res.indexPage;
    }
}

export const api = new Api();
