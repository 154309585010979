import React from 'react';
import classNames from "classnames";
import QRCode from 'react-qr-code';
import copy from "copy-to-clipboard";
import PhoneInput from 'react-phone-input-2';
import { resolve } from "inversify-react";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { when } from 'mobx';
import { withTranslation } from 'react-i18next';

import { pd, processRequestError } from "../utils/utilities";

import { AuthStore } from "../stores";

import { Button } from "../components/Button";

interface IProfilePageProps {
    t: any;
}

interface IProfilePageState {
    activeTab: null | 'name' | 'phone' | 'password' | '2fa' | 'tg' | 'partner';
    newName: string;
    oldPassword: string;
    password: string;
    password2: string;
    newPhone: string;
    otpCode: string;
    tgUsername: string;
    inviterTgUsername: string;
    loading: boolean;
}

@observer
class ProfilePage extends React.Component<IProfilePageProps, IProfilePageState> {
    private reactionDisposer: any;

    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IProfilePageState = {
        activeTab: null,
        newName: '',
        oldPassword: '',
        password: '',
        password2: '',
        newPhone: '',
        otpCode: '',
        tgUsername: '',
        inviterTgUsername: '',
        loading: false,
    }

    componentDidMount() {
        this.reactionDisposer = when(
            () => !!(this.authStore.profile?.tgUsername || this.authStore.profile?.inviterTgUsername),
            () => this.setState({
                tgUsername: this.authStore.profile?.tgUsername || '',
                inviterTgUsername: this.authStore.profile?.inviterTgUsername || '',
            })
        );
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    onUpdateName = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updateName(this.state.newName);
            toast.success('Изменения сохранены');
            this.setState({ newName: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onUpdatePassword = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updatePassword(this.state.oldPassword, this.state.password);
            toast.success('Изменения сохранены');
            this.setState({ oldPassword: '', password: '', password2: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onUpdatePhone = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updatePhone('+' + this.state.newPhone);
            toast.success('Изменения сохранены');
            this.setState({ newPhone: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onToggle2FA = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.toggle2fa(this.state.otpCode);
            toast.success('Изменения сохранены');
            this.setState({ otpCode: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onUpdateTg = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updateTg(this.state.tgUsername, this.state.inviterTgUsername);
            toast.success('Изменения сохранены');
            this.setState({ activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { activeTab, newName, oldPassword, password, password2, newPhone, otpCode, tgUsername, inviterTgUsername, loading } = this.state;
        const { t } = this.props;
        let correct_phone;

        if ((this.authStore.profile?.phone)) {
            let str = (this.authStore.profile?.phone);
            let gr = str.match(/(\+\d)(\d{3})(\d{3})(\d{2})(\d{2})/);
            correct_phone = gr[1] + '(' + gr[2] + ')' + gr[3] + '-' + gr[4] + '-' + gr[5];
        }

        const textLink = `${t('profile.tabs.partner.link-text')} ${this.authStore.referralLink}`

        return (
            <main className="main">
                <section className="otc-section">
                    <div className="container">
                        <div className="profile-wrap">
                            <div className="chat js-chat-tabs">
                                <div className="chat__list-wrap">
                                    <h2 className="operation-title active">{t('profile.title')}</h2>
                                    <ul className="chat__list">
                                        <li className={classNames(['chat__item', { active: activeTab === 'name' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">{t('profile.options.nickname.title')}</span>
                                                <span className="chat__base-text">{t('profile.options.nickname.description')} <span className="chat__profile-label">{this.authStore.profile?.name} </span></span>
                                                {this.authStore.profile?.referrer && <span className="chat__base-text">Referrer: <span className="chat__profile-label">{this.authStore.profile?.referrer}</span></span>}
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn edit small', { active: activeTab !== 'name' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'name' })}
                                                >
                                                    {t('profile.button-edit')}
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'name' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    {t('profile.button-cancel')}
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'phone' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">{t('profile.options.phone.title')}</span>
                                                <span className="chat__base-text">
                                                    {(this.authStore.profile?.phone) ? t('profile.options.phone.description') : t('profile.options.phone.description-not-specified')}
                                                    <span className="chat__profile-label"> {correct_phone}</span>
                                                </span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn edit small', { active: activeTab !== 'phone' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'phone' })}
                                                >
                                                    {t('profile.button-edit')}
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'phone' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    {t('profile.button-cancel')}
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'password' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">{t('profile.options.password.title')}</span>
                                                <span className="chat__base-text">{t('profile.options.password.description')}</span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn edit small', { active: activeTab !== 'password' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'password' })}
                                                >
                                                    {t('profile.button-edit')}
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'password' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    {t('profile.button-cancel')}
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === '2fa' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">{t('profile.options.2FA.title')}</span>
                                                <span className="chat__base-text">{t('profile.options.2FA.description')}</span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn edit small', { active: activeTab !== '2fa' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: '2fa' })}
                                                >
                                                    {t('profile.button-edit')}
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === '2fa' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    {t('profile.button-cancel')}
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'tg' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">{t('profile.options.telegram.title')}</span>
                                                <span className="chat__base-text">
                                                    {(this.authStore.profile?.tgUsername) ? t('profile.options.telegram.description') : t('profile.options.telegram.description-not-specified')}
                                                    <span className="chat__profile-label">{(this.authStore.profile?.tgUsername)}</span>
                                                </span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn edit small', { active: activeTab !== 'tg' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'tg' })}
                                                >
                                                    {t('profile.button-edit')}
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'tg' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    {t('profile.button-cancel')}
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'partner' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">{t('profile.options.partner.title')}</span>
                                                <span className="chat__base-text">
                                                    {t('profile.options.partner.description')}
                                                    <span className="chat__profile-label"> {this.authStore.referralLink}</span>
                                                </span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn edit small', { active: activeTab !== 'partner' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'partner' })}
                                                >
                                                    {t('profile.button-open')}
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'partner' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    {t('profile.button-close')}
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="profile-footer">
                                        {this.authStore.profile?.emailValidated && (
                                            <div className="chat__verified-wrap">
                                                <span className="chat__verified-title">{t('profile.options.email-confirm.title')}</span>
                                                <p className="chat__base-text">{t('profile.options.email-confirm.description')}</p>
                                            </div>
                                        )}
                                        <button
                                            className="btn tertiary small"
                                            type="button"
                                            onClick={() => this.authStore.logout()}
                                        >
                                            {t('profile.exit')}
                                        </button>
                                    </div>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'name' }])}>
                                    <h2 className="operation-title">{t('profile.options.nickname.title')}</h2>
                                    <form className="main-form" onSubmit={this.onUpdateName}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.current')}</label>
                                                <input
                                                    className="main-form__input main-form__input_dis"
                                                    type="text"
                                                    disabled
                                                    value={this.authStore.profile?.name}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.new')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={newName}
                                                    onChange={e => this.setState({ newName: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!newName} loading={loading}>{t('profile.button-save')}</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'phone' }])}>
                                    <h2 className="operation-title">{t('profile.tabs.phone.title')}</h2>
                                    <form className="main-form" onSubmit={this.onUpdatePhone}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.current')}</label>
                                                <input
                                                    className="main-form__input main-form__input_dis"
                                                    type="text"
                                                    disabled
                                                    value={correct_phone}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.new')}</label>
                                                <PhoneInput
                                                    country='ru'
                                                    inputProps={{ className: 'main-form__input' }}
                                                    value={newPhone}
                                                    onChange={newPhone => this.setState({ newPhone })}
                                                    disableDropdown
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!newPhone} loading={loading}>{t('profile.button-save')}</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'password' }])}>
                                    <h2 className="operation-title">{t('profile.tabs.password.title')}</h2>
                                    <form className="main-form" onSubmit={this.onUpdatePassword}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.current')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="password"
                                                    value={oldPassword}
                                                    onChange={e => this.setState({ oldPassword: e.target.value })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.new')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="password"
                                                    value={password}
                                                    onChange={e => this.setState({ password: e.target.value })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.new-replay')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="password"
                                                    value={password2}
                                                    onChange={e => this.setState({ password2: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!oldPassword || !password || password !== password2} loading={loading}>{t('profile.button-save')}</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === '2fa' }])}>
                                    <h2 className="operation-title">2FA</h2>
                                    <form className="main-form" onSubmit={this.onToggle2FA}>
                                        <div className="main-form__wrap">
                                            {this.authStore.profile?.otpEnabled ? (
                                                <></>
                                            ) : (
                                                <div className='main-form__qr'>
                                                    <div className='qr__code-div'>{t('profile.tabs.2FA.description')}</div>
                                                    <div className="main-form__qr__code-wrapper">
                                                        <QRCode
                                                            value={`otpauth://totp/${this.authStore.profile?.name}?secret=${this.authStore.profile?.otpSecret}&issuer=TopExchange`}
                                                            bgColor='#f8f8f8'
                                                        />
                                                    </div>
                                                    <div>{t('profile.tabs.2FA.description-additinal')} <code>{this.authStore.profile?.otpSecret}</code></div>
                                                </div>
                                            )}
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('profile.tabs.2FA.code')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    maxLength={6}
                                                    value={otpCode}
                                                    onChange={e => this.setState({ otpCode: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={otpCode.length !== 6} loading={loading}>
                                                {this.authStore.profile?.otpEnabled ? `${t('profile.button-turn-off')}` : `${t('profile.button-turn-on')}`}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'tg' }])}>
                                    <h2 className="operation-title">Telegram</h2>
                                    <form className="main-form" onSubmit={this.onUpdateTg}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">User</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={tgUsername}
                                                    onChange={e => this.setState({ tgUsername: e.target.value })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Referrer</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={inviterTgUsername}
                                                    onChange={e => this.setState({ inviterTgUsername: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!tgUsername} loading={loading}>{t('profile.button-save')}</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content partner-content', { active: activeTab === 'partner' }])}>
                                    <div className="main-block">
                                        <h2 className="operation-title">{t('profile.tabs.partner.title')}</h2>
                                        <div className="program-wrap">
                                            <div className="program">
                                                <div className="program__wrap">
                                                    <span className="program__number">1</span>
                                                    <div className="program__info">
                                                        <p className="base-text">{t('profile.tabs.partner.description-options.first')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="program">
                                                <div className="program__wrap">
                                                    <span className="program__number">2</span>
                                                    <div className="program__info">
                                                        <p className="base-text">{t('profile.tabs.partner.description-options.second')}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='program link-referrer'>
                                                <div className="program__wrap link-referrer__wrap">
                                                    <div className="program__link">
                                                        <span className="program__link-title">{t('profile.tabs.partner.your-link')}</span>
                                                        <div className="program__link-wrap">
                                                            <p className="program__link-text">{this.authStore.referralLink}</p>
                                                            <button className="program__link-copy" type="button" onClick={() => copy(this.authStore.referralLink) && toast.success(`${t('profile.copy-success')}`)}>
                                                                <svg>
                                                                    <use href="#copy"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="program__link">
                                                        <span className="program__link-title">{t('profile.tabs.partner.your-link-text')}</span>
                                                        <div className="program__link-wrap">
                                                            <p className="program__link-text">{textLink}</p>
                                                            <button className="program__link-copy" type="button" onClick={() => copy(textLink) && toast.success(`${t('profile.copy-success')}`)}>
                                                                <svg>
                                                                    <use href="#copy"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default withTranslation()(ProfilePage);
