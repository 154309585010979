import React, {FC, useState, useEffect} from 'react';
import classNames from "classnames";
import {observer} from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {rootStore} from "../../Application";

import {ExchangeOrdersType} from "../../utils/graphql";
import {useInterval} from "../../utils/interval";
import { divideNumberByPieces } from '../../utils/utilities';


interface IOrderBookProps {
    symbol: string;
    coinSymbol: string;
    baseSymbol: string;
}


const OrderBook: FC<IOrderBookProps> = ({symbol,coinSymbol,baseSymbol}) => {
    const [limit, setLimit] = useState<number>(20)
    const [loading, setLoading] = useState<boolean>(false)
    const [itemsSell, setItemsSell] = useState<ExchangeOrdersType[]>()
    const [itemsBuy, setItemsBuy] = useState<ExchangeOrdersType[]>()
    const { t } = useTranslation();

    // @ts-ignore
    useEffect(async () => {
        await loadingOrderBook();
    }, []);

    useInterval(async () => {
         await loadingOrderBook()
    }, 1000 * 2);

    // @ts-ignore
    useEffect(async () => {
        await loadingOrderBook()
    }, [symbol]);


    const setItems = async (one,direct) => {
        rootStore.ordersStore.setSelectData(one,direct)
    }

    const loadingOrderBook = async () => {
        await rootStore.ordersStore.getOrdersBook(symbol, limit)
        await rootStore.coinStore.getBalancesAll();
        setItemsBuy((rootStore.ordersStore.buy))
        setItemsSell((rootStore.ordersStore.sell))
    }

    return (
        <div className="table-wrap">
            <div className="table glass table-border  scroll-wrap">
                <div className="table__wrap" >
                    <h3 className="table__title">{t('exchange.sale')} {coinSymbol}</h3>
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>{t('exchange.quotation')} ({baseSymbol})</span>
                        </div>
                        <div className="table__field"><span>{t('exchange.amount')} ({coinSymbol})</span></div>
                        <div className="table__field"><span>{t('exchange.total')} ({baseSymbol})</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {itemsSell?.map((one, key) => (
                            <div className="table__row" style={{cursor:"pointer"}} key={key} onClick={() => setItems(one,"BUY")}>
                                <div className="table__field warning">
                                    <span>{divideNumberByPieces(one.price)}</span>
                                </div>
                                <div className="table__field">
                                    <span>{one.amounts}</span>
                                </div>
                                <div className="table__field">
                                    <span>{divideNumberByPieces(one.turnovers)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="table glass table-border  scroll-wrap">
                <div className="table__wrap">
                    <h3 className="table__title">{t('exchange.purchase')} {coinSymbol}</h3>
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>{t('exchange.quotation')} ({baseSymbol})</span>
                        </div>
                        <div className="table__field"><span>{t('exchange.amount')} ({coinSymbol})</span></div>
                        <div className="table__field"><span>{t('exchange.total')} ({baseSymbol})</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {itemsBuy?.map((one,key) => (
                            <div className="table__row" key={key}  style={{cursor:"pointer"}}  onClick={() => setItems(one,"SELL")}>
                                <div className="table__field success-stock">
                                    <span>{divideNumberByPieces(one.price)}</span>
                                </div>
                                <div className="table__field">
                                    <span>{one.amounts}</span>
                                </div>
                                <div className="table__field">
                                    <span>{divideNumberByPieces(one.turnovers)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OrderBook
