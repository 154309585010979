import React from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { resolve } from 'inversify-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DayPicker } from 'react-day-picker';
import { Tooltip } from 'react-tooltip';

import { Api } from '../../utils/api';
import { OtcDeal, AppOtcDealStatusChoices, AppOtcAdPriceExtraPercentDirectionChoices } from '../../utils/graphql';
import {pd, tf} from "../../utils/utilities";

import {AuthStore} from "../../stores";

import {Button} from "../../components/Button";

interface IOTCDealsTabProps extends WithTranslation {
}

interface IOTCDealsTabState {
    deals: OtcDeal[];
    status: AppOtcDealStatusChoices;
    dateFrom: string;
    dateFromSubmit: string;
    dateTo: string;
    dateToSubmit: string;
    validFilter: boolean;
    minAmount: number;
    minAmountSubmit: number;
    maxAmount: number;
    maxAmountSubmit: number;
    isCalendarOpenFrom: boolean;
    isCalendarOpenTo: boolean;
    language: string;
}

interface ITabs {
    status: AppOtcDealStatusChoices;
    text: string;
}

interface IHeadFields {
    modificatorClass: string;
    text: string;
    condition: boolean;
}

interface INoDeals {
    conditions: {
        main: AppOtcDealStatusChoices;
        option?: AppOtcDealStatusChoices;
    }
    text: string;
}

class OTCDealsTab extends React.Component<IOTCDealsTabProps, IOTCDealsTabState> {
    private updateInterval: any;

    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IOTCDealsTabState = {
        deals: [],
        status: AppOtcDealStatusChoices.Active,
        dateFrom: '',
        dateFromSubmit: '',
        dateTo: '',
        dateToSubmit: '',
        validFilter: true,
        minAmount: null,
        minAmountSubmit: null,
        maxAmount: null,
        maxAmountSubmit: null,
        isCalendarOpenFrom: false,
        isCalendarOpenTo: false,
        language: 'ru',
    }

    componentDidMount() {
        this.loadDeals();
        this.updateInterval = setInterval(this.loadDeals, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval);
    }

    loadDeals = async () => {
        const {dateFrom, dateFromSubmit, dateTo, dateToSubmit, minAmount, minAmountSubmit, maxAmount, maxAmountSubmit} = this.state;

        if ((dateFrom === `` || dateTo === `` || minAmount === null || maxAmount === null)) {
            this.setState({ deals: await this.api.getMyOtcDeals() });
        } else {    
            this.setState({ deals: await this.api.getMyOtcDeals(this.correctFormatDateToServer(dateFromSubmit), this.correctFormatDateToServer(dateToSubmit), minAmountSubmit, maxAmountSubmit) });
        }
    }

    correctFormatDateToServer = (date: string) => {
        const [day, month, year] = date.split('.').map(String);
        return `${year}-${month}-${day}`;
    }

    onSubmitForm = async (e: React.FormEvent) => {
        const {validFilter, dateFrom, dateFromSubmit, dateTo, dateToSubmit, minAmount, minAmountSubmit, maxAmount, maxAmountSubmit} = this.state;
        pd(e);

        if (!validFilter || (dateFrom === `` || dateTo === `` || minAmount === null || maxAmount === null)) {
            toast.error('Невалидная форма фильтрации');
            return;
        }

        this.setState({
            dateFromSubmit: dateFrom,
            dateToSubmit: dateTo,
            minAmountSubmit: minAmount,
            maxAmountSubmit: maxAmount
        });
        this.setState({ deals: await this.api.getMyOtcDeals(this.correctFormatDateToServer(dateFromSubmit), this.correctFormatDateToServer(dateToSubmit), minAmountSubmit, maxAmountSubmit) });
    };

    render() {
        const { deals, status, dateFrom, dateTo, validFilter, minAmount, maxAmount, isCalendarOpenFrom, isCalendarOpenTo, language } = this.state;
        const { t, i18n } = this.props;

        i18n.on("languageChanged", () => {
            this.setState({language: i18n.language});
        });

        const listDirectiionComission = {
            [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: '-',
            [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: '+'
        };


        const tabs: ITabs[] = [
            {
                status: AppOtcDealStatusChoices.Active,
                text: 'otc.my-deals.tabs.active'
            },
            {
                status: AppOtcDealStatusChoices.Completed,
                text: 'otc.my-deals.tabs.completed'
            },
            {
                status: AppOtcDealStatusChoices.Canceled,
                text: 'otc.my-deals.tabs.canceled'
            }
        ];

        const headFields: IHeadFields[] = [
            {
                modificatorClass: 'id',
                text: 'ID',
                condition: true,
            },
            {
                modificatorClass: 'date-and-time',
                text: 'otc.deals.date-and-time',
                condition: true,
            },
            {
                modificatorClass: 'seller',
                text: 'otc.deals.seller',
                condition: true,
            },
            {
                modificatorClass: 'buyer',
                text: 'otc.deals.buyer',
                condition: true,
            },
            {
                modificatorClass: 'status',
                text: 'otc.deals.status.title',
                condition: (status === AppOtcDealStatusChoices.Active),
            },
            {
                modificatorClass: 'transfer-amount',
                text: 'otc.deals.amount',
                condition: true,
            },
            {
                modificatorClass: 'payment-method',
                text: 'otc.deals.payment-method',
                condition: true,
            },
            {
                modificatorClass: 'commission',
                text: 'otc.deals.commission',
                condition: true,
            },
            {
                modificatorClass: 'get-balance',
                text: 'otc.deals.get-on-balance',
                condition: true,
            }
        ];

        const noDeals: INoDeals[] = [
            {
                conditions: {
                    main: AppOtcDealStatusChoices.Active,
                    option: AppOtcDealStatusChoices.Arbitrage
                },
                text: 'otc.deals.no-my-deals-active'
            },
            {
                conditions: {
                    main: AppOtcDealStatusChoices.Canceled,
                },
                text: 'otc.deals.no-my-deals-cancelled'
            },
            {
                conditions: {
                    main: AppOtcDealStatusChoices.Completed,
                },
                text: 'otc.deals.no-my-deals-completed'
            }
        ];

        const validateDate = (value: string) => {
            const [day, month, year] = value.split('.').map(Number);
            let valid = true;

            const invalidDates = {
                minDay: 1,
                maxDay: 31,
                minMonth: 1,
                maxMonth: 12,
                minYear: 1900,
                maxYear: 2100,
                specificDays: [4, 6, 9, 11],
                febrary: 2
            }

            if (day < invalidDates.minDay || day > invalidDates.maxDay) {
                valid = false;
            }
    
            if (month < invalidDates.minMonth || month > invalidDates.maxMonth) {
                valid = false;
            }

            if (year < invalidDates.minYear || year > invalidDates.maxYear) {
                valid = false;
            }
    
            // Check for invalid days in specific months
            if (invalidDates.specificDays.includes(month) && day > 30) {
                valid = false;
            }
    
            // Check February and leap years
            if (month === invalidDates.febrary) {
                const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
                if (day > (isLeapYear ? 29 : 28)) {
                    valid = false;
                }
            }
    
            if (!valid && !Number.isNaN(day) && !Number.isNaN(month) && !Number.isNaN(year) && value !== null && value !== '') {
                toast.warning('Неверный формат даты');
                this.setState({validFilter: false});
            } 
            // else {
            //     this.setState({validFilter: true});
            // }
        };
    
        const handleChangeFromDate = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            validateDate(value);
            this.setState({dateFrom: value});
        };

        const handleChangeToDate = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            validateDate(value);
            this.setState({dateTo: value});
        };

        const handleIconCalendarFromClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            pd(e);

            this.setState({isCalendarOpenFrom: !isCalendarOpenFrom});
        };

        const handleIconCalendarToClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            pd(e);

            this.setState({isCalendarOpenTo: !isCalendarOpenTo});
        };

        const amountWithProcents = (amount: string, percent: string, sign: '+' | '-'): number => {
            const multiplier = sign === '+' ? 1 : -1;
            return parseFloat(amount) * (1 + multiplier * (parseFloat(percent) / 100));
        };

        return (
            <div className="tabs__content active">
                <div className="deal">
                    <ul className="deal__list deal__list--my-otc">
                        {tabs.map((tab) => <li key={`tabs-${tab.status}`} className={classNames('deal__item', { active: status === tab.status })} onClick={() => this.setState({ status: tab.status })}>{t(tab.text)}</li>)}
                    </ul>
                    <div className="deal__filter filter">
                        <h3 className="filter__title">{t('otc.deals.filter.search')}</h3>
                        <div className="filter__date">
                            <form className="filter__form" onSubmit={this.onSubmitForm}>
                                <h4 className="filter__date-title">{t('otc.deals.filter.interval')}</h4>
                                <div className="filter__wrapper-dates">
                                    <InputMask
                                        className={"filter__date-input"}
                                        type="text"
                                        mask="99.99.9999"
                                        value={dateFrom}
                                        // requierd={true}
                                        onChange={handleChangeFromDate}
                                        placeholder="дд.мм.гггг"
                                    />
                                    <div className="filter__from-date-calendar calendar" style={{ position: 'relative', display: 'inline-block' }}>
                                        <button 
                                            className="calendar__button-icon"
                                            onClick={handleIconCalendarFromClick} 
                                            style={{ background: 'none', border: 'none', cursor: 'pointer' }}

                                        >
                                            <svg className="calendar__icon" width="73" height="71" viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M60.129 5.23801H56.2188V3.63641C56.2188 1.97621 54.879 0.636414 53.2188 0.636414C51.5586 0.636414 50.2188 1.97621 50.2188 3.63641V5.23801H38.9298V3.63641C38.9298 1.97621 37.59 0.636414 35.9298 0.636414C34.2696 0.636414 32.9298 1.97621 32.9298 3.63641V5.23801H21.6488V3.63641C21.6488 1.97621 20.309 0.636414 18.6488 0.636414C16.9886 0.636414 15.6488 1.97621 15.6488 3.63641V5.23801H12.8207C6.03954 5.23801 0.531738 10.7497 0.531738 17.527V58.347C0.531738 65.1282 6.04344 70.636 12.8207 70.636H60.1217C66.9029 70.636 72.4107 65.1243 72.4107 58.347V17.531C72.4107 10.7498 66.8983 5.23801 60.129 5.23801ZM66.4102 58.351C66.4102 61.8198 63.5899 64.6401 60.1211 64.6401H12.8201C9.35134 64.6401 6.53104 61.8198 6.53104 58.351V29.64H66.41L66.4102 58.351ZM66.4102 23.64H6.53124V17.5306C6.53124 14.0618 9.35154 11.2415 12.8203 11.2415H15.6484V14.0813C15.6484 15.7415 16.9882 17.0813 18.6484 17.0813C20.3086 17.0813 21.6484 15.7415 21.6484 14.0813V11.2415H32.9294V14.0813C32.9294 15.7415 34.2692 17.0813 35.9294 17.0813C37.5896 17.0813 38.9294 15.7415 38.9294 14.0813V11.2415H50.2104V14.0813C50.2104 15.7415 51.5502 17.0813 53.2104 17.0813C54.8706 17.0813 56.2104 15.7415 56.2104 14.0813V11.2415H60.1206C63.5894 11.2415 66.4097 14.0618 66.4097 17.5306L66.4102 23.64Z" fill="white"/>
                                            </svg>
                                        </button>
                                        {isCalendarOpenFrom && (
                                            <div
                                            className="calendar__wrapper-day-picker"
                                            >
                                                <DayPicker
                                                    captionLayout='dropdown'
                                                    className="calendar__day-picker"
                                                    // locale={{
                                                    //     localize: language
                                                    // }}
                                                    onDayClick={(day) => {
                                                        this.setState({dateFrom: `${day.getDate() < 10 ? `0` + day.getDate() : day.getDate()}.${day.getMonth() < 9 ? `0` + (day.getMonth() + 1) : (day.getMonth() + 1)}.${day.getFullYear()}`});
                                                        this.setState({isCalendarOpenFrom: false});
                                                    }} 
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="filter__wrapper-dates">
                                    <InputMask
                                        className={"filter__date-input"}
                                        type="text"
                                        mask="99.99.9999"
                                        value={dateTo}
                                        // requierd={true}
                                        onChange={handleChangeToDate}
                                        placeholder="дд.мм.гггг"
                                    />
                                    <div className="filter__from-date-calendar calendar" style={{ position: 'relative', display: 'inline-block' }}>
                                        <button 
                                            className="calendar__button-icon"
                                            onClick={handleIconCalendarToClick} 
                                            style={{ background: 'none', border: 'none', cursor: 'pointer' }}

                                        >
                                            <svg className="calendar__icon" width="73" height="71" viewBox="0 0 73 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M60.129 5.23801H56.2188V3.63641C56.2188 1.97621 54.879 0.636414 53.2188 0.636414C51.5586 0.636414 50.2188 1.97621 50.2188 3.63641V5.23801H38.9298V3.63641C38.9298 1.97621 37.59 0.636414 35.9298 0.636414C34.2696 0.636414 32.9298 1.97621 32.9298 3.63641V5.23801H21.6488V3.63641C21.6488 1.97621 20.309 0.636414 18.6488 0.636414C16.9886 0.636414 15.6488 1.97621 15.6488 3.63641V5.23801H12.8207C6.03954 5.23801 0.531738 10.7497 0.531738 17.527V58.347C0.531738 65.1282 6.04344 70.636 12.8207 70.636H60.1217C66.9029 70.636 72.4107 65.1243 72.4107 58.347V17.531C72.4107 10.7498 66.8983 5.23801 60.129 5.23801ZM66.4102 58.351C66.4102 61.8198 63.5899 64.6401 60.1211 64.6401H12.8201C9.35134 64.6401 6.53104 61.8198 6.53104 58.351V29.64H66.41L66.4102 58.351ZM66.4102 23.64H6.53124V17.5306C6.53124 14.0618 9.35154 11.2415 12.8203 11.2415H15.6484V14.0813C15.6484 15.7415 16.9882 17.0813 18.6484 17.0813C20.3086 17.0813 21.6484 15.7415 21.6484 14.0813V11.2415H32.9294V14.0813C32.9294 15.7415 34.2692 17.0813 35.9294 17.0813C37.5896 17.0813 38.9294 15.7415 38.9294 14.0813V11.2415H50.2104V14.0813C50.2104 15.7415 51.5502 17.0813 53.2104 17.0813C54.8706 17.0813 56.2104 15.7415 56.2104 14.0813V11.2415H60.1206C63.5894 11.2415 66.4097 14.0618 66.4097 17.5306L66.4102 23.64Z" fill="white"/>
                                            </svg>
                                        </button>
                                        {isCalendarOpenTo && (
                                            <div
                                            className="calendar__wrapper-day-picker"
                                            >
                                                <DayPicker
                                                    className="calendar__day-picker"
                                                    captionLayout='dropdown'
                                                    // locale={{
                                                    //     localize: language
                                                    // }}
                                                    onDayClick={(day) => {
                                                        this.setState({dateTo: `${day.getDate() < 10 ? `0` + day.getDate() : day.getDate()}.${day.getMonth() < 9 ? `0` + (day.getMonth() + 1) : (day.getMonth() + 1)}.${day.getFullYear()}`});
                                                        this.setState({isCalendarOpenTo: false});
                                                    }} 
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <span className="filter__amount-title">{t('otc.deals.filter.amount')}</span>
                                <InputMask
                                    className="filter__input-min-amount"
                                    type="number"
                                    value={minAmount}
                                    // requierd={true}
                                    placeholder="0"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.setState({minAmount: event.target.value})}}
                                />
                                <InputMask
                                    className="filter__input-max-amount"
                                    type="number"
                                    value={maxAmount}
                                    // requierd={true}
                                    placeholder="0"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {this.setState({maxAmount: event.target.value})}}
                                />
                                <Button kind="edit" type="submit">{t('otc.deals.filter.btn-apply')}</Button>
                            </form>
                        </div>
                    </div>
                    <div className="deal__content active">
                        <div className="table deal-active scroll-wrap">
                            <div className="table__wrap">
                                <div className="table__head">
                                    {headFields.map((field) => field.condition && <div key={`head-field-${field.modificatorClass}`} className={`table__field table__field_row table__field--${field.modificatorClass}`}><span>{t(field.text)}</span></div>)}
                                </div>
                                <div className="table__body scroll-wrap">
                                    {
                                        noDeals.map((deal) => {
                                            return (
                                                (status === deal.conditions.main) &&
                                                (deals.filter(d => ((d.status === deal.conditions.main || d.status === deal.conditions.option) && (d.state !== 'CREATED'))).length) === 0 ? 
                                                    <div key={`no-my-deals-${deal.conditions.main}`} className="no-my-deals">
                                                        {t(deal.text)}
                                                    </div>
                                                : null 
                                            )  
                                        })
                                    }
                                    {((deals.filter(d => ((d.status === status || (status === AppOtcDealStatusChoices.Active && d.status === AppOtcDealStatusChoices.Arbitrage)) && (d.state !== 'CREATED') && (d.state !== 'CANCELED' || (d.status === AppOtcDealStatusChoices.Canceled && d.state === 'CANCELED'))))).sort((a, b) => a.id < b.id ? 1 : -1)).map(d => (
                                        <Link to={`/otc/deals/${d.id}`} className="table__row" key={`deal-${d.id}`}>
                                            <div className="table__field table__field--id"><span>{d.id}</span> {d.hasUnread && <span className='unread-marker'>*</span>}</div>
                                            <div className="table__field table__field--date-and-time">
                                                <span>
                                                    {(DateTime.fromISO(d.datetime).toFormat('D') === DateTime.now().toFormat('D')) && (DateTime.fromISO(d.datetime).toFormat('t'))}
                                                    {(DateTime.fromISO(d.datetime).toFormat('D') !== DateTime.now().toFormat('D')) && (DateTime.fromISO(d.datetime).toFormat('D t'))}
                                                </span>
                                            </div>
                                            <div className="table__field table__field--seller"><span>{d.ad.action === 'SELL' ? d.ad.user.name : d.user.name}</span></div>
                                            <div className="table__field table__field--buyer"><span>{d.ad.action === 'BUY' ? d.ad.user.name : d.user.name}</span></div>
                                            {(status === AppOtcDealStatusChoices.Active) && 
                                                <div className={classNames('table__field table__field--status', { success: d.status === AppOtcDealStatusChoices.Active, arbitration: d.status === AppOtcDealStatusChoices.Arbitrage })}>
                                                    <span>{d.status === AppOtcDealStatusChoices.Active ? `${t('otc.deals.status.active')}` : d.status === AppOtcDealStatusChoices.Arbitrage ? `${t('otc.deals.status.arbitrage')}` : null}</span>
                                                </div>                                    
                                            }
                                            <div className="table__field table__field_col table__field--transfer-amount">
                                                <span>{
                                                    this.authStore.profile?.id === d.ad?.user?.id ? (
                                                        d.amount
                                                    )
                                                    : (
                                                        d.ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer 
                                                        ? tf(amountWithProcents(d.amount, d.ad.priceExtraPercent, '+')) 
                                                        : tf(amountWithProcents(d.amount, d.ad.priceExtraPercent, '-'))
                                                    )
                                                } {d.ad.currency}</span>
                                            </div>
                                            <div className="table__field table__field_col table__field--payment-method">
                                                <span>{d.ad.paymentMethod}</span>
                                            </div>
                                            <div className="table__field table__field--commission">
                                                <span
                                                    data-tooltip-id="commission-direction-tooltip"
                                                    data-tooltip-content={`${
                                                        this.authStore.profile?.id !== d.ad?.user?.id ? (d.ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'Вы доплачиваете' : d.ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'Доплата вам' : null)
                                                        : (d.ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'Вы доплачиваете' : d.ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'Доплата вам' : null)
                                                    }`}
                                                    className={classNames(``, {
                                                        [`table__field--commission-minus`]: AppOtcAdPriceExtraPercentDirectionChoices.Creator === d.ad.priceExtraPercentDirection,
                                                        [`table__field--commission-plus`]: AppOtcAdPriceExtraPercentDirectionChoices.Customer === d.ad.priceExtraPercentDirection
                                                    })}
                                                >
                                                    {
                                                        listDirectiionComission[d.ad.priceExtraPercentDirection]
                                                    }
                                                    {d.ad.priceExtraPercent}%
                                                </span>
                                                <Tooltip className="commission-tooltip" id="commission-direction-tooltip" />
                                            </div>
                                            <div className="table__field table__field_col table__field--get-balance">
                                                <span>{
                                                    this.authStore.profile?.id !== d.ad?.user?.id ? (
                                                        d.amount
                                                    )
                                                    : (
                                                        d.ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer 
                                                        ? tf(amountWithProcents(d.amount, d.ad.priceExtraPercent, '+')) 
                                                        : tf(amountWithProcents(d.amount, d.ad.priceExtraPercent, '-'))
                                                    )
                                                } {d.ad.currency}</span>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OTCDealsTab);
