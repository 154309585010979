module.exports = {
    "main": {
        "list-why": {
            "one": "Наличные и безналичные рубли, Bitcoin, USDT",
            "two": "Вывод на карты Сбербанк, Тиньков, Альфа-банк",
            "three": "Минимальные комиссии от 0.1% до 0.2% за сделку",
            "four": "Биржа работает круглосуточно в полном объеме"
        },
        "btn-sign-up": "Зарегистрироваться",
        "promo-sign-up": "Зарегистрируй аккаунт прямо сейчас",
        "btn-sign-in": "Войти",
        "exchange-intended": {
            "title": "Для кого предназначена наша биржа",
            "list": {
                "one": {
                    "title": "Трейдер",
                    "list": {
                        "one": "Единственная биржа с возможностью пополнения или вывода баланса наличными",
                        "two": "Работаем по всей России",
                        "three": "Вывод рублей на карты/счета Сбербанка, Тинькова, Альфабанка, ВТБ",
                        "four": "Комиссия всего от 0.1% за сделку"
                    }
                },
                "two": {
                    "title": "Майнер",
                    "list": {
                        "one": "Продавая свою криптовалюту на бирже вы гарантировано получите наиболее высокий курс и избежите лишних комиссий посредников",
                        "two": "Комфортная, автоматизированная сделка, не вставая из за компьютера",
                        "three": "Получение наличных в кассе, любые регионы, карты, счета, кэшины"
                    }
                },
                "three": {
                    "title": "Обменник",
                    "list": {
                        "one": "Несколько вариантов интеграции",
                        "two": "Низкая комиссия на отправку криптовалюты",
                        "three": "Множество обменников уже зарабатывают с биржей",
                        "four": "Свяжитесь с нами, чтобы узнать о возможных вариантах интеграции"
                    }
                }
            }
        }
    },
    "otc": {
        "tabs": {
            "my-invitation": "Мои объявления",
            "my-trades": "Мои сделки",
            "my-chat": "Чаты"
        },
        "index": {
            "top-up-balance": "Пополнить баланс биржи",
            "withdraw-balance": "Вывести баланс с биржи",
            "seller": "Продавец",
            "payment-method": "Метод оплаты",
            "description": "Условия",
            "commission": "Комиссия",
            "amount": "Сумма",
            "no-ads": "Нет активных объявлений",
            "btn-buy": "Купить",
            "btn-sell": "Продать",
            "btn-edit": "Редактировать"
        },
        "my-ads": {
            "title": "Мои объявления",
            "seller": "Продавец",
            "buyer": "Покупатель",
            "payment-method": "Метод оплаты",
            "commission": "Комиссия",
            "amount": "Сумма",
            "status": "Статус",
            "status-active": "Активно",
            "status-no-active": "Не активно",
            "btn-edit": "Изменить",
            "ad-changing": "Изменяется",
            "btn-open": "Включить",
            "btn-close": "Выключить",
            "btn-delete": "Удалить",
            "you-sure": "Вы уверены?",
            "deal-complited": "Сделка завершена",
            "no-my-ads": "Пока нет ваших объявлений",
            "ad-info": {
                "creature-title": "Создание объявления",
                "edit-title": "Изменение объявления",
                "direction": "Направление",
                "buy": "Покупка",
                "sell": "Продажа",
                "currency": "Валюта",
                "min-amount": "Минимальная сумма",
                "max-amount": "Максимальная сумма",
                "payment-method": "Метод оплаты",
                "payment-details": "Реквизиты",
                "description": "Описание",
                "commission": "Комиссия",
                "btn-cancel": "Отмена",
                "btn-save": "Опубликовать"
            },
            "message": {
                "ad-created": "Объявление создано",
                "ad-edited": "Объявление изменено",
                "ad-delete": "Объявление удалено",
                "ad-open": "Объявление включено",
                "ad-close": "Объявление выключено"
            }
        },
        "my-deals": {
            "tabs": {
                "active": "Активные",
                "completed": "Завершённые",
                "canceled": "Отменённые"
            }
        },
        "my-deal-ad": {
            "title": "Объявление",
            "seller": "Продавец",
            "buyer": "Покупатель",
            "payment-method": "Метод оплаты: ",
            "commission": "Комиссия",
            "amount": "Сумма",
            "btn-sell": "Продать",
            "btn-buy": "Купить",
            "btn-paid": "Оплатил",
            "btn-arbitration": "Проблема/Арбитраж",
            "btn-cancel": "Отмена сделки",
            "wait-pay": "Ожидайте оплату...",
            "wait-offer": "Ожидайте предложение...",
            "condition": "Условие",
            "status": {
                "title": "Статус",
                "buy": {
                    "get": "Получите:",
                    "get-commission": "К оплате:",
                    "payment-details": "Реквизиты:",
                    "to-pay": "К оплате:",
                    "amount": "Сумма к получению:",
                    "btn-payment-received": "Оплата получена",
                    "confirmation-payment": "Ожидайте подтверждения оплаты..."
                },
                "sell": {

                },
                "completed": "Сделка завершена",
                "cancelled": "Сделка отменена"
            },
            "message": {
                
            }
        },
        "chat": {
            "send-to-message": "Написать сообщение...",
            "list": "Список чатов"
        },
        "deals": {
            "date-and-time": "Дата и время",
            "id": "ID",
            "seller": "Продавец",
            "buyer": "Покупатель",
            "status": {
                "title": "Статус",
                "active": "Активный",
                "completed": "Завершённый",
                "canceled": "Отмененный",
                "arbitrage": "Арбитраж"
            },
            "no-my-deals-cancelled": "Отсутствуют отменённые сделки",
            "no-my-deals-completed": "Отсутствуют завершённые сделки",
            "no-my-deals-active": "Отсутствуют активные сделки",
            "amount": "Сумма перевода",
            "payment-method": "Метод оплаты",
            "commission": "Комиссия",
            "get-on-balance": "Получите на баланс",
            "filter": {
                "search": "Поиск",
                "interval": "Интервал дат:",
                "amount": "Сумма:",
                "btn-apply": "Применить"
            }
        }
    },
    "exchange": {
        "by-market": "По рынку",
        "limit": "Лимит",
        "buy": "Купить",
        "sell": "Продать",
        "amount": "Количество",
        "quotation": "Курс",
        "total": "Всего",
        "sale": "Продажа",
        "purchase": "Покупка",
        "chart": {
            "title": "График"
        },
        "open-voucher": {
            "title": "Открытые ордера",
            "sell": "Продажа",
            "buy": "Покупка",
            "direction": "Направление",
            "type": "Тип",
            "price": "Цена",
            "volume": "Объём",
            "data": "Дата и время",
            "button-cancel": "Отменить",
            "rp": "Комиссия"
        },
        "history": {
            "title": "История"
        },
        "message": {
            "info": {
                "max-amount": "Максимальная сумма"
            },
            "error": {
                "volume-order": "Неверно указан объём ордера",
                "count-order": "Неверно указано количество",
                "course-ranges": "Отклонение от текущего курса более 15%"
            },
            "success": {
                "created-order": "Ордер создан",
                "buy-order": "Ордер(ы) куплен(ы)",
                "sell-order": "Ордер(ы) продан(ы)"
            }
        }
    },
    "signin": {
        "no-account": "Нет аккаунта?",
        "title": "Вход",
        "sign-up": "Регистрация",
        "or-sign-in-up": "Или войти/зарегестрироваться с помощью:"
    },
    "sign-up": {
        "title": "Регистрация",
        "no-account": "Уже есть аккаунт?"
    },
    "header": {
        "exchange": "Биржа",
        "assets": "Активы",
        "profile": "Профиль",
        "sigin": "Вход",
        "register": "Регистрация"
    },
    "footer": {
        "telegram-bot": "Telegram",
        "help": "Служба поддержки"
    },
    "assets": {
        "tabs": {
            "replenishment": "Пополнение",
            "withdrawal": "Вывод"
        },
        "type-operation": {
            "replenishment": "Пополнение в",
            "withdrawal": "Вывод в" 
        },
        "deposite-means": "Способ депозита",
        "withdrawal-means": "Способ вывода",
        "deposit-options": {
            "maxcode": "Пополнение MaxCode",
            "exmocode": "Пополнение ExmoCode"
        },
        "withdrawal-options": {
            "maxcode": "Вывод MaxCode",
            "exmocode": "Вывод ExmoCode"
        },
        "withdrawal-sum": "Сумма вывода",
        "condition": {
            "title": "Условия",
            "description": "Бесплатно"
        },
        "code-replenishment": "Код пополнения",
        "button-replenishment": "Пополнить",
        "button-withdrawal": "Вывести",
        "history-replenishments": {
            "title": "История пополнений",
            "time": "Время",
            "number": "Номер",
            "sum": "Сумма",
            "status": "Статус",
            "code-status": {
                "success": "Подтверждено",
                "warning": "Отменено",
                "wait": "Ожидание"
            }
        },
        "history-withdrawal": {
            "title": "История вывода",
            "time": "Время",
            "code": "Код",
            "sum": "Сумма",
            "status": "Статус",
            "adderess": "Адрес",
            "code-status": {
                "redeemed": "Использован",
                "created": "Копировать"
            },
            "copy-code": "Код скопирован"
        },
        "cryptocurrency-deposit-options": {
            "first": "Используйте Ваш кошелёк для внесения депозита на баланс биржы.",
            "second": "Cкопируйте адрес ниже в Ваш кошелёк, далее введите сумму зачисления, а после подтвердите перёвод.",
            "third": "Как закончиться перечислиться сумма, будет пополнет ваш баланс на бирже. Статус перевода можете проверить справа в истории"
        },
        "min-amount-deposit": "Минимальная сумма депозита:",
        "scan-qr-code": "Или отсканируйте QR код для оплаты.",
        "cryptocurrency-withdrawal-options": {
            "first": "Введите адрес и сумму. Дожидайтесь подтверждения заявки в течении 30 минут."
        },
        "adderess-withdrawal": "Адрес вывода",
        "amount-withdrawal": "Сумма вывода",
        "warning-withdrawal-info": "Внимание! Для Bech32 адресов (начинаются с bc1) комиссия на вывод всегда 0.0004 BTC, вне зависимости от объёма.",
        "warning-withdrawal-info-usdt": "Это адрес кошелька USDT в формате TRC20. Будьте внимательны!",
        "warning-deposit-info": "Это адрес депозита USDT в формате TRC20. Будьте внимательны!",
        "copy-adderess": "Адрес скопирован",
        "partner": {
            "withdrawal": "Вывод с партнерского счета",
            "hint": "На рублёвый счет"
        },
        "tabs-partner": "Партнёрская программа",
        "message": {
            "bid-created": "Заявка создана",
            "transferred-to-ruble": "Переведено на рублёвый счёт"
        },
        "no-assets-deposit": "История отсутствует",
        "no-assets-withdrawal": "История отсутствует"
    },
    "status-server": {
        "assets": {
            "success-replenishment": "Пополнение произведено успешно",
            "success-deposit-code": "Код пополнения создан, см. в истории вывода"
        }
    },
    "profile": {
        "title": "Аккаунт",
        "button-edit": "Изменить",
        "button-cancel": "Отмена",
        "button-open": "Открыть",
        "button-close": "Закрыть",
        "button-turn-on": "Включить",
        "button-turn-off": "Отключить",
        "button-save": "Сохранить",
        "options": {
            "nickname": {
                "title": "Никнейм",
                "description": "Ваш никнейм:"
            },
            "phone": {
                "title": "Телефон",
                "description": "Ваш номер телефона:",
                "description-not-specified": "Добавьте номер телефона"
            },
            "password": {
                "title": "Пароль",
                "description": "У вас есть возможность изменить пароль"
            },
            "2FA": {
                "title": "2FA",
                "description": "У вас есть возможность изменить 2FA"
            },
            "telegram": {
                "title": "Telegram",
                "description": "Ваш Telegram: ",
                "description-not-specified": "Добавьте ваш Telegram"
            },
            "partner": {
                "title": "Партнёрская программа",
                "description": "Ваша реферальная ссылка:"
            },
            "email-confirm": {
                "title": "Ваш аккаунт подтвержден",
                "description": "Благодаря этому мы защитим вашу учетную запись, используя как пароль так и номер телефона, а также электронную почту."
            }
        },
        "tabs": {
            "current": "Текущий",
            "new": "Новый",
            "new-replay": "Новый повтор",
            "nickname": {
                "title": "Никнейм"
            },
            "phone": {
                "title": "Телефон"
            },
            "password": {
                "title": "Пароль"
            },
            "2FA": {
                "title": "2FA",
                "description": "Отсканируйте QR-код при помощи Google Authenticator для включения 2FA",
                "description-additinal": "Или используйте текстовый секрет: ",
                "code": "Код 2FA"
            },
            "partner": {
                "title": "Партнёрская программа",
                "description-options": {
                    "first": "Можешь получать 0.1% от каждой сделки своих рефералов, поэтому приглашай своих коллег и друзей! ",
                    "second": "Можешь рассказать о работе с биржой Maxbit своим рефералам!"
                },
                "your-link": "Ваша реферальная ссылка",
                "your-link-text": "Ваша реферальная ссылка с текстом",
                "link-text": "Получайте до 50% от комиссии ваших рефералов, зарегистрировавшись по моей ссылке!"
            }
        },
        "exit": "Выйти из аккаунта",
        "copy-success": "Ссылка скопирована"
    },
    "message": {
        "no-auth-pages": "Для просмотра страницы войдите в аккаунт"
    }
};