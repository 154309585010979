import React from 'react';
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import { RecoveryModal } from "./RecoveryModal";
import { CalcModal } from "./CalcModal";
import { OTCEditAd } from "./OTCEditAd";

export class Modals extends React.Component {
    render() {
        return (
            <>
                <SignInModal />
                <SignUpModal />
                <RecoveryModal />
                <CalcModal />
            </>
        )
    }
}
