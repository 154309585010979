import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// import {useTranslation} from 'react-i18next';
interface IFooterProps {
    t: any;
}

interface IFooterState {
}

class Footer extends React.Component<IFooterProps, IFooterState> {
    render() {
        // const {t} = useTranslation();
        const { t } = this.props;
        return (
            <footer className="footer">
                <div className="container">
                    {/* <div className="contact contact--info">
                    <img width="15" height="15" src={require("../images/info-icon.svg")}/>
                        <span className="contact__title">Инфо. канал:</span>
                        <a className="contact__link" href="#">@channel</a>
                    </div>
                    <div className="contact contact--chat">
                        <img width="15" height="15" src={require("../images/chat-icon.svg")}/>
                        <span className="contact__title">Чат: </span>
                        <a className="contact__link" href="#">@chatexchange</a>
                    </div> */}
                    <div className="contact contact--currency-bot">
                        <img width="15" height="15" src={require("../images/telegram-icon.svg")}/>
                        <a className="contact__link" href="https://t.me/COIN_COLA_NEW_BOT">{t('footer.telegram-bot')}</a>
                    </div>
                    <div className="contact contact--help">
                        <a className="contact__link" href="#">
                            <img width="15" height="15" src={require("../images/help-icon.svg")}/>
                            <span>{t('footer.help')}</span>
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
}


/*
<div className="bg-logo"><img src={require('../images/Logo_1200.gif')} alt=""/></div>

<div className="bg-logo"><img src={require('../images/logo.png')} alt=""/></div>
<div className="contact">
                            <span className="contact__name">TOP EXCHANGE</span>
                            <span className="base-text">Биржа криптовалюты и токенов</span>
                        </div>
*/

export default withTranslation()(Footer);