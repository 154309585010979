import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';

import {BalanceExchangeType, CoinType} from "../utils/graphql";
import { rootStore } from '../Application';
import {tf, divideNumberByPieces} from "../utils/utilities";

import { TCurrency } from '../types/currency';

interface IInfoCurrencyWallet {
    currency: TCurrency;
    mantissa: number;
    currencyToUSD: number;
    currencyToRub: number;
};

const getComponentImageSymbol = (symbol) => {
    switch(symbol) {
        case 'BTC':
            return (
                <img src={require(`../images/btc.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );
        case 'RUB':
            return (
                <img src={require(`../images/rub.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );
        case 'USDT':
            return (
                <img src={require(`../images/usdt.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );
        case 'LTC':
            return (
                <img src={require(`../images/ltc.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );  
        case 'UZS':
            return (
                <img src={require(`../images/uzs.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );
        case 'AED':
            return (
                <img src={require(`../images/aed.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );
        case 'KZT':
            return (
                <img src={require(`../images/kzt.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );
        case 'XMR':
            return (
                <img src={require(`../images/xmr.svg`)} width="40" height="40" className="info-currency-wallet__img"/>
            );                 
    }
}



const InfoCurrencyWallet: FC<IInfoCurrencyWallet> = ({currency, mantissa, currencyToUSD, currencyToRub}) => {

    const onGetBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === currency)
            if (findOne) {
                return findOne.balance
            }
        }
        return 0;
    }
    const onGetFreezBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === currency)
            if (findOne) {
                return findOne.freezeBalance
            }
        }
        return 0;
    }

    return (
        <section className="info-currency-wallet">
            {getComponentImageSymbol(currency)}
            <div className="info-currency-wallet__wrapper-balance">
                <span className="info-currency-wallet__balance">{divideNumberByPieces(tf(onGetBalance(),mantissa))} {currency}</span>
                <span className="info-currency-wallet__balance-frozen">
                    <svg width="20" height="20" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.00039 0.300049C2.68039 0.300049 1.60039 1.38005 1.60039 2.70005V4.02205C1.22288 4.3586 0.92081 4.77119 0.713999 5.23272C0.507189 5.69425 0.400315 6.1943 0.400391 6.70005C0.400391 7.65483 0.779675 8.5705 1.45481 9.24563C2.12994 9.92076 3.04561 10.3 4.00039 10.3C4.95517 10.3 5.87084 9.92076 6.54598 9.24563C7.22111 8.5705 7.60039 7.65483 7.60039 6.70005C7.60047 6.1943 7.49359 5.69425 7.28678 5.23272C7.07997 4.77119 6.7779 4.3586 6.40039 4.02205V2.70005C6.40039 1.43005 5.39339 0.407049 4.14239 0.329049C4.09722 0.310816 4.04909 0.300988 4.00039 0.300049ZM4.00039 1.10005C4.88839 1.10005 5.60039 1.81205 5.60039 2.70005V3.47805C5.10355 3.22983 4.55578 3.10061 4.00039 3.10061C3.445 3.10061 2.89723 3.22983 2.40039 3.47805V2.70005C2.40039 1.81205 3.11239 1.10005 4.00039 1.10005ZM4.00039 5.90005C4.17686 5.89863 4.34881 5.95583 4.48928 6.06266C4.62974 6.1695 4.73077 6.31994 4.77653 6.49038C4.82229 6.66082 4.81018 6.84163 4.74211 7.00445C4.67403 7.16727 4.55385 7.30289 4.40039 7.39005V7.90005C4.40039 8.00613 4.35825 8.10788 4.28323 8.18289C4.20822 8.25791 4.10648 8.30005 4.00039 8.30005C3.8943 8.30005 3.79256 8.25791 3.71755 8.18289C3.64253 8.10788 3.60039 8.00613 3.60039 7.90005V7.39005C3.44613 7.30343 3.32511 7.16788 3.25647 7.00482C3.18783 6.84175 3.17547 6.66047 3.22134 6.4896C3.26721 6.31873 3.3687 6.16801 3.50978 6.06125C3.65086 5.95448 3.82348 5.89777 4.00039 5.90005Z" fill="white" fillOpacity="0.5"/>
                    </svg>
                    {divideNumberByPieces(tf(onGetFreezBalance(),mantissa))} {currency}</span>
            </div>
            <div className="info-currency-wallet__wrapper-currency">
                {
                    currencyToUSD !== 0 ? 
                    <span className="info-currency-wallet__currency info-currency-wallet__currency--usd">{divideNumberByPieces((parseFloat(tf(onGetBalance(),mantissa)) * currencyToUSD).toFixed(2))} USD</span>
                    : ``
                }
                {
                    currencyToRub !== 0 ? 
                    <span className="info-currency-wallet__currency info-currency-wallet__currency--rub">{divideNumberByPieces((parseFloat(tf(onGetBalance(),mantissa)) * currencyToRub).toFixed(2))} RUB</span>
                    : ``
                }
            </div>
        </section>
    );
};

export default observer(InfoCurrencyWallet);