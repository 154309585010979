import React from 'react';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { NavLink, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import { withTranslation } from 'react-i18next';

import {AuthStore, CoinStore} from "../stores";

import { tf } from "../utils/utilities";
import {BalanceExchangeType} from "../utils/graphql";

import AssetsRubTab from "./assets/AssetsRubTab";
import AssetsKztTab from "./assets/AssetsKztTab";
import AssetsBtcTab from "./assets/AssetsBtcTab";
import AssetsUsdtTab from "./assets/AssetsUsdtTab";
// import { AssetsFnkTab } from "./assets/AssetsFnkTab";
import AssetsXmrTab from "./assets/AssetsXmrTab";
import AssetsLtcTab from "./assets/AssetsLtcTab";
import AssetsPartnerTab from "./assets/AssetsPartnerTab";
import TabOne from '../components/active/tab';

interface IAssetsPageProps {
    t: any;
}

interface IAssetsPageState {
    balanses?: BalanceExchangeType[];
}

@observer
class AssetsPage extends React.Component<IAssetsPageProps, IAssetsPageState> {
    state: IAssetsPageState = {
        balanses: [],
    }

    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    @resolve(CoinStore)
    declare protected readonly coinStore: CoinStore;


    async componentDidMount() {
        await this.onGetCoin()
    }
    onGetCoin = async () => {
        await this.coinStore.getBalancesAll()
    }  

    render() {
        const profile = this.authStore.profile;
        const { t } = this.props;
    
        return (
            <main className="main">
                <section className="assets-section">
                    <div className="container">
                        <div className="tabs js-tabs">
                            <ul className="tabs__list">
                                <NavLink to='/assets/rub' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/rub.png')} alt=""/></div>
                                    <TabOne symbol='RUB' mantissa={2}/>
                                </NavLink>
                                {/* <NavLink to='/assets/kzt' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/kzt.png')} alt=""/></div>
                                    <TabOne symbol='KZT' mantissa={2}/>
                                </NavLink> */}
                                <NavLink to='/assets/btc' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/btc.svg')} alt=""/></div>
                                    <TabOne symbol='BTC' mantissa={8}/>
                                </NavLink>

                                <NavLink to='/assets/xmr' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/xmr-icon.png')} alt=""/></div>
                                    <TabOne symbol='XMR' mantissa={8}/>
                                </NavLink>

                                <NavLink to='/assets/ltc' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/ltc.svg')} alt=""/></div>
                                    <TabOne symbol='LTC' mantissa={2}/>
                                </NavLink>

                                <NavLink to='/assets/usdt' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/usdt.svg')} alt=""/></div>
                                    <TabOne symbol='USDT'mantissa={2} />
                                </NavLink>
                                <NavLink to='/assets/partner' className="tabs__item">
                                    <div className="tabs__icon"><img src={require('../images/rub22.svg')} alt=""/></div>
                                    <div className="tabs__item-info"><span className="tabs__item-name">{t('assets.tabs-partner')}</span>
                                        <div className="tabs__item-line">
                                            <span>{tf(profile?.partnerBalance)}</span>
                                        </div>
                                    </div>
                                </NavLink>
                            </ul>
                            <Switch>
                                <Route exact path='/assets'><Redirect to='/assets/rub' /></Route>
                                <Route path='/assets/rub' component={AssetsRubTab} />
                                {/* <Route path='/assets/kzt' component={AssetsKztTab} /> */}
                                <Route path='/assets/btc' component={AssetsBtcTab} />
                                <Route path='/assets/usdt' component={AssetsUsdtTab} />
                                {/* <Route path='/assets/fnk' component={AssetsFnkTab} /> */}
                                <Route path='/assets/xmr' component={AssetsXmrTab} />
                                <Route path='/assets/ltc' component={AssetsLtcTab} />
                                <Route path='/assets/partner' component={AssetsPartnerTab} />
                            </Switch>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default withTranslation()(AssetsPage);
