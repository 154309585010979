import React from 'react';
import { OperationLogType } from "../utils/graphql";
import { DateTime } from "luxon";
import { tf, divideNumberByPieces } from "../utils/utilities";
import { withTranslation } from 'react-i18next';

interface IHistoryDepositProps {
    operations?: OperationLogType[];
    operationsExmoCode?: any;
    mantissa: number;
    t: any;
}

interface IHistoryDepositState {
}

class HistoryDeposit extends React.Component<IHistoryDepositProps, IHistoryDepositState> {
    render() {
        const { t, operations, mantissa, operationsExmoCode } = this.props;

        return (
            <div className="table assets scroll-wrap">
                <div className="table__wrap">
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>{t('assets.history-replenishments.time')}</span>
                        </div>
                        <div className="table__field"><span>{t('assets.history-replenishments.number')}</span></div>
                        <div className="table__field"><span>{t('assets.history-replenishments.sum')}</span></div>
                        <div className="table__field"><span>{t('assets.history-replenishments.status')}</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {(operations?.length) === 0 ? 
                            <div className="no-assets">
                                {t('assets.no-assets-deposit')}
                            </div>
                            : null    
                        }
                        {operations?.map(operation => (
                            <div className="table__row" key={operation.id}>
                                <div className="table__field"><span>{DateTime.fromISO(operation.datetime).toFormat('D t')}</span></div>
                                <div className="table__field"><span>{operation.id}</span></div>
                                <div className="table__field"><span>{divideNumberByPieces(tf(operation.value, mantissa))}</span></div>
                                {operation.status === true && <div className="table__field success"><span>{t('assets.history-replenishments.code-status.success')}</span></div>}
                                {operation.status === false && <div className="table__field warning"><span>{t('assets.history-replenishments.code-status.warning')}</span></div>}
                                {operation.status === null && <div className="table__field info-text"><span>{t('assets.history-replenishments.code-status.wait')}</span></div>}
                            </div>
                        ))}
                        {operationsExmoCode?.map((element, index) => (
                            <div className="table__row" key={`${element}-${index}`}>
                                <div className="table__field"><span>{DateTime.fromISO(element.createdAt).toFormat('D t')}</span></div>
                                <div className="table__field"><span>{index}</span></div>
                                <div className="table__field"><span>{tf(element.amount, mantissa)}</span></div>
                                {element.status === 'redeemed' && <div className="table__field success"><span>{t('assets.history-replenishments.code-status.success')}</span></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(HistoryDeposit);