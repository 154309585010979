import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { rootStore } from '../Application';

const WithdrawalMethod = () => {
    const { t, i18n } = useTranslation();

    const optionsWithdrawal  = [
        { value: 'MaxCode', label: t('assets.withdrawal-options.maxcode') },
        { value: 'ExmoCode', label: t('assets.withdrawal-options.exmocode') },
    ]

    const [selectedOptionWithdrawal, setSelectedOptionWithdrawal] = useState(optionsWithdrawal[0]);

    i18n.on("languageChanged", () => {
        if (selectedOptionWithdrawal.value === "MaxCode")
            setSelectedOptionWithdrawal({ label: t('assets.withdrawal-options.maxcode'), value: 'MaxCode' });
        if (selectedOptionWithdrawal.value === "Exmocode")
            setSelectedOptionWithdrawal({ label: t('assets.withdrawal-options.exmocode'), value: 'ExmoCode' });
    });


    const handleChange = (selectedOption) => {
        rootStore.coinBaze.setSelectWithdrawal(selectedOption.value);
        setSelectedOptionWithdrawal(selectedOption);
    };

    return (
        (<div className="main-form__field">
            <label className="main-form__label" htmlFor="#">{t('assets.deposite-means')}</label>
                <Select
                    options={optionsWithdrawal}
                    key={'withdrawal-means'}
                    classNamePrefix="withdrawal-select"
                    className='withdrawal-select'
                    defaultValue={optionsWithdrawal[0]}
                    onChange={handleChange}
                    value={selectedOptionWithdrawal}
                    unstyled={true}
                />
        </div>)
    );
};

export default observer(WithdrawalMethod);