import React from 'react';
import classNames from "classnames";
import QRCode from "qrcode.react";
import copy from "copy-to-clipboard";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';

import {rootStore} from "../../Application";
import { AuthStore, CoinStore } from "../../stores";

import { pd, processRequestError, tf } from "../../utils/utilities";
import { Api } from "../../utils/api";
import { OperationsType } from "../../utils/graphql";
import { BalanceExchangeType } from '../../utils/graphql';

import HistoryDeposit from "../../components/HistoryDeposit";
import HistoryWithdraw from "../../components/HistoryWithdraw";
import { Button } from "../../components/Button";
import BalanceLine from '../../components/active/BalanceLine';
import InfoCurrencyWallet from '../../components/InfoCurrencyWallet';

interface IAssetsLtcTabProps {
    t: any;
}

interface IAssetsLtcTabState {
    activeTab: 'deposit' | 'withdraw';
    operations?: OperationsType;
    loading: boolean;
    withdrawAmount: string;
    withdrawAddress: string;
    otpCode: string;
    actualBalanceCurrency: number;
}

@observer
class AssetsLtcTab extends React.Component<IAssetsLtcTabProps, IAssetsLtcTabState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(CoinStore)
    declare protected readonly coinStore: CoinStore;
    @resolve(Api)
    declare protected readonly api: Api;

    state: IAssetsLtcTabState = {
        activeTab: 'deposit',
        loading: false,
        withdrawAmount: '',
        withdrawAddress: '',
        otpCode: '',
        actualBalanceCurrency: null
    }

    componentDidMount() {
        this.loadOperations();
    }

    loadOperations = async () => {
        this.setState({ operations: await this.api.getOperations('ltc') });
    }

    onWithdraw = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.api.withdrawLtc(this.state.withdrawAmount, this.state.withdrawAddress, this.state.otpCode);
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success(`${this.props.t('assets.message.bid-created')}`);
            this.setState({ withdrawAmount: '', withdrawAddress: '', otpCode: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onGetBalance = () => {
        this.coinStore.getBalancesAll()
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === 'LTC')
            if (findOne) {
                return findOne.balance
            }
        }
        return 0
    }

    render() {
        const { activeTab, operations, loading, withdrawAddress, withdrawAmount, otpCode, actualBalanceCurrency } = this.state;
        const { t } = this.props;
        const profile = this.authStore.profile;
        const withdrawAmountNumber = parseFloat(withdrawAmount);
        
        return (
            <div className="tabs__content active">
                <InfoCurrencyWallet
                    currency={'LTC'}
                    mantissa={2}
                    currencyToUSD={102.08}
                    currencyToRub={10387.57}
                />
                <div className="deal js-deal-tabs">
                    <ul className="deal__list">
                    <li className={classNames('deal__item', { active: activeTab === 'deposit' })} onClick={() => this.setState({ activeTab: 'deposit' })}>{t('assets.tabs.replenishment')}</li>
                        <li className={classNames('deal__item', { active: activeTab === 'withdraw' })} onClick={() => this.setState({ activeTab: 'withdraw' })}>{t('assets.tabs.withdrawal')}</li>
                    </ul>
                    <div className={classNames('deal__content', { active: activeTab === 'deposit' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='LTC' mantissa={8}/>
                                <div className="program-wrap">
                                    <div className="program">
                                        <div className="program__wrap">
                                            <span className="program__number">1</span>
                                            <div className="program__info">
                                                <p className="base-text">{t('assets.cryptocurrency-deposit-options.first')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="program">
                                        <div className="program__wrap"><span
                                            className="program__number">2</span>
                                            <div className="program__info">
                                                <p className="base-text">{t('assets.cryptocurrency-deposit-options.second')}</p>
                                                <div className="program__qr-wrap">
                                                    <div className="program__qr">
                                                        {profile && <QRCode value={profile?.ltcAddress} size={190} />}
                                                        {/*<img src={require('../../images/qr.jpg')} alt=""/>*/}
                                                    </div>
                                                    <div className="program__link">
                                                        <span className="program__link-title">{t('assets.min-amount-deposit')} 0.0000616 LTC</span>
                                                        <div className="program__link-wrap">
                                                            <p className="program__link-text">{profile?.ltcAddress}</p>
                                                            <button className="program__link-copy" type="button" onClick={() => { copy(profile?.ltcAddress); toast.success(`${t('assets.copy-adderess')}`) }}>
                                                                <svg>
                                                                    <use href="#copy"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <p className="base-text">{t('assets.scan-qr-code')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="program">
                                        <div className="program__wrap">
                                            <span className="program__number">3</span>
                                            <div className="program__info">
                                                <p className="base-text">{t('assets.cryptocurrency-deposit-options.third')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="main-block main-block--withdrawal">
                                <h2 className="operation-title">{t('assets.history-replenishments.title')}</h2>
                                <HistoryDeposit operations={operations?.deposits} mantissa={8} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('deal__content', { active: activeTab === 'withdraw' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='LTC' />
                                <div className="program-wrap">
                                    <div className="program">
                                        <div className="program__wrap">
                                            <div className="program__info">
                                                <p className="base-text">{t('assets.cryptocurrency-withdrawal-options.first')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bye-sell">
                                    <form className="main-form" onSubmit={this.onWithdraw}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('assets.adderess-withdrawal')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={withdrawAddress}
                                                    onChange={e => this.setState({ withdrawAddress: e.target.value })}
                                                    required
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('assets.amount-withdrawal')}</label>
                                                <div className="main-form__input-wrap">
                                                    <input
                                                        className="main-form__input"
                                                        type="number"
                                                        min={0}
                                                        max={this.onGetBalance()}
                                                        step={.00000001}
                                                        value={withdrawAmount}
                                                        onChange={(e) => {
                                                            this.setState({ withdrawAmount: e.target.value });
                                                            this.setState({ actualBalanceCurrency: this.onGetBalance() });
                                                        }}
                                                        required
                                                    />
                                                    <button className="main-form__max" type="button" onClick={() => {
                                                        this.setState({ withdrawAmount: tf(this.onGetBalance(),2) });
                                                        this.setState({ actualBalanceCurrency: this.onGetBalance() });
                                                }}>MAX</button>
                                                </div>
                                            </div>
                                            {profile?.otpEnabled && (
                                                <div className="main-form__field">
                                                    <label className="main-form__label" htmlFor="#">Код 2FA</label>
                                                    <input
                                                        className="main-form__input"
                                                        type="text"
                                                        value={otpCode}
                                                        onChange={e => this.setState({ otpCode: e.target.value })}
                                                        required
                                                    />
                                                </div>
                                            )}
                                            <div className="main-form__footer">
                                                <Button
                                                    kind="secondary"
                                                    type="submit"
                                                    loading={loading}
                                                    disabled={!withdrawAmount || withdrawAmountNumber <= 0 || withdrawAmountNumber > parseFloat(tf(actualBalanceCurrency,2))}
                                                >
                                                    {t('assets.button-withdrawal')}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="main-block main-block--withdrawal">
                                <h2 className="operation-title">{t('assets.history-withdrawal.title')}</h2>
                                <HistoryWithdraw operations={operations?.withdraws} mantissa={8} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(AssetsLtcTab);
