import React from 'react';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { withTranslation } from 'react-i18next';
import TelegramLoginButton from 'react-telegram-login'

import { pd, processRequestError } from "../utils/utilities";
import { Button } from "../components/Button";
import { toast } from "react-toastify";

import { AuthStore, InfoStore, ModalStore } from "../stores";
import { Modals } from "../stores/ModalStore";


import OrderBook from "../components/exchange/OrderBook";

interface IIndexPageProps {
    t: any;
}

interface IIndexPageState {
}

interface TelegramUser {
    id: number
    photo_url: string  
    first_name: string
    last_name: string  
    username: string
    auth_date: number  
    hash: string
  }


@observer
class IndexPage extends React.Component<IIndexPageProps, IIndexPageState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(InfoStore)
    declare protected readonly infoStore: InfoStore;

    state: IIndexPageState = {
    }

    handleTelegramResponse = async (user: TelegramUser) => {    
        try {
            const { id, first_name, username, photo_url, auth_date, hash } = user;
            await this.authStore.tokenAuthTg(id, first_name, username, photo_url, auth_date, hash);
            toast.success('Вход выполнен успешно');
            this.modalStore.hideModals();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }  
    }
    bot_name = 'maxbit_icu_bot';

    render() {
        const { t } = this.props;

        return (
            <main className="main">
            <section className="main-section">
                <div className="container">
                    <div className="main-content">
                        <div className="info-wrap">
                            {/* <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.topText }} /> */}
                            <h1 className="main-title">MaxBit</h1>
                            <div className="purpose">
                                <ul className="purpose__list">
                                    <li>{t('main.list-why.one')}</li>
                                    <li>{t('main.list-why.two')}</li>
                                    <li>{t('main.list-why.three')}</li>
                                    <li>{t('main.list-why.four')}</li>
                                </ul>
                            </div>
                            {!this.authStore.token && (
                                <>
                                    <div className="btn-wrap">
                                        <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>{t('main.btn-sign-up')}</button>
                                        <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>{t('main.btn-sign-in')}</button>
                                    </div>
                                    <div className="btn-wrap">
                                        <span className='or-sign-in-up'>{t('signin.or-sign-in-up')}</span>
                                        <section className="custom-telegram-button">
                                            <TelegramLoginButton dataOnauth={this.handleTelegramResponse} botName='maxbit_icu_bot' usePic={false} />                
                                        </section>
                                    </div>
                                </>
                            )}
                        </div>
                        {/*<div className="main-img"><img src={require('../images/main-img.svg')} alt=""/></div>*/}
                        <div className="main-img"><img src={this.infoStore.indexPage?.topImage} alt=""/></div>
                    </div>
                </div>
            </section>
            <section className="purpose-section">
                <div className="container">
                    <div className="purpose-content">
                        <h2 className="section-title">{t('main.exchange-intended.title')}</h2>
                        <div className="purpose-wrap">
                            <div className="purpose">
                                <div className="purpose__wrap">
                                    {/* <div className="purpose__img"><img src={require('../images/purpose1.svg')} alt=""/></div> */}
                                    <div className="purpose__img"><img src={this.infoStore.indexPage?.bottomImage1} alt=""/></div>
                                    <span className="purpose__title">{t('main.exchange-intended.list.one.title')}</span>
                                    <ul className="purpose__list">
                                        <li>{t('main.exchange-intended.list.one.list.one')}
                                        </li>
                                        <li>{t('main.exchange-intended.list.one.list.two')}</li>
                                        <li>{t('main.exchange-intended.list.one.list.three')}</li>
                                        <li>{t('main.exchange-intended.list.one.list.four')}</li>
                                    </ul>
                                    {/* <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.bottomText1 }} /> */}
                                </div>
                            </div>
                            <div className="purpose">
                                <div className="purpose__wrap">
                                    {/* <div className="purpose__img"><img src={require('../images/purpose2.svg')} alt=""/></div> */}
                                    <div className="purpose__img"><img src={this.infoStore.indexPage?.bottomImage2} alt=""/></div>
                                    <span className="purpose__title">{t('main.exchange-intended.list.two.title')}</span>
                                    <ul className="purpose__list">
                                        <li>{t('main.exchange-intended.list.two.list.one')}
                                        </li>
                                        <li>{t('main.exchange-intended.list.two.list.two')}</li>
                                        <li>{t('main.exchange-intended.list.two.list.three')}</li>
                                    </ul>
                                    {/* <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.bottomText2 }} /> */}
                                </div>
                            </div>
                            <div className="purpose">
                                <div className="purpose__wrap">
                                    {/* <div className="purpose__img"><img src={require('../images/purpose3.svg')} alt=""/></div> */}
                                    <div className="purpose__img"><img src={this.infoStore.indexPage?.bottomImage3} alt=""/></div>
                                    <span className="purpose__title">{t('main.exchange-intended.list.three.title')}</span>
                                    <ul className="purpose__list">
                                        <li>{t('main.exchange-intended.list.three.list.one')}</li>
                                        <li>{t('main.exchange-intended.list.three.list.two')}</li>
                                        <li>{t('main.exchange-intended.list.three.list.three')}</li>
                                        <li>{t('main.exchange-intended.list.three.list.four')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {!this.authStore.token && (
                <section className="register-section">
                    <div className="container">
                        <div className="register-content">
                            <h2 className="section-title">{t('main.promo-sign-up')}</h2>
                            <div className="btn-wrap">
                                <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>{t('main.btn-sign-up')}</button>
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>{t('main.btn-sign-in')}т</button>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </main>
            // <main className="main">
            //     <section className="main-section">
            //         <div className="container">
            //             <div className="main-content">
            //                 <div className="info-wrap">
            //                     {/* <div dangerouslySetInnerHTML={{ __html: this.infoStore.indexPage?.topText }} /> */}
            //                     <h1 className="main-title">Криптовалютная биржа Teletrade</h1>
            //                     {/* <div className="main-title__logo"><img src={require('../images/logott4.png')} alt=""/></div> */}
            //                     <span className="promo-text">Безналичные рубли, Bitcoin, USDT, LTC, XMR, KZT</span>
            //                     <ul className="promo-list">
            //                         <li className="promo-list__element">Пополнение и вывод без комиссии</li>
            //                         <li className="promo-list__element">Вывод на карты: Сбербанка, Т-Банка, Альфа, МТС-банка, ВТБ</li>
            //                         <li className="promo-list__element">Работаем по всей России</li>
            //                         <li className="promo-list__element">Биржа работает круглосуточно в полном объеме</li>
            //                         <li className="promo-list__element">Различные способы пополнения/вывода баланса через P2P-платформу биржы</li>
            //                     </ul>
                                
            //                     {!this.authStore.token && (
            //                         <div className="btn-wrap">
            //                             <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Зарегистрироваться</button>
            //                             <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>Войти в аккаунт</button>
            //                         </div>
            //                     )}
            //                 </div>
            //                 {/*<div className="main-img"><img src={require('../images/main-img.svg')} alt=""/></div>*/}
            //                 <div className="main-img"><img src={this.infoStore.indexPage?.topImage} alt=""/></div>
            //             </div>
            //         </div>
            //     </section>
            //     <section className="work-now">
            //         <div className="container">
            //             <h2 className='work-now__title'>
            //                 Начните за 30 секунд!
            //             </h2>
            //             <ul className="work-now__list list-create">
            //                 <li className='list-create__element'>
            //                     <span className='list-create__element-number'>1</span>
            //                     <img/>
            //                     <h3 className='list-create__element-title'>Создайте аккаунт</h3>
            //                     <p className='list-create__element-description'>Укажите свой адрес электронной почты и пароль - и вы зарегистрированы</p>
            //                     <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Зарегистрироваться</button>
            //                 </li>
            //                 <li className='list-create__element'>
            //                     <span className='list-create__element-number'>2</span>
            //                     <img/>
            //                     <h3 className='list-create__element-title'>Внесите депозит</h3>
            //                     <p className='list-create__element-description'>Пополнить баланс благодаря P2P-платформы биржы или через коды MaxCode и ExmoCode</p>
            //                 </li>
            //                 <li className='list-create__element'>
            //                     <span className='list-create__element-number'>3</span>
            //                     <img/>
            //                     <h3 className='list-create__element-title'>Начните торговать</h3>
            //                     <p className='list-create__element-description'>Заходите в вкладку биржы, анализириуйте графики, читайте стаканы и ещё многое другое!</p>
            //                 </li>
            //             </ul>
            //         </div>
            //     </section>
            //     {/* <section>
            //         <h2>Почему Teletrade?</h2>
            //         <ul>
            //             <li>
            //                 <img/>
            //                 <h3>Поддержка 24\7</h3>
            //                 <p>Круглосуточная поддержка в режиме реального времени готова помочь вам</p>
            //             </li>
            //             <li>
            //                 <img/>
            //                 <h3>Реферальная программа</h3>
            //                 <p>Приглашайте своих друзей и коллег в Teletrade и получайте рубли на свой счёт</p>
            //             </li>
            //             <li>
            //                 <img/>
            //                 <h3>Быстрые депозиты и выводы</h3>
            //                 <p>Быстро и легко переводите средства на свои счета и обратно</p>
            //             </li>
            //             <li>
            //                 <img/>
            //                 <h3>Низкие комиссии</h3>
            //                 <p>Для всех видов сделок, для вывода/пополнения средств на нашей бирже везде низкие комиссии</p>
            //             </li>
            //             <li>
            //                 <img/>
            //                 <h3>Работаем по России и по всему миру</h3>
            //                 <p>Поддерживаем и обрабатываем платежи по всему миру</p>
            //             </li>
            //             <li>
            //                 <img/>
            //                 <h3>P2P-платформа</h3>
            //                 <p>Пополняйте счёт любым удобным способом на P2P-платформе по выгодным курсам.</p>
            //             </li>
            //         </ul>
            //     </section> */}
            //     {!this.authStore.token && (
            //         <section className="register-section">
            //             <div className="container">
            //                 <div className="register-content">
            //                     <h2 className="section-title">Окунитесь в мир трейдинга прямо сейчас:</h2>
            //                     <div className="btn-wrap">
            //                         <button className="btn primary" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>Зарегистрироваться</button>
            //                         <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>Войти в аккаунт</button>
            //                     </div>
            //                 </div>
            //             </div>
            //         </section>
            //     )}
            // </main>
        )
    }
}

export default withTranslation()(IndexPage);
