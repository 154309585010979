import React from 'react';
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { fromEvent } from "file-selector";
import { FileWithPath } from "file-selector/src/file";
import { resolve } from "inversify-react";
import { RouteComponentProps } from "react-router";
import { deserialize } from "typescript-json-serializer";
import { DateTime } from "luxon";
import { withTranslation } from 'react-i18next';

import { AuthStore } from "../../stores";

import { ChatMessage } from "../../utils/types";
import { API_HOST, API_PROTO } from "../../utils/const";
import { pd } from "../../utils/utilities";


interface RouteParams {
    cid?: string;
}

interface IOTCChatsTabProps extends RouteComponentProps<RouteParams> {
}

interface IOTCChatsTabState {
    chats: ChatMessage[];
    activeChat?: ChatMessage;
    messages: ChatMessage[];
    messageInput: string;
    language: 'ru' | 'en';
}

class OTCChatsTab extends React.Component<IOTCChatsTabProps, IOTCChatsTabState> {
    private messagesScroll: HTMLDivElement;
    private fileInput: HTMLInputElement;

    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IOTCChatsTabState = {
        chats: [],
        messages: [],
        messageInput: '',
        language: 'ru',
    }

    componentDidMount() {
        this.authStore.websocket.addEventListener('message', this.onMessage);
        if (this.authStore.websocket.readyState == WebSocket.CONNECTING)
            this.authStore.websocket.addEventListener('open', this.getChats);
        else
            this.getChats();
    }

    componentWillUnmount() {
        this.authStore.websocket.removeEventListener('message', this.onMessage);
        this.authStore.websocket.removeEventListener('open', this.getChats);
    }

    getChats = () => {
        this.authStore.websocket.send(JSON.stringify({ action: 'get_chats' }));
    }

    onMessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data);

        if (data.action === 'chats_list') {
            this.setState({ chats: data.chats.map(c => deserialize(c, ChatMessage)) });
        } else if (data.action === 'history') {
            const messages: ChatMessage[] = data.messages.map(m => deserialize(m, ChatMessage));
            this.addMessages(messages)
        } else if (data.action === 'new_message') {
            const message: ChatMessage = deserialize(data.message, ChatMessage);
            this.addMessages([message]);
        }
    }

    addMessages = (messages: ChatMessage[]) => {
        messages = messages.filter(m => m.other_user_id === this.state.activeChat.other_user_id);
        messages = messages.concat(this.state.messages);
        const messageIds = {}
        messages.forEach(m => messageIds[m.pk] = m);
        messages = Object.values(messageIds);
        messages.sort((a, b) => a.pk < b.pk ? -1 : 1);
        const needScroll = this.messagesScroll.scrollHeight - this.messagesScroll.scrollTop === this.messagesScroll.clientHeight;
        this.setState({ messages }, () => needScroll && this.messagesScroll.scrollTo({ top: 99999999 }));
    }

    toggleChat = (activeChat: ChatMessage) => {
        this.setState({ activeChat, messages: [] });
        this.authStore.websocket.send(JSON.stringify({
            action: 'get_messages',
            type: 'user',
            object_id: activeChat.other_user_id,
        }));
    }

    onSendMessage = (e: React.FormEvent) => {
        e && pd(e);
        this.authStore.websocket.send(JSON.stringify({
            action: 'send_message',
            type: 'user',
            object_id: this.state.activeChat.other_user_id,
            text: this.state.messageInput,
        }))
        this.setState({ messageInput: '' });
    }

    attachFile = async (e) => {
        const files = await fromEvent(e);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.authStore.websocket.send(JSON.stringify({
                action: 'send_message',
                type: 'user',
                object_id: this.state.activeChat.other_user_id,
                text: this.state.messageInput,
                attachment: reader.result,
            }))
            this.setState({ messageInput: '' });
        });
        reader.readAsDataURL(files[0] as FileWithPath);
    }

    render() {
        const { t, i18n } = this.props;
        const { chats, activeChat, messages, messageInput, language } = this.state;

        let prevDay = null;
        let prevDayFlag = true;
        let consecutiveMessages = false;
        let prevIncomig = null;
        

        i18n.on("languageChanged", () => {
            this.setState({language: i18n.language});
        });

        return (
            <div className="tabs__content tabs__content--chat-otc active">
                <div className="chat-wrap">
                    <div className="chat">
                        <div className="chat__list-wrap">
                            <h2 className="operation-title small">{t('otc.chat.list')}</h2>
                            <ul className="chat__list">
                                {chats.map(c => (
                                    <li
                                        className={classNames('chat__item', { active: activeChat?.other_user_id === c.other_user_id })}
                                        onClick={() => this.toggleChat(c)}
                                        key={c.other_user_id}
                                    >
                                        {/*<div className="chat__avatar"><img src={require('../../images/avatar1.jpg')} alt=""/>*/}
                                        {/*</div>*/}
                                        <div className="chat__info">
                                            <div className="chat__info-line">
                                                <span className="chat__text chat__text--name">{c.other_user_name}</span>
                                                <span className="chat__text">
                                                    {c.datetime.toLocaleString((DateTime.TIME_24_SIMPLE))}
                                                {/* {(DateTime.fromISO(c.datetime).toFormat('D') === DateTime.now().toFormat('D')) && (DateTime.fromISO(c.datetime).toFormat('t'))}
                                                {(DateTime.fromISO(c.datetime).toFormat('D') !== DateTime.now().toFormat('D')) && (DateTime.fromISO(c.datetime).toFormat('D t'))} */}
                                                </span>
                                            </div>
                                            <div className="chat__info-line">
                                                <p className="chat__base-text chat__text--last-message">{c.text}</p>
                                                {c.complete_deals > 0 && <span className="chat__count">{c.complete_deals}</span>}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {activeChat && (
                            <div className="chat__content active">
                                <h2 className="operation-title">{activeChat.other_user_name}</h2>
                                <div className="chat__message-content scroll-wrap" ref={ref => this.messagesScroll = ref}>
                                    {messages.map((m) => {
                                        prevDay === null ? (prevDay = m.datetime.toLocaleString(DateTime.DATE_FULL)) : ((prevDay === m.datetime.toLocaleString(DateTime.DATE_FULL)) ? prevDayFlag = false : (prevDayFlag = true))
                                        prevDayFlag === true ? (prevDay = m.datetime.toLocaleString(DateTime.DATE_FULL)) : null
                                        if (m.incoming === prevIncomig) {
                                            consecutiveMessages = true;
                                        } else {
                                            consecutiveMessages = false;
                                            prevIncomig = m.incoming;
                                        }
                                        if (prevIncomig === null) {
                                            prevIncomig = m.incoming;
                                        }
                                        if (prevDayFlag) {
                                            consecutiveMessages = false;
                                        }
                                        
                                        return (<React.Fragment key={m.pk}>
                                            {
                                                prevDayFlag &&
                                                <span
                                                    className='chat__text chat__text--day-time'>
                                                    {m.datetime.setLocale(language).toLocaleString((DateTime.DATE_FULL))} 
                                                </span>
                                            }
                                            <div className={classNames('chat__message', {my: !m.incoming}, {'chat__message--consecutive': consecutiveMessages}, {'chat__message--file': m.attachment}, {'chat__message--image': (m.attachment === null ? false : (m.attachment).includes('.png') || (m.attachment).includes('.webp') || (m.attachment).includes('.jpg') || (m.attachment).includes('.jpeg') || (m.attachment).includes('.gif'))})}>
                                            <div className="chat__info-line">
                                                <span className='chat__text'>{m.incoming ? activeChat.other_user_name : this.authStore.profile.name}</span>
                                            </div>
                                            <div className="chat__message-bg">
                                                <p className={classNames('chat__base-text', {service: m.service})}>{m.text}</p>
                                                {m.attachment && !((m.attachment).includes('.png') || (m.attachment).includes('.webp') || (m.attachment).includes('.jpg') || (m.attachment).includes('.jpeg') || (m.attachment).includes('.gif')) && (
                                                    <p className='chat__base-text chat__base-text-file'>
                                                        <a href={`${API_PROTO}://${API_HOST}${m.attachment}`} target='_blank'>[Файл]</a>
                                                    </p>
                                                )}
                                                {m.attachment && ((m.attachment).includes('.png') || (m.attachment).includes('.webp') || (m.attachment).includes('.jpg') || (m.attachment).includes('.jpeg') || (m.attachment).includes('.gif')) && (
                                                    <div className='chat__base-text chat__base-text-file chat__base-file-img'>
                                                        <a href={`${API_PROTO}://${API_HOST}${m.attachment}`} target='_blank'>
                                                            <img src={`${API_PROTO}://${API_HOST}${m.attachment}`}/>
                                                        </a>
                                                    </div>
                                                )}
                                                <span
                                                    className='chat__text chat__text--time'>
                                                        {m.datetime.toLocaleString((DateTime.TIME_24_SIMPLE))} 
                                                </span>
                                            </div>
                                        </div>                                       
                                        </React.Fragment>)
                                    })}
                                </div>
                                <form className="main-form" onSubmit={this.onSendMessage}>
                                    <input type='file' ref={ref => this.fileInput = ref} onChange={this.attachFile}
                                           style={{display: 'none'}}/>
                                    <div className="main-form__wrap">
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#"/>
                                            <TextareaAutosize
                                                minRows={1}
                                                // maxRows={5}
                                                className="main-form__textarea main-from__textarea--chat"
                                                name="#"
                                                placeholder={`${t('otc.chat.send-to-message')}`}
                                                value={messageInput}
                                                onKeyDown={e => e.key === 'Enter' && !e.shiftKey && this.onSendMessage(e)}
                                                onChange={e => this.setState({messageInput: e.target.value})}
                                            />
                                            <button className="btn send" type="submit">
                                                <svg>
                                                    <use href="#send"/>
                                                </svg>
                                            </button>
                                            <button className="main-form__file" type="button"
                                                    onClick={() => this.fileInput.click()}>
                                                <svg>
                                                    <use href="#file"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OTCChatsTab);
