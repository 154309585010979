import { AxiosError } from "axios";
import { toast } from "react-toastify";
import numbro from "numbro";
import { BigNumber } from 'ethers';
import BN from 'bignumber.js';
import React from "react";
import { ClientError } from "graphql-request";
import { ERROR_MESSAGES } from "./const";

numbro.registerLanguage({
    ...numbro.languageData('en-US'),
    languageTag: 'cs',
    delimiters: {
        thousands: ' ',
        decimal: '.',
    },
    defaults: { thousandSeparated: true },
}, true);

function getErrorMessage(text: string) {
    console.log("Name error: ",text)
    return ERROR_MESSAGES[text] || text;
}


export function processRequestError(e: ClientError) {
    console.log(e.message);
    if (!e.message) {
        toast.error('An error has occurred while performing a request. Please check your internet connection and try later.')
        return;
    }
    // toast.error(e.message)
    if (e.response.errors) {
        e.response.errors.forEach(err => toast.error(getErrorMessage(err.message)));
    }
}

export function fsp(x, trimMantissa = true, mantissa = 2) {
    if (typeof x === 'undefined') return '...';
    x = parseFloat(x) || 0;
    return numbro(x).format({ trimMantissa, mantissa });
}

export function toBNJS(val: BigNumber | number | string) {
    return new BN(val.toString());
}

export function tf(val: BigNumber | number | string, mantissa = 2) {
    if (!val)
        val = '0';
    return toBNJS(val).toFixed(mantissa, 0);
}
export function tf2(val: BigNumber | number | string, mantissa = 2) {
    if (!val)
        val = '0';
    return toBNJS(val).toFixed(mantissa, 1);
}

export function  normalizeVolume (value: number, step: number, profitable = false) {
    const mul = Math.ceil(1 / step);
    return profitable ? +(Math.floor(value * mul) / mul) : +(Math.round(value * mul) / mul);
}

export function PrecisionCount(x){
    return  (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0)
}


export function trimAddress(address: string) {
    const len = address.length;
    return address.slice(0, 6) + '*****' + address.slice(len - 4, len);
}

export function pd(e: React.SyntheticEvent, ...rest: any[]) {
    e.preventDefault();
}

export function divideNumberByPieces(x, delimiter = " ") {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
    return parts.join(".");
  }
