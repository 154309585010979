import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { withTranslation } from 'react-i18next';

import { AuthStore, ModalStore } from "../stores";
import { Modals } from "../stores/ModalStore";

import SelectLanguage from "./SelectLanguage";
import classNames from 'classnames';

interface IHeaderProps {
    t: any;
}

interface IHeaderState {
    mobileNavOpen: boolean;
}

@observer
class Header extends React.Component<IHeaderProps, IHeaderState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;

    state: IHeaderState = {
        mobileNavOpen: false,
    }

    render() {
        const { mobileNavOpen } = this.state;
        const { t } = this.props;

        return (
            <header className="header">
                <div className="container">
                    <Link className="logo" to="/">
                        {/* <div className="bg-logo"><img src={require('../images/logott4.png')} alt=""/></div> */}
                        <div className="bg-logo"><img src={require('../images/Logo_1200.gif')} alt="Лого"/></div>
                    </Link>
                    <ul className={`nav-list ${mobileNavOpen && 'active'}`}>
                        <li className="nav-list__element"><NavLink to="/exchange">{t('header.exchange')}</NavLink></li>
                        <li className="nav-list__element"><NavLink to="/assets">{t('header.assets')}</NavLink></li>
                        <li className="nav-list__element"><NavLink to="/otc">P2P</NavLink></li>
                        {/* {this.authStore.token && (
                            <>
                                <li><NavLink to="/program">Партнер. программа</NavLink></li>
                             </>
                        )} */}
                    </ul>
                    <nav className={`nav ${mobileNavOpen && 'active'}`}>
                        {this.authStore.token ? (
                            <React.Fragment>
                                <div className="user-nav">
                                    <Link className="profile" to='/profile'>
                                        {/* {t('header.profile')} */}
                                        <img width="25" height="25" src={require("../images/avatar-icon.svg")}/>
                                    </Link>
                                </div>
                                <SelectLanguage/>
                            </React.Fragment>
                        ) : (
                            <div className="user-nav">
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>{t('header.sigin')}</button>
                                <span>/</span>
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>{t('header.register')}</button>
                            </div>
                        )}
                    </nav>
                    <div className={classNames(
                        'wrapper-nav-mobile',
                        {'wrapper-nav-mobile--active': mobileNavOpen}
                    )
                    }>
                        <ul className={`nav-list ${mobileNavOpen && 'active'}`}>
                            <li className="nav-list__element"><NavLink to="/exchange" onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}>{t('header.exchange')}</NavLink></li>
                            <li className="nav-list__element"><NavLink to="/assets" onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}>{t('header.assets')}</NavLink></li>
                            <li className="nav-list__element"><NavLink to="/otc" onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}>P2P</NavLink></li>
                            {/* {this.authStore.token && (
                                <>
                                    <li><NavLink to="/program">Партнер. программа</NavLink></li>
                                </>
                            )} */}
                        </ul>
                        <nav className={`nav ${mobileNavOpen && 'active'}`}>
                        {this.authStore.token ? (
                            <React.Fragment>
                                <div className="user-nav">
                                    <Link className="profile" to='/profile'  onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}>
                                        {/* {t('header.profile')} */}
                                        <img width="25" height="25" src={require("../images/avatar-icon.svg")}/>
                                    </Link>
                                </div>
                                <SelectLanguage
                                />
                            </React.Fragment>
                        ) : (
                            <div className="user-nav">
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>{t('header.sigin')}</button>
                                <span>/</span>
                                <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>{t('header.register')}</button>
                            </div>
                        )}
                    </nav>
                    </div>
                    <div className="burger" onClick={() => this.setState({ mobileNavOpen: !mobileNavOpen })}><span/><span/><span/></div>
                </div>
            </header>
        )
    }
}

export default withTranslation()(Header);
