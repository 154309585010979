import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

import { rootStore } from '../Application';

const DepositMethod = () => {
    const { t, i18n } = useTranslation();

    const optionsDeposit  = [
        { value: 'MaxCode', label: t('assets.deposit-options.maxcode') },
        { value: 'ExmoCode', label: t('assets.deposit-options.exmocode') },
    ]

    const [selectedOptionDeposit, setSelectedOptionDeposit] = useState(optionsDeposit[0]);

    i18n.on("languageChanged", () => {
        if (selectedOptionDeposit.value === "MaxCode")
        setSelectedOptionDeposit({ label: t('assets.deposit-options.maxcode'), value: 'MaxCode' });
        if (selectedOptionDeposit.value === "Exmocode")
            setSelectedOptionDeposit({ label: t('assets.deposit-options.exmocode'), value: 'ExmoCode' });
    });


    const handleChange = (selectedOption) => {
        rootStore.coinBaze.setSelectDeposite(selectedOption.value);
        setSelectedOptionDeposit(selectedOption);
    };
    
    return (
        (<div className="main-form__field">
            <label className="main-form__label" htmlFor="#">{t('assets.deposite-means')}</label>
                <Select
                    options={optionsDeposit}
                    key={'deposit-means'}
                    classNamePrefix="deposit-select"
                    className='deposit-select'
                    defaultValue={optionsDeposit[0]}
                    onChange={handleChange}
                    value={selectedOptionDeposit}
                    unstyled={true}
                />
        </div>)
    );
};

export default observer(DepositMethod);