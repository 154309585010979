import React from 'react';
import _ from "lodash";
import classNames from "classnames";
import {observer} from "mobx-react";
import {resolve} from "inversify-react";
import { withTranslation } from 'react-i18next';

import {AuthStore, CoinStore} from "../stores";

import {tf, normalizeVolume, PrecisionCount} from "../utils/utilities";
import {BalanceExchangeType, CoinType} from "../utils/graphql";

import HistoryOrders from "../components/exchange/HistoryOrders";
import OrderBook from "../components/exchange/OrderBook";
import FormBuy from "../components/exchange/FormBuy";
import FormSell from "../components/exchange/FormSell";

interface IExchangePageProps {
    t: any;
}

interface IExchangePageState {
    pair: string;
    priceType: 'market' | 'limit' | 'moex';
    baseSymbols: string[];
    coins: string[];
    currentBaseSymbol: string;
    currentCoin?: CoinType;
    balanses?: BalanceExchangeType[];
    inputBuy: number;
    inputTurnoverBuy: number;
    currentRate: number;
}

@observer
class ExchangePage extends React.Component<IExchangePageProps, IExchangePageState> {
    state: IExchangePageState = {
        pair: '',
        priceType: 'market',
        baseSymbols: [],
        balanses: [],
        coins: [],
        currentBaseSymbol: "",
        inputBuy: 0.0001,
        currentRate: 0.1,
        inputTurnoverBuy: 0.0001
    }

    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    @resolve(CoinStore)
    declare protected readonly coinStore: CoinStore;


    async componentDidMount() {
        try {
            this.setState({baseSymbols: await this.coinStore.getBaseSymbols()});
            this.setState({currentBaseSymbol: this.state.baseSymbols[0]});
                 
            await this.coinStore.getCoins(this.state.currentBaseSymbol)
            this.setState({coins: this.coinStore.coins})
            this.setState({pair: this.coinStore.coins[1]})

            await this.onGetCoin()
        } finally {

        }
    }

    onGetCoin = async () => {
        await this.coinStore.getCoin(this.state.pair)
        await this.coinStore.getBalancesAll()

        this.setState({balanses: this.coinStore.balances})
        this.setState({currentCoin: this.coinStore.coin})

        this.setState({currentRate: Number(tf(this.coinStore.coin.rubRate, this.coinStore.coin.baseCoinScale))})
        this.setState({inputTurnoverBuy: Number(tf(this.state.currentRate * this.state.inputBuy, this.coinStore.coin.baseCoinScale))})
    }

       onGetBalance(symbol:string):BalanceExchangeType {
        let findOne:BalanceExchangeType =  this.state.balanses.find((one:BalanceExchangeType)=>one.symbol==symbol)
        if (findOne) {
            return findOne
        }
    }

    render() {
        const {pair, priceType, coins, currentCoin, currentRate, inputBuy, inputTurnoverBuy} = this.state;

        const fff = ['BTCUSDT', 'BTCRUB', 'LTCUSDT', 'XMRUSDT', 'USDTRUB'];

        const { t } = this.props; 

        // @ts-ignore
        // @ts-ignore
        return (
            <main className="main">
                <section className="exchange-section">
                    <div className="container">
                        <div className="tabs js-tabs">
                            <ul className="tabs__list js-tab-list">
                                {fff?.map((one) => (
                                    <li key={one} className={classNames('tabs__item', {active: pair === one})}
                                        onClick={async () => {
                                            await this.setState({pair: one});
                                            await this.onGetCoin()
                                        }}>{one}</li>

                                ))}

                            </ul>
                            <div className="tabs__content js-tab-content active">
                                <div className="deal js-deal-tabs">
                                    <ul className="deal__list js-deal-list">
                                    <li className={classNames('deal__item', {active: priceType === 'market'})}
                                            onClick={() => this.setState({priceType: 'market'})}>{t('exchange.by-market')}
                                        </li>
                                        <li className={classNames('deal__item', {active: priceType === 'limit'})}
                                            onClick={() => this.setState({priceType: 'limit'})}>{t('exchange.limit')}
                                        </li>
                                        
                                    </ul>
                                    <div className={classNames('deal__content', {active: currentCoin != null})}>
                                        <div className="main-content main-content--exchange">
                                            {currentCoin?.symbol==pair && <FormBuy currentCoin={currentCoin} type={priceType}/>}
                                            {currentCoin?.symbol==pair && <FormSell currentCoin={currentCoin} type={priceType}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {currentCoin?.symbol==pair && <OrderBook symbol={currentCoin?.symbol} baseSymbol={currentCoin?.baseSymbol}
                                                   coinSymbol={currentCoin?.coinSymbol}/>}
                    </div>
                </section>
                <section className="last-section">
                    {currentCoin?.symbol==pair  && <HistoryOrders symbol={currentCoin?.symbol} baseSymbol={currentCoin?.baseSymbol}
                                                   coinSymbol={currentCoin?.coinSymbol}/>}
                </section>
            </main>
        )
    }
}

export default withTranslation()(ExchangePage);
