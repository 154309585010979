import React from 'react';
import classNames from "classnames";
import TextareaAutosize from 'react-textarea-autosize';
import InputMask from "react-input-mask";
import {RouteComponentProps} from "react-router";
import {resolve} from "inversify-react";
import {deserialize} from "typescript-json-serializer";
import {DateTime} from "luxon";
import {fromEvent} from "file-selector";
import {FileWithPath} from "file-selector/src/file";
import { withTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { API_HOST, API_PROTO } from "../../utils/const";
import {OtcDeal, AppOtcDealStatusChoices, AppOtcAdPriceExtraPercentDirectionChoices} from "../../utils/graphql";
import {Api} from "../../utils/api";
import {ChatMessage} from "../../utils/types";
import {pd, processRequestError, tf} from "../../utils/utilities";

import {AuthStore} from "../../stores";

import {Button} from "../../components/Button";

interface RouteParams {
    did: string;
}

interface IOTCDealProps extends RouteComponentProps<RouteParams> {
    t: any;
    i18n: any;
}

interface IOTCDealState {
    deal?: OtcDeal;
    messages: ChatMessage[];
    messageInput: string,
    baseSum: string;
    actualSum: string;
    loading: boolean;
    bankCardNumber: string;
    paymentMethod: string;
    language: 'ru' | 'en';
    isActiveArbitrage: boolean;
}

class OTCDeal extends React.Component<IOTCDealProps, IOTCDealState> {
    private messagesScroll: HTMLDivElement;
    private fileInput: HTMLInputElement;

    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IOTCDealState = {
        messages: [],
        messageInput: '',
        baseSum: '',
        actualSum: '',
        loading: false,
        bankCardNumber: '',
        paymentMethod: '',
        language: 'ru',
        isActiveArbitrage: true,
    };

    componentDidMount() {
        this.loadDeal();
        this.authStore.websocket.addEventListener('message', this.onMessage);
        if (this.authStore.websocket.readyState == WebSocket.CONNECTING)
            this.authStore.websocket.addEventListener('open', this.getMessages);
        else
            this.getMessages();
    }

    getMessages = () => {
        this.authStore.websocket.send(JSON.stringify({
            action: 'get_messages',
            type: 'deal',
            object_id: this.props.match.params.did
        }));
    }

    componentWillUnmount() {
        this.authStore.websocket.removeEventListener('message', this.onMessage);
        this.authStore.websocket.removeEventListener('open', this.getMessages);
    }

    addMessages = async (messages: ChatMessage[]) => {
        const needRefresh = messages.some(m => m.service);
        messages = messages.filter(m => m.deal_id.toString() === this.props.match.params.did);
        messages = messages.concat(this.state.messages);
        const messageIds = {}
        messages.forEach(m => messageIds[m.pk] = m);
        messages = Object.values(messageIds);
        messages.sort((a, b) => a.pk < b.pk ? -1 : 1);
        const needScroll = this.messagesScroll.scrollHeight - this.messagesScroll.scrollTop === this.messagesScroll.clientHeight;
        this.setState({messages}, () => needScroll && this.messagesScroll.scrollTo({top: 99999999}));
        if (needRefresh)
            await this.loadDeal();
    }

    onMessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data);

        if (data.action === 'history') {
            const messages: ChatMessage[] = data.messages.map(m => deserialize(m, ChatMessage));
            this.addMessages(messages)
        } else if (data.action === 'new_message') {
            const message: ChatMessage = deserialize(data.message, ChatMessage);
            this.addMessages([message]);
        }
    }

    onSendMessage = (e: React.FormEvent) => {
        e && pd(e);
        if (this.state.messageInput !== '') {
            this.authStore.websocket.send(JSON.stringify({
                action: 'send_message',
                type: 'deal',
                object_id: this.props.match.params.did,
                text: this.state.messageInput,
            }))
        }
        this.setState({messageInput: ''});
    }

    attachFile = async (e) => {
        const files = await fromEvent(e);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.authStore.websocket.send(JSON.stringify({
                action: 'send_message',
                type: 'deal',
                object_id: this.props.match.params.did,
                text: this.state.messageInput,
                attachment: reader.result,
            }))
            this.setState({messageInput: ''});
        });
        reader.readAsDataURL(files[0] as FileWithPath);
    }

    onOffer = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            // if (this.state.deal?.ad?.action === 'SELL') {
                await this.api.otcDealOffer(this.props.match.params.did, this.state.baseSum, this.state.bankCardNumber, this.state.paymentMethod);
            // } else if (this.state.deal?.ad?.action === 'BUY') {
            //     await this.api.otcDealOffer(this.props.match.params.did, this.state.actualSum, this.state.bankCardNumber, this.state.paymentMethod);
            // }
            
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onAccept = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealAccept(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onDecline = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealDecline(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onPaid = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealPaid(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onComplete = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealComplete(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onArbitration = async (e: React.FormEvent) => {
        pd(e);
        await this.api.otcDealArbitrage(this.props.match.params.did);
        this.setState({isActiveArbitrage: false});
    }

    onCancel = async (e: React.FormEvent) => {
        pd(e);
        await this.api.otcDealCancel(this.props.match.params.did);
    }

    loadDeal = async () => {
        this.setState({deal: await this.api.getDeal(this.props.match.params.did)});
        this.setState({isActiveArbitrage: !(this.state.deal.status === AppOtcDealStatusChoices.Arbitrage)});
    }

    render() {
        const STEP_AMOUNT = 0.01;

        const { t, i18n } = this.props;
        const {deal, messages, messageInput, baseSum, actualSum, bankCardNumber, paymentMethod, language, isActiveArbitrage} = this.state;
        const ad = deal?.ad;

        const otherUserName = deal?.user.id === this.authStore.profile?.id ? ad?.user.name : deal?.user.name;
        const isDealAuthor = deal?.user.id === this.authStore.profile?.id;
        const isSeller = deal?.ad.action === 'SELL' && !isDealAuthor || deal?.ad.action === 'BUY' && isDealAuthor;

        let prevDay = null;
        let prevDayFlag = true;
        let consecutiveMessages = false;
        let prevIncomig = null;

        i18n.on("languageChanged", () => {
            this.setState({language: i18n.language});
        });

        const amountWithProcents = (amount: string, percent: string, sign: '+' | '-'): number => {
            const multiplier = sign === '+' ? 1 : -1;
            return parseFloat(amount) * (1 + multiplier * (parseFloat(percent) / 100));
        };

        return (
            <div className="tabs__content active">
                <div className="main-content">
                    <div className="main-block my-deal-ad">
                        <h2 className="operation-title">{t('otc.my-deal-ad.title')}</h2>
                        <div className="operation">
                            <div className="operation__wrap">
                                <div className="operation__line">
                                    <div className="operation__block">
                                        <span
                                            className="operation__title">{ad?.action === 'SELL' ? `${t('otc.my-deal-ad.seller')}` : `${t('otc.my-deal-ad.buyer')}`}</span>
                                        <span className="operation__value">{ad?.user.name}</span>
                                    </div>
                                    <div className="operation__block">
                                        <span className="operation__title">{t('otc.my-deal-ad.payment-method')}</span>
                                        <span className="operation__value">{ad?.paymentMethod}</span>
                                    </div>
                                    <div className="operation__block">
                                        <span className="operation__title">{t('otc.my-deal-ad.commission')}</span>
                                        {ad?.priceExtraPercent !== "0.00" ?
                                            <>
                                                <span
                                                    className={`operation__value ${ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'commission-customer' : ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'commission-creator' : null}`}
                                                    data-tooltip-id="commission-direction-tooltip"
                                                    data-tooltip-content={`${ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'Вы доплачиваете' : ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'Доплата вам' : null }`}
                                                >{
                                                    ad?.action === 'BUY'
                                                    ? ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? '-' : null
                                                    : ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? '-' : null
                                                }{parseFloat(ad?.priceExtraPercent) % 1000}%</span>
                                                <Tooltip className="commission-tooltip" id="commission-direction-tooltip" />
                                            </> : 
                                            <span className="operation__value">0.0%</span>
                                        }
                                    </div>
                                    <div className="operation__block">
                                        <span className="operation__title">{t('otc.my-deal-ad.amount')}</span>
                                        <span
                                            className="operation__value">{ad?.minSum} - {ad?.maxSum} {ad?.currency}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bye-sell">
                            {deal?.state === 'CREATED' && deal?.ad.action === 'SELL' &&  (
                                isDealAuthor ? (
                                    <form className="main-form" onSubmit={this.onOffer}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.status.buy.get')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="number"
                                                    min={(ad?.minSum)}
                                                    max={(ad?.maxSum)}
                                                    step={STEP_AMOUNT}
                                                    value={baseSum}
                                                    required={true}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({ baseSum: e.target.value });
                                                        const list = {
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: () => {
                                                                this.setState({
                                                                    actualSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '-')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: () => {
                                                                this.setState({
                                                                    actualSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '+')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                        }
                                                        list[ad?.priceExtraPercentDirection]();
                                                    }}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.status.buy.get-commission')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="number"
                                                    required={true}
                                                    min={
                                                        ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer 
                                                            ? (amountWithProcents(ad?.minSum, ad?.priceExtraPercent, '+')).toFixed(2)
                                                            : (amountWithProcents(ad?.minSum, ad?.priceExtraPercent, '-')).toFixed(2)
                                                    }
                                                    max={
                                                        ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer 
                                                            ? (amountWithProcents(ad?.maxSum, ad?.priceExtraPercent, '+')).toFixed(2)
                                                            : (amountWithProcents(ad?.maxSum, ad?.priceExtraPercent, '-')).toFixed(2)
                                                    }
                                                    step={STEP_AMOUNT}
                                                    value={actualSum}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({ actualSum: e.target.value })
                                                        const list = {
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: () => {
                                                                this.setState({
                                                                    baseSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '+')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: () => {
                                                                this.setState({
                                                                    baseSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '-')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                        }
                                                        list[ad?.priceExtraPercentDirection]();
                                                    }}
                                                />
                                            </div>
                                            <Button kind="primary"
                                                    type="submit">{t('otc.my-deal-ad.btn-buy')}</Button>
                                        </div>
                                    </form>
                                ) : (
                                    <div className='status-text'>{t('otc.my-deal-ad.wait-offer')}</div>
                                )
                            )}
                            {deal?.state === 'CREATED' && deal?.ad.action === 'BUY' && (
                                isDealAuthor ? (
                                    <form className="main-form" onSubmit={this.onOffer}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="buy-get">{t('otc.my-deal-ad.status.buy.get')}</label>
                                                <InputMask
                                                    className="main-form__input"
                                                    id="buy-get"
                                                    type="number"
                                                    min={ad?.minSum}
                                                    max={ad?.maxSum}
                                                    step={STEP_AMOUNT}
                                                    value={baseSum}
                                                    requierd={true}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({ baseSum: e.target.value });
                                                        const list = {
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: () => {
                                                                this.setState({
                                                                    actualSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '+')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: () => {
                                                                this.setState({
                                                                    actualSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '-')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                        }
                                                        list[ad?.priceExtraPercentDirection]();
                                                    }}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="get-commission">{t('otc.my-deal-ad.status.buy.get-commission')}</label>
                                                <InputMask
                                                    className="main-form__input"
                                                    id="get-commission"
                                                    type="number"
                                                    requierd={true}
                                                    min={
                                                        ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer 
                                                            ? (amountWithProcents(ad?.minSum, ad?.priceExtraPercent, '-')).toFixed(2)
                                                            : (amountWithProcents(ad?.minSum, ad?.priceExtraPercent, '+')).toFixed(2)
                                                    }
                                                    max={
                                                        ad?.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer 
                                                            ? (amountWithProcents(ad?.maxSum, ad?.priceExtraPercent, '-')).toFixed(2)
                                                            : (amountWithProcents(ad?.maxSum, ad?.priceExtraPercent, '+')).toFixed(2)
                                                    }
                                                    step={STEP_AMOUNT}
                                                    value={actualSum}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        this.setState({ actualSum: e.target.value })
                                                        const list = {
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: () => {
                                                                this.setState({
                                                                    baseSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '-')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                            [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: () => {
                                                                this.setState({
                                                                    baseSum: (
                                                                        amountWithProcents(e.target.value, ad?.priceExtraPercent, '+')
                                                                    ).toFixed(2).toString()
                                                                });
                                                            },
                                                        }
                                                        list[ad?.priceExtraPercentDirection]();
                                                    }}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="payment-method">{t('otc.my-deal-ad.payment-method')}</label>
                                                <InputMask
                                                    className="main-form__input"
                                                    id="payment-method"
                                                    type="text"
                                                    value={paymentMethod}
                                                    requierd={true}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                        paymentMethod: e.target.value,
                                                    })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="payment-details">{t('otc.my-deal-ad.status.buy.payment-details')}</label>
                                                <InputMask
                                                    className="main-form__input"
                                                    id="payment-details"
                                                    type="text"
                                                    value={bankCardNumber}
                                                    formatChars={
                                                        {'9': '[0-9]'}
                                                    }
                                                    requierd={true}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                        bankCardNumber: e.target.value,
                                                    })}
                                                />
                                            </div>
                                            <Button kind="primary" type="submit">{t('otc.my-deal-ad.btn-sell')}</Button>
                                        </div>
                                    </form>
                                ) : (
                                    <div className='status-text'>{t('otc.my-deal-ad.wait-offer')}</div>
                                )
                            )}
                            {deal?.state === 'ACCEPTED' && deal?.ad.action === 'SELL' && (
                                isSeller ? (
                                    <>
                                        <div className='status-text'>{t('otc.my-deal-ad.wait-pay')}</div>
                                        <Button kind="fourthly" type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                    </>
                                ) : (
                                    <form className="main-form" onSubmit={this.onPaid}>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.status.buy.to-pay')}</label>
                                            <span className="main-form__input">
                                                {
                                                    {
                                                        [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '-')).toFixed(2),
                                                        [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '+')).toFixed(2),
                                                    }[ad?.priceExtraPercentDirection]
                                                }
                                            </span>
                                        </div>
                                        {ad.description && 
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.condition')}</label>
                                            <span className="main-form__input">
                                                {ad.description}
                                            </span>
                                        </div>}
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.payment-method')}</label>
                                            <span className="main-form__input">
                                                {ad.paymentMethod}
                                            </span>
                                        </div>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.status.buy.payment-details')}</label>
                                            <span className="main-form__input">
                                                {ad.bankCardNumber}
                                            </span>
                                        </div>
                                        <div className="main-form__wrap">
                                            <Button kind="primary" type="submit">{t('otc.my-deal-ad.btn-paid')}</Button>
                                            <Button kind="fourthly"  type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                            <Button kind="fourthly"  type="button" onClick={this.onCancel}>{t('otc.my-deal-ad.btn-cancel')}</Button>
                                        </div>
                                    </form>
                                )
                            )}
                            {deal?.state === 'ACCEPTED' && deal?.ad.action === 'BUY' && (
                                isSeller ? (
                                    <>
                                        <div className='status-text'>{t('otc.my-deal-ad.wait-pay')}</div>
                                        <Button kind="fourthly"  type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                    </>
                                ) : (
                                    <form className="main-form" onSubmit={this.onPaid}>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.status.buy.to-pay')}</label>
                                            <span className="main-form__input">
                                                {
                                                    {
                                                        [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '+')).toFixed(2),
                                                        [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '-')).toFixed(2),
                                                    }[ad?.priceExtraPercentDirection]
                                                }
                                            </span>
                                        </div>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.payment-method')}</label>
                                            <span className="main-form__input">
                                                {deal?.paymentMethod}
                                            </span>
                                        </div>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-deal-ad.status.buy.payment-details')}</label>
                                            <span className="main-form__input">
                                                {deal?.bankCardNumber}
                                            </span>
                                        </div>
                                        <div className="main-form__wrap">
                                            <Button kind="primary" type="submit">{t('otc.my-deal-ad.btn-paid')}</Button>
                                            <Button kind="fourthly"  type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                            <Button kind="fourthly"  type="button" onClick={this.onCancel}>{t('otc.my-deal-ad.btn-cancel')}</Button>
                                        </div>
                                    </form>
                                )
                            )}
                            {deal?.state === 'PAID' && deal?.ad.action === 'SELL' && (
                                isSeller ? (
                                    <form className="main-form" onSubmit={this.onComplete}>
                                        <div className='status-text'>{t('otc.my-deal-ad.status.buy.amount')} {
                                        {
                                            [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '-')).toFixed(2),
                                            [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '+')).toFixed(2),
                                        }[ad?.priceExtraPercentDirection]
                                        }</div>
                                        <div className="main-form__wrap">
                                            <Button kind="primary" type="submit">{t('otc.my-deal-ad.status.buy.btn-payment-received')}</Button>
                                            <Button kind="fourthly" type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                        </div>
                                    </form>
                                ) : (
                                    <>
                                        <div className='status-text'>{t('otc.my-deal-ad.status.buy.confirmation-payment')}</div>
                                        <Button kind="fourthly" type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                    </>
                                )
                            )}
                            {deal?.state === 'PAID' && deal?.ad.action === 'BUY' && (
                                isSeller ? (
                                    <form className="main-form" onSubmit={this.onComplete}>
                                        <div className='status-text'>{t('otc.my-deal-ad.status.buy.amount')} {
                                            {
                                                [AppOtcAdPriceExtraPercentDirectionChoices.Creator]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '+')).toFixed(2),
                                                [AppOtcAdPriceExtraPercentDirectionChoices.Customer]: (amountWithProcents(deal?.amount, ad?.priceExtraPercent, '-')).toFixed(2),
                                            }[ad?.priceExtraPercentDirection]
                                        }</div>
                                        <div className="main-form__wrap">
                                            <Button kind="primary" type="submit">{t('otc.my-deal-ad.status.buy.btn-payment-received')}</Button>
                                            <Button kind="fourthly"  type="button" disabled={!isActiveArbitrage} onClick={this.onArbitration}>{t('otc.my-deal-ad.btn-arbitration')}</Button>
                                        </div>
                                    </form>
                                ) : (
                                    <div className='status-text'>{t('otc.my-deal-ad.status.buy.confirmation-payment')}</div>
                                )
                            )}
                            {deal?.state === 'COMPLETE' && (
                                <div className='status-text'>{t('otc.my-deal-ad.status.completed')}</div>
                            )}
                            {deal?.state === 'CANCELED' && (
                                <div className='status-text'>{t('otc.my-deal-ad.status.cancelled')}</div>
                            )}
                        </div>
                    </div>
                    <div className="main-block mini-chat">
                        <div className="chat">
                            <div className="chat__list-wrap">
                                <h2 className="operation-title">{otherUserName}</h2>
                                <div ref={ref => this.messagesScroll = ref}
                                     className="chat__message-content scroll-wrap">
                                    {messages.map((m) => {
                                        prevDay === null ? (prevDay = m.datetime.toLocaleString(DateTime.DATE_FULL)) : ((prevDay === m.datetime.toLocaleString(DateTime.DATE_FULL)) ? prevDayFlag = false : (prevDayFlag = true))
                                        prevDayFlag === true ? (prevDay = m.datetime.toLocaleString(DateTime.DATE_FULL)) : null
                                        if (m.incoming === prevIncomig) {
                                            consecutiveMessages = true;
                                        } else {
                                            consecutiveMessages = false;
                                            prevIncomig = m.incoming;
                                        }
                                        if (prevIncomig === null) {
                                            prevIncomig = m.incoming;
                                        }
                                        if (prevDayFlag) {
                                            consecutiveMessages = false;
                                        }
                                        
                                        return (<React.Fragment key={m.pk}>
                                            {
                                                prevDayFlag &&
                                                <span
                                                    className='chat__text chat__text--day-time'>
                                                    {m.datetime.setLocale(language).toLocaleString((DateTime.DATE_FULL))} 
                                                </span>
                                            }
                                            <div className={classNames('chat__message', {my: !m.incoming}, {'chat__message--consecutive': consecutiveMessages}, {'chat__message--file': m.attachment}, {'chat__message--image': (m.attachment === null ? false : (m.attachment).includes('.png') || (m.attachment).includes('.webp') || (m.attachment).includes('.jpg') || (m.attachment).includes('.jpeg') || (m.attachment).includes('.gif'))})}>
                                            <div className="chat__info-line">
                                                <span className='chat__text'>{m.incoming ? otherUserName : this.authStore.profile.name}</span>
                                            </div>
                                            <div className="chat__message-bg">
                                                <p className={classNames('chat__base-text', {service: m.service})}>{m.text}</p>
                                                {m.attachment && !((m.attachment).includes('.png') || (m.attachment).includes('.webp') || (m.attachment).includes('.jpg') || (m.attachment).includes('.jpeg') || (m.attachment).includes('.gif')) && (
                                                    <p className='chat__base-text chat__base-text-file'>
                                                        <a href={`${API_PROTO}://${API_HOST}${m.attachment}`} target='_blank'>[Файл]</a>
                                                    </p>
                                                )}
                                                {m.attachment && ((m.attachment).includes('.png') || (m.attachment).includes('.webp') || (m.attachment).includes('.jpg') || (m.attachment).includes('.jpeg') || (m.attachment).includes('.gif')) && (
                                                    <div className='chat__base-text chat__base-text-file chat__base-file-img'>
                                                        <a href={`${API_PROTO}://${API_HOST}${m.attachment}`} target='_blank'>
                                                            <img src={`${API_PROTO}://${API_HOST}${m.attachment}`}/>
                                                        </a>
                                                    </div>
                                                )}
                                                <span
                                                    className='chat__text chat__text--time'>
                                                        {m.datetime.toLocaleString((DateTime.TIME_24_SIMPLE))} 
                                                </span>
                                            </div>
                                        </div>                                       
                                        </React.Fragment>)
                                    })}
                                </div>
                                <form className="main-form" onSubmit={this.onSendMessage}>
                                    <input type='file' ref={ref => this.fileInput = ref} onChange={this.attachFile}
                                           style={{display: 'none'}}/>
                                    <div className="main-form__wrap">
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#"/>
                                            <TextareaAutosize
                                                minRows={1}
                                                // maxRows={5}
                                                className="main-form__textarea main-from__textarea--chat"
                                                name="#"
                                                placeholder={`${t('otc.chat.send-to-message')}`}
                                                value={messageInput}
                                                onKeyDown={e => e.key === 'Enter' && !e.shiftKey && this.onSendMessage(e)}
                                                onChange={e => this.setState({messageInput: e.target.value})}
                                            />
                                            <button className="btn send" type="submit">
                                                <svg>
                                                    <use href="#send"/>
                                                </svg>
                                            </button>
                                            <button className="main-form__file" type="button"
                                                    onClick={() => this.fileInput.click()}>
                                                <svg>
                                                    <use href="#file"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OTCDeal);
