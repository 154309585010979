import * as jwt from 'jsonwebtoken';
import * as crypto from 'crypto';
import { RootStore } from "./";
import {action,  makeObservable, observable, runInAction} from 'mobx';
import { injectable } from "inversify";

@injectable()
export class CoinBaze {

    @observable  coins: string[];
    @observable timeDataFraimChart: string = "15m";
    @observable editAdFromIndex: string = null;
    @observable selectDeposite: "MaxCode" | "ExmoCode" = "MaxCode";
    @observable selectWithdrawal: "MaxCode" | "ExmoCode" = "MaxCode";
    

    public constructor(protected rootStore: RootStore) {
        makeObservable(this);
    }

    @action setEditAdFromIndex(value) {
        this.editAdFromIndex = value;
    }

    @action setTimeDataFraimChart(value) {
        this.timeDataFraimChart = value;
    }

    @action getTimeDataFraimChart() {
        return this.timeDataFraimChart;
    }

    @action setSelectDeposite(value) {
        this.selectDeposite = value;
    }

    @action getSelectDeposite() {
        return this.selectDeposite;
    }

    @action setSelectWithdrawal(value) {
        this.selectWithdrawal = value;
    }

    @action getSelectWithdrawal() {
        return this.selectWithdrawal;
    }


}

