import React from 'react';
import HistoryDeposit from "../../components/HistoryDeposit";
import classNames from "classnames";
import { Button } from "../../components/Button";
import { pd, processRequestError, tf } from "../../utils/utilities";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { Api } from "../../utils/api";
import { AuthStore, CoinStore } from "../../stores";
import { OperationsType } from "../../utils/graphql";
import { toast } from "react-toastify";
import HistoryWithdrawRub from "../../components/HistoryWithdrawRub";
import BalanceLine from '../../components/active/BalanceLine';
import DepositMethod from '../../components/DepositMethod';
import WithdrawalMethod from '../../components/WithdrawalMethod';
import InfoCurrencyWallet from '../../components/InfoCurrencyWallet';

import {BalanceExchangeType} from "../../utils/graphql";
import { ExchangeExmoCodeCurrencyChoices } from '../../utils/graphql';
import { rootStore } from '../../Application';

import { withTranslation } from 'react-i18next';
import i18n from '../../i18n.js';

interface IAssetsRubTabProps {
    t: any;
}

interface IAssetsRubTabState {
    activeTab: 'deposit' | 'withdraw';
    depositCode: string;
    withdrawAmount: string;
    operations?: OperationsType;
    operationsExmoCode?: any;
    loading: boolean;
    otpCode: string;
    actualBalanceCurrency: number;
}

    // const onGetBalance = () => {
    //     if ( rootStore.coinStore.balances) {
    //         let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === currency)
    //         if (findOne) {
    //             return findOne.balance
    //         }
    //     }
    //     return 0;
    // }
    // const onGetFreezBalance = () => {
    //     if ( rootStore.coinStore.balances) {
    //         let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === currency)
    //         if (findOne) {
    //             return findOne.freezeBalance
    //         }
    //     }
    //     return 0;
    // }

@observer
class AssetsRubTab extends React.Component<IAssetsRubTabProps, IAssetsRubTabState> {
    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;
    @resolve(CoinStore)
    declare protected readonly coinStore: CoinStore;

    state: IAssetsRubTabState = {
        activeTab: 'deposit',
        depositCode: '',
        withdrawAmount: '',
        loading: false,
        otpCode: '',
        actualBalanceCurrency: null,
    }

    componentDidMount() {
        rootStore.coinBaze.setSelectDeposite('MaxCode');
        rootStore.coinBaze.setSelectWithdrawal('MaxCode');
        this.loadOperations();
    }

    loadOperations = async () => {
        this.setState({ operations: await this.api.getOperations('rub') });
        const operationsExmoCodeCopy = await this.api.getMyExmoCode();
        this.setState({operationsExmoCode: operationsExmoCodeCopy.filter(element => element.currency === ExchangeExmoCodeCurrencyChoices.Rub)});
    }

    onGetBalance = () => {
        this.coinStore.getBalancesAll()
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol === 'RUB')
            if (findOne) {
                return findOne.balance
            }
        }
        return 0
    }

    onDeposit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            console.log(rootStore.coinBaze.getSelectDeposite());
            switch(rootStore.coinBaze.getSelectDeposite()) {
                case 'MaxCode':
                    await this.api.depositRub(this.state.depositCode);
                    break;
                case 'ExmoCode':
                    await this.api.redeemExmoCode(this.state.depositCode);
                    break;                
            }
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success(`${i18n.language === 'en' ? 'The deposit was made successfully' : 'Пополнение произведено успешно'}`);
            this.setState({ depositCode: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
            await this.coinStore.getBalancesAll()
        }
    }

    onWithdraw = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            switch(rootStore.coinBaze.getSelectWithdrawal()) {
                case 'MaxCode':
                    await this.api.withdrawRub(this.state.withdrawAmount, this.state.otpCode);
                    break;
                case 'ExmoCode':
                    await this.api.createExmoCode(ExchangeExmoCodeCurrencyChoices.Rub, Number(this.state.withdrawAmount));
                    break;
            }
            await this.authStore.loadUserInfo();
            await this.loadOperations();
            toast.success(`${i18n.language === 'en' ? 'The deposit code was created, see the withdrawal history' : 'Код пополнения создан, см. в истории вывода'}`);
            this.setState({ withdrawAmount: '' });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
            await this.coinStore.getBalancesAll()
        }
    }

    render() {
        const { t } = this.props;
        const { activeTab, depositCode, loading, operations,operationsExmoCode, withdrawAmount, otpCode, actualBalanceCurrency } = this.state;
        const withdrawAmountNumber = parseFloat(withdrawAmount);
        const profile = this.authStore.profile;
        const currency = 'RUB';

        return (
            <div className="tabs__content active">
                <InfoCurrencyWallet
                    currency={currency}
                    mantissa={2}
                    currencyToUSD={0.0103}
                    currencyToRub={0}
                />

                {/* {this.onGetBalance()}uuuu */}
                <div className="deal">
                    <ul className="deal__list">
                        <li className={classNames('deal__item', { active: activeTab === 'deposit' })} onClick={() => this.setState({ activeTab: 'deposit' })}>{t('assets.tabs.replenishment')}</li>
                        <li className={classNames('deal__item', { active: activeTab === 'withdraw' })} onClick={() => this.setState({ activeTab: 'withdraw' })}>{t('assets.tabs.withdrawal')}</li>
                    </ul>
                    <div className={classNames('deal__content', { active: activeTab === 'deposit' })}>
                        <div className="main-content">
                            <div className="main-block">
                               <BalanceLine symbol='RUB' typeOperation='replenishment'/>
                                <div className="bye-sell">
                                    <form className="main-form" onSubmit={this.onDeposit}>
                                        <div className="main-form__wrap">
                                            <DepositMethod/>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('assets.condition.title')}</label>
                                                <p className="base-text cond">
                                                    {t('assets.condition.description')}
                                                </p>
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">{t('assets.code-replenishment')}</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={depositCode}
                                                    onChange={e => this.setState({ depositCode: e.target.value })}
                                                    required
                                                />
                                            </div>
                                            <div className="main-form__footer">
                                                <Button kind='secondary' type='submit' disabled={!depositCode} loading={loading}>{t('assets.button-replenishment')}</Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="main-block main-block--withdrawal">
                                <h2 className="operation-title">{t('assets.history-replenishments.title')}</h2>
                                <HistoryDeposit operations={operations?.deposits} mantissa={2} />
                            </div>
                        </div>
                    </div>
                    <div className={classNames('deal__content', { active: activeTab === 'withdraw' })}>
                        <div className="main-content">
                            <div className="main-block">
                            <BalanceLine symbol='RUB' typeOperation='withdrawal'/>
                            <div className="bye-sell">
                                <form className="main-form" onSubmit={this.onWithdraw}>
                                    <div className="main-form__wrap">
                                        <WithdrawalMethod/>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('assets.condition.title')}</label>
                                            <p className="base-text cond">
                                                {t('assets.condition.description')}
                                            </p>
                                        </div>
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('assets.withdrawal-sum')}</label>
                                            <div className="main-form__input-wrap">
                                                <input
                                                    className="main-form__input"
                                                    type="number"
                                                    min={0}
                                                    max={this.onGetBalance()}
                                                    step={.01}
                                                    value={withdrawAmount}
                                                    onChange={(e) => {
                                                        this.setState({ withdrawAmount: e.target.value });
                                                        this.setState({ actualBalanceCurrency: this.onGetBalance() });
                                                    }}
                                                />
                                                <button
                                                    className="main-form__max"
                                                    type="button"
                                                    onClick={() => {
                                                        this.setState({ withdrawAmount: tf(this.onGetBalance(),2) });
                                                        this.setState({ actualBalanceCurrency: this.onGetBalance() });
                                                    }}
                                                >
                                                    MAX
                                                </button>
                                            </div>
                                            
                                        </div>
                                        {profile?.otpEnabled && (
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Код 2FA</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={otpCode}
                                                    onChange={e => this.setState({ otpCode: e.target.value })}
                                                    required
                                                />
                                            </div>
                                        )}
                                        <div className="main-form__footer">
                                            <Button
                                                kind="secondary"
                                                type="submit"
                                                loading={loading}
                                                disabled={!withdrawAmount || withdrawAmountNumber <= 0 || withdrawAmountNumber > parseFloat(tf(actualBalanceCurrency,2))}
                                            >
                                                {t('assets.button-withdrawal')}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                            <div className="main-block main-block--withdrawal">
                                <h2 className="operation-title">{t('assets.history-withdrawal.title')}</h2>
                                <HistoryWithdrawRub operations={operations?.withdraws} operationsExmoCode={operationsExmoCode} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(AssetsRubTab);