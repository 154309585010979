import "reflect-metadata";
import "babel-polyfill";

import React from 'react';
import ReactDOM from 'react-dom';
import WrappedApp from './Application';

import './i18n.js'

ReactDOM.render(
    <WrappedApp />,
    document.getElementById('wrapper')
);
