import React, { Suspense } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "inversify-react";
import { observer } from "mobx-react";

import { RootStore } from "./stores";

import IndexPage from "./pages/IndexPage";
import ProfilePage from "./pages/ProfilePage";
import AssetsPage from "./pages/AssetsPage";
import OTCPage from "./pages/OTCPage";
import ExchangePage from "./pages/ExchangePage";

import { Modals } from "./modals";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ProtectedRoute from "./components/ProtectedRoute";
import { SVGSprite } from "./components/SVGSprite";

export const rootStore = new RootStore();
const container = rootStore.container

@observer
class Application extends React.Component {
    render() {
        // const { i18n } = this.props;
        if (!rootStore.authStore.initialized)
            return (
                <div>
                    {/* <img src={require("./images/preloader.svg")} /> */}
                    Loading..
                </div>
            );
        return (
            <Provider container={container}>
                <Router history={rootStore.historyStore}>
                    <ToastContainer/>

                    <Header />
                    <Switch>
                        <Route exact path='/' component={IndexPage} />
                        <ProtectedRoute exact path='/profile' component={ProfilePage} />
                        <ProtectedRoute path='/assets' component={AssetsPage} />
                        <ProtectedRoute path='/otc' component={OTCPage} />
                        <ProtectedRoute path='/exchange' component={ExchangePage} />
                    </Switch>
                    <Footer />

                    <Modals />

                    <SVGSprite />
                </Router>
            </Provider>
        );
    }
}

function WrappedApp() {
    return (
        <Suspense fallback="...loading">
            <Application/>
        </Suspense>
    );
}

export default WrappedApp;
