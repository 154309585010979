import React from 'react';
import { Modal } from "../components/Modal";
import { Modals, ModalStore } from "../stores/ModalStore";
import { resolve } from "inversify-react";
import { pd, processRequestError } from "../utils/utilities";
import { observer } from "mobx-react";
import { AuthStore } from "../stores";
import { toast } from "react-toastify";
import { Button } from "../components/Button";
import InputMask from "react-input-mask";
import TelegramLoginButton from 'react-telegram-login'
import { withTranslation, WithTranslation } from 'react-i18next';

interface ISignUpModalProps extends WithTranslation {
}

interface ISignUpModalState {
    name: string;
    email: string;
    password: string;
    password2: string;
    loading: boolean;
}

interface TelegramUser {
    id: number
    photo_url: string  
    first_name: string
    last_name: string  
    username: string
    auth_date: number  
    hash: string
  }

@observer
class SignUpModal extends React.Component<ISignUpModalProps, ISignUpModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: ISignUpModalState = {
        name: '',
        email: '',
        password: '',
        password2: '',
        loading: false,
    }

    onSubmit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.register(this.state.name, this.state.email, this.state.password);
            toast.success('Регистрация произведена успешно, теперь вы можете войти');
            this.modalStore.showModal(Modals.SignIn);
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

        handleTelegramResponse = async (user: TelegramUser) => {    
            try {
                const { id, first_name, username, photo_url, auth_date, hash } = user;
                await this.authStore.tokenAuthTg(id, first_name, username, photo_url, auth_date, hash);
                toast.success('Вход выполнен успешно');
                this.modalStore.hideModals();
            } catch (e) {
                processRequestError(e);
            } finally {
                this.setState({ loading: false });
            }  
        }
        bot_name = 'maxbit_icu_bot';

    render() {
        const { t } = this.props;
        const { name, email, password, password2, loading } = this.state;

        return (
            <Modal modalKey={Modals.SignUp}>
                <form className="main-form" onSubmit={this.onSubmit}>
                    <div className="main-form__wrap">
                        <h3 className="main-form__title">{t('sign-up.title')}</h3>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                        </div>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                        </div>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                        </div>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="password"
                                placeholder="Confirm password"
                                value={password2}
                                onChange={e => this.setState({ password2: e.target.value })}
                            />
                        </div>
                        <Button kind='primary' type='submit' loading={loading} disabled={!name || !email || !password || password !== password2}>{t('sign-up.title')}</Button>
                        {/* <section className="custom-telegram-button">
                            <TelegramLoginButton dataOnauth={this.handleTelegramResponse} botName='maxbit_icu_bot' usePic={false} />                
                        </section> */}
                        <div className="sign-in-nav">
                            <span className="sign-in-no-account__text">{t('sign-up.no-account')}</span>
                            <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>{t('signin.sign-up')}</button>
                        </div>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default withTranslation()(SignUpModal);