import axios from "axios";
        // Format and log the data Bunance
        // const candles = data.map(candle => ({
        //     openTime: candle[0],
        //     open: parseFloat(candle[1]),
        //     high: parseFloat(candle[2]),
        //     low: parseFloat(candle[3]),
        //     close: parseFloat(candle[4]),
        //     volume: parseFloat(candle[5]),
        //     closeTime: candle[6]
        // }));


interface IDataPairCandle {
    time: string;
    low: number;
    high: number;
    open: number;
    close: number;
    volume: number;
}

interface IDataPairsCandle extends Array<IDataPairCandle> {};

interface IDataPairHistogram {
    time: string;
    value: number;
}

interface IDataPairsHistogram extends Array<IDataPairHistogram> {};

const adapterBinanceToChartCandle = (data, activeCurrency) => {
    const result: IDataPairsCandle = data.map(function(item) {
        return {
            time: Math.floor(new Date(item[0]).getTime() / 1000),
            open: parseFloat(item[1]) * activeCurrency,
            high: parseFloat(item[2]) * activeCurrency,
            low: parseFloat(item[3]) * activeCurrency,
            close: parseFloat(item[4]) * activeCurrency,
            volume: parseFloat(item[5]),
        };
    });

    return result;
};

const adapterCryptoCompareToChartCandle = (data) => {
    const result: IDataPairsCandle = data.map(function(item) {
        return {
            time: Math.floor(new Date(item.time).getTime()),
            open: (item.open),
            high: (item.high),
            low: (item.low),
            close: (item.close),
            volume: (item.volumeto),
        };
    });

    return result;
};
        
        

const  getBinanceCandles = async (symbol, baseSymbol, interval, activeCurrency, limit = 1000) => {
    // const url = `https://api.binance.com/api/v3/klines?symbol=${symbol}${baseSymbol}&interval=${interval}&limit=${limit}&startTime=${startTime}&endTime=${endTime}`;
    console.log(symbol);
    const url = `https://api.binance.com/api/v3/klines?symbol=${symbol}${baseSymbol}&interval=${interval}&limit=${limit}`;
    
    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        
        const data = await response.json();
        
        return adapterBinanceToChartCandle(data, activeCurrency);
    } catch (error) {
        console.error(`Error fetching Binance ${symbol}-${baseSymbol} candles:`, error);
    }
}

// // Function to fetch candle data
// const getCryptoCompareCandles = async (fsym, tsym, time: '15m' | '30m' | '1h' | '1d', limit) => {
//     let baseUrl;
//     let aggregate = 1;
//     try {
//         switch (time) {
//             case '15m':
//                 baseUrl = 'https://min-api.cryptocompare.com/data/v2/histominute';
//                 aggregate = 15;
//                 break;
//             case '30m':
//                 baseUrl = 'https://min-api.cryptocompare.com/data/v2/histominute';
//                 aggregate = 30;
//                 break;
//             case '1h':
//                 baseUrl = 'https://min-api.cryptocompare.com/data/v2/histohour';
//                 break;
//             case '1d':
//                 baseUrl = 'https://min-api.cryptocompare.com/data/v2/histoday';
//                 break;                
//         }
//         const response = await axios.get(baseUrl, {
//             params: {
//                 fsym: fsym,        // From symbol (e.g., BTC)
//                 tsym: tsym,        // To symbol (e.g., RUB)
//                 limit: limit,      // Number of data points (e.g., 60 for the last hour)
//                 api_key: 'f4e7ad26c450b63f098bd479b2fa4eaa25229fe766e5171f5b0da6bec45f0fcb',   // Your API key (optional but recommended for higher limits)
//                 aggregate: aggregate,
//             },
//         });

//         if (response.data && response.data.Data) {
//             console.log('Candle data:', response.data.Data.Data);
//             return adapterCryptoCompareToChartCandle(response.data.Data.Data);
//         } else {
//             console.error('No data received.');
//         }
//     } catch (error) {
//         console.error('Error fetching candle data:', error.response ? error.response.data : error.message);
//     }
// }

// Function to fetch historical BTC-USDT candles from Binance
// async function fetchBTCUSDTCandles() {
//     try {
//         const response = await axios.get('https://api.binance.com/api/v3/klines', {
//             params: {
//                 symbol: 'BTCUSDT',
//                 interval: '1h',  // 1-hour candles (can be adjusted)
//                 limit: 100,       // number of candles to retrieve
//             }
//         });
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching BTC-USDT candles:', error);
//     }
// }
// 


// const fetchUSDTtoRUB = async () => {
//     const apiKey = "ee7c2635-1836-4964-a49d-f35c594534f7"; // Replace with your CoinMarketCap API Key
//     const url = "https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest";

//     // Define the query parameters
//     const params = new URLSearchParams({
//         symbol: "USDT",  // Cryptocurrency symbol
//         convert: "RUB"   // Target fiat currency
//     });

//     try {
//         // Make the API request
//         const response = await fetch(`${url}?${params.toString()}`, {
//             method: "GET",
//             headers: {
//                 "X-CMC_PRO_API_KEY": apiKey, // Authorization header
//                 "Accept": "application/json"
//             }
//         });

//         // Check if the response is successful
//         if (!response.ok) {
//             throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         // Parse the JSON response
//         const data = await response.json();
//         const price = data.data.USDT.quote.RUB.price; // Extract RUB price for USDT

//         console.log(`1 USDT = ${price.toFixed(2)} RUB`);
//         return price;
//     } catch (error) {
//         console.error("Error fetching exchange rate:", error.message);
//     }
// };

// // Execute the function
// fetchUSDTtoRUB();

import axios from 'axios';

// Типы для ответа API
interface CoinGeckoResponse {
    bitcoin: {
        rub: number;
    };
}



// Функция для получения курса XMR к RUB
// import axios from 'axios';

// async function getXMRtoRUBRate(): Promise<number | null> {
//     try {
//         const response = await axios.get('https://api.bitget.com/api/spot/v1/market/ticker', {
//             params: {
//                 symbol: 'XMRUSDT',
//             },
//         });
//         const rate = response.data?.data?.last;
//         if (rate) {
//             console.log(`Курс XMR к RUB: ${rate}`);
//             return parseFloat(rate);
//         }
//         console.warn('Курс не найден');
//         return null;
//     } catch (error) {
//         console.error('Ошибка при запросе курса:', error);
//         return null;
//     }
// }

// getXMRtoRUBRate();


// type pairsCurrenciesConvertion = 'XMRUSD' | 'XMRRUB';

// export const getActualCurrency = async (pairs: pairsCurrenciesConvertion) => {
//     let data;
//     switch (pairs) {
//         case 'XMRRUB':
//             data = getXMRtoRUBRate();
//     }

//     return data;
// };






// // Function to convert BTC-USDT candles to BTC-RUB
// async function convertBTCtoRUB() {
//     try {
//         // Get the BTC to USDT candles (OHLCV data)
//         const btcCandles = await fetchBTCUSDTCandles();
        
//         // Get the USDT to RUB exchange rate
//         // const usdtRUB = await fetchUSDTtoRUB();
        
//         // Convert the candle data to BTC-RUB using the exchange rate
//         const btcRUBCandles = btcCandles.map(candle => {
//             const open = candle[1] * 1;   // Open price * USDT-RUB rate
//             const high = candle[2] * 1;   // High price * USDT-RUB rate
//             const low = candle[3] * 1;    // Low price * USDT-RUB rate
//             const close = candle[4] * 1;  // Close price * USDT-RUB rate
//             const volume = candle[5];           // Volume remains the same

//             return {
//                 open: open,
//                 high: high,
//                 low: low,
//                 close: close,
//                 volume: volume,
//                 time: candle[0], // Timestamp of the candle
//             };
//         });
//         console.log('BTC to RUB Candles:', btcRUBCandles);
//     } catch (error) {
//         console.error('Error converting BTC to RUB:', error);
//     }
// }

// // Run the conversion
// convertBTCtoRUB();

export const getDataCandles = async (symbol, baseSymbol, interval, pairs = 'BTCRUB', limit = 1000) => {
    let data;
    switch (pairs) {
        case 'BTCRUB':
            data = (getBinanceCandles(symbol, 'USDT', interval, 99.97, limit)); 
            // data = getCryptoCompareCandles(symbol, baseSymbol, interval, 2000);
            break
        case 'BTCUSDT':
            data = (getBinanceCandles(symbol, baseSymbol, interval, 1, limit)); 
            // data = getCryptoCompareCandles(symbol, baseSymbol, interval, 2000);
            break
        case 'LTCUSDT':
            data = (getBinanceCandles(symbol, baseSymbol, interval, 1, limit));
            break
        case 'USDTRUB':
            data = (getBinanceCandles(symbol, baseSymbol, interval, 1, limit)); 
            // data = getCryptoCompareCandles(symbol, baseSymbol, interval, 2000);
            break
        case 'UZSRUB':
            // console.log('wkkrjiorewjgioer');
            data = (getBinanceCandles(symbol, 'USDT', interval, 99.97, limit)); 
            break
        case 'AEDRUB':
            // data = getCryptoCompareCandles(symbol, baseSymbol, interval, 2000);
            data = (getBinanceCandles(symbol, 'USDT', interval, 99.97, limit)); 
            break
        case 'KZTRUB':
            // data = getCryptoCompareCandles(symbol, baseSymbol, interval, 2000);
            data = (getBinanceCandles(symbol, 'USDT', interval, 99.97, limit));     
            break
        case 'XMRUSDT':
            // data = getCryptoCompareCandles(symbol, baseSymbol, interval, 2000);
            data = (getBinanceCandles(symbol, 'USDT', interval, 99.97, limit));     
            break
        
    }
    return data;
}
  

