import React from 'react';
import { Modal } from "../components/Modal";
import { Modals, ModalStore } from "../stores/ModalStore";
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { pd, processRequestError } from "../utils/utilities";
import { Button } from "../components/Button";
import { toast } from "react-toastify";
import { AuthStore } from "../stores";
import _ from "lodash";
import InputMask from "react-input-mask";
import TelegramLoginButton from 'react-telegram-login'
// import { toast } from "react-toastify";
// import { processRequestError } from "../utils/utilities";
import { TG_BOT_NAME, API_HOST } from "../utils/const";
import { WithTranslation, withTranslation } from 'react-i18next';
interface ISignInModalProps extends WithTranslation {
}

interface ISignInModalState {
    email: string;
    password: string;
    has2fa: boolean;
    otpCode: string;
    loading: boolean;
}

interface TelegramUser {
    id: number
    photo_url: string  
    first_name: string
    last_name: string  
    username: string
    auth_date: number  
    hash: string
  }

  

@observer
class SignInModal extends React.Component<ISignInModalProps, ISignInModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: ISignInModalState = {
        email: '',
        password: '',
        has2fa: false,
        otpCode: '',
        loading: false,
    }

    // CustomTelegramButton =() => {
    //     const handleClick = () => {
    //       const botName = "your_bot_name";
    //       const redirectUri = encodeURIComponent("https://your-redirect-url.com");
    //       window.open(
    //         `https://oauth.telegram.org/auth?bot_id=${botName}&redirect_uri=${redirectUri}&scope=bot&response_type=token`,
    //         "_blank"
    //       );
    //     };
    //     return (
    //         <button
    //           onClick={handleClick}
    //           style={{
    //             backgroundColor: "#0088cc",
    //             color: "#fff",
    //             padding: "10px 20px",
    //             border: "none",
    //             borderRadius: "5px",
    //             cursor: "pointer",
    //             fontSize: "16px",
    //           }}
    //         >
    //           Login with Telegram
    //         </button>
    //       );
    //     }        

    onSubmit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.tokenAuth(this.state.email, this.state.password, this.state.otpCode);
            toast.success('Вход выполнен успешно');
            this.modalStore.hideModals();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    checkHas2FA = _.debounce(async () => {
        this.setState({ loading: true });
        try {
            this.setState({ has2fa: await this.authStore.has2FA(this.state.email) });
        } finally {
            this.setState({ loading: false });
        }
    }, 300);

    handleTelegramResponse = async (user: TelegramUser) => {    
        try {
            const { id, first_name, username, photo_url, auth_date, hash } = user;
            await this.authStore.tokenAuthTg(id, first_name, username, photo_url, auth_date, hash);
            toast.success('Вход выполнен успешно');
            this.modalStore.hideModals();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }  
    }
    bot_name = 'maxbit_icu_bot';

    render() {
        const { t } = this.props;
        const { email, password, has2fa, otpCode, loading } = this.state;

        return (
            <Modal modalKey={Modals.SignIn} onShow={this.checkHas2FA}>
                <form className="main-form" onSubmit={this.onSubmit}>
                    <div className="main-form__wrap">
                        <h3 className="main-form__title">{t('signin.title')}</h3>
                        <div className="main-form__field">
                            <InputMask
                                className="main-form__input"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ email: e.target.value }); this.checkHas2FA() }}
                            />
                        </div>
                        <div className="main-form__field main-form__field_col">
                            <InputMask
                                className="main-form__input"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value })}
                            />
                            <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.Recover)}>Забыли пароль?</button>
                        </div>
                        {has2fa && (
                            <div className="main-form__field">
                                <input
                                    className="main-form__input"
                                    type="text"
                                    placeholder="Code 2FA"
                                    value={otpCode}
                                    onChange={e => this.setState({ otpCode: e.target.value })}
                                />
                            </div>
                        )}
                        <Button kind='primary' type='submit' loading={loading} disabled={!email || !password || has2fa && otpCode.length !== 6}>{t('signin.title')}</Button>
                        
                        {/* <section className="custom-telegram-button">
                            <TelegramLoginButton dataOnauth={this.handleTelegramResponse} botName='maxbit_icu_bot' usePic={false} />                
                        </section> */}
                        <div className="sign-in-nav">
                            <span className="sign-in-no-account__text">{t('signin.no-account')}</span>
                            <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignUp)}>{t('signin.sign-up')}</button>
                        </div>
                    </div>
                </form>
            </Modal>
        )
    }
}

export default withTranslation()(SignInModal);
