import React from 'react';
import Select from 'react-select'
import { resolve } from 'inversify-react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip'
import InputMask from 'react-input-mask';

import { CoinBaze } from '../../stores/CoinBaze';
import { ModalStore } from '../../stores';

import { Api } from "../../utils/api";
import { pd, processRequestError } from '../../utils/utilities';
import { OtcAd, AppOtcAdPriceExtraPercentDirectionChoices } from '../../utils/graphql';

import { Button } from '../../components/Button';
import i18n from '../../i18n.js';

interface IOTCMyAdsProps extends WithTranslation {
}

interface IOTCMyAdsState {
    myAds?: OtcAd[];
    action: any;
    currency: string;
    minSum: string;
    maxSum: string;
    paymentMethod: string;
    description: string;
    priceExtraPercent: string;
    priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices;
    loading?: boolean;
    deletingId?: string;
    deleteLoading?: boolean;
    editingId?: string;
    bankCardNumber?: string;
    selectedOptionDirection: {
        value: string;
        label: any;
    };
    selectedOptionCurrency: {
        value: string;
        label: any;
    };
}

const initialState: IOTCMyAdsState = {
    action: 'buy',
    currency: 'rub',
    minSum: null,
    maxSum: null,
    paymentMethod: '',
    description: '',
    priceExtraPercent: '0',
    priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Creator,
    selectedOptionDirection: null,
    selectedOptionCurrency: { value: 'rub', label: 'RUB'},
}

@observer
class OTCMyAds extends React.Component<IOTCMyAdsProps, IOTCMyAdsState> {
    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(CoinBaze)
    declare protected readonly coinBaze: CoinBaze;

    state: IOTCMyAdsState = {
        myAds: [],
        ...initialState,
        selectedOptionDirection: { value: 'buy', label: this.props.t('otc.my-ads.ad-info.buy')},
        loading: false,
        deleteLoading: false,
    }

    componentDidMount() {
        this.loadAds();
        this.setState({ selectedOptionDirection: {value: 'buy', label: this.props.t('otc.my-ads.ad-info.buy')} });
        this.checkEdiFromIndex();
    }

    loadAds = async () => {
        this.setState({ myAds: await this.api.getMyOtcAds() });
    }

    checkEdiFromIndex = async () => {
        await this.loadAds();
        if (this.coinBaze.editAdFromIndex !== null) {
            this.state.myAds.forEach(element => {
                if (this.coinBaze.editAdFromIndex === element.id) {
                    this.setState({ editingId: element.id, ...element as any, action: element.action.toLowerCase(), currency: element.currency.toLowerCase() })
                }
            });
            this.coinBaze.setEditAdFromIndex(null);
        }
    }

    onCreate = async (e: React.FormEvent) => {
        pd(e);
        const { action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent, priceExtraPercentDirection, bankCardNumber } = this.state;
        this.setState({ loading: true });
        try {
            console.log(this.state);
            await this.api.otcCreateAd(action,currency, (minSum === null || minSum === '') ? '0' : minSum, maxSum, paymentMethod, description, (Math.abs(parseFloat(priceExtraPercent))).toString(), bankCardNumber, priceExtraPercentDirection);
            this.setState(initialState);
            toast.success(`${this.props.t('otc.my-ads.message.ad-created')}`);
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
            this.setState({
                action: 'buy',
                currency: 'rub',
                minSum: '',
                maxSum: '',
                paymentMethod: '',
                description: '',
                priceExtraPercent: '0',
                selectedOptionDirection: {value: 'buy', label: this.props.t('otc.my-ads.ad-info.buy')},
                selectedOptionCurrency: { value: 'rub', label: 'RUB'},
            });
        }
    }

    onEdit = async (e: React.FormEvent) => {
        pd(e);
        const { action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent, priceExtraPercentDirection, editingId, bankCardNumber } = this.state;
        this.setState({ loading: true });
        try {
            await this.api.otcEditAd(editingId, action,currency, minSum, maxSum, paymentMethod, description, (Math.abs(parseFloat(priceExtraPercent))).toString(), bankCardNumber, priceExtraPercentDirection);
            this.setState({ ...initialState, editingId: undefined });
            toast.success(`${this.props.t('otc.my-ads.message.ad-edited')}`);
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
            this.setState({
                action: 'buy',
                currency: 'rub',
                minSum: '',
                maxSum: '',
                paymentMethod: '',
                description: '',
                priceExtraPercent: '0',
                priceExtraPercentDirection: null,
                selectedOptionDirection: {value: 'buy', label: this.props.t('otc.my-ads.ad-info.buy')},
                selectedOptionCurrency: { value: 'rub', label: 'RUB'},
            });
        }
    }

    onDelete = async (id: string) => {
        this.setState({ deleteLoading: true });
        try {
            await this.api.otcDeleteAd(id)
            toast.success(`${this.props.t('otc.my-ads.message.ad-delete')}`);
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ deleteLoading: false, deletingId: undefined });
        }
    }

    onCloseAd = async (id: string) => {
        try {
            await this.api.otcSetActiveAd(id, false);
            toast.success(`${this.props.t('otc.my-ads.message.ad-open')}`);
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        }
    }

    onOpenAd = async (id: string) => {
        try {
            await this.api.otcSetActiveAd(id, true);
            toast.success(`${this.props.t('otc.my-ads.message.ad-close')}`);
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        }
    }

    render() {
        const { myAds, action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent, loading, deletingId, deleteLoading, editingId, bankCardNumber } = this.state;
        const { t, i18n } = this.props;

        const { selectedOptionDirection } = this.state;
        const optionsDirection  = [
            { value: 'buy', label: t('otc.my-ads.ad-info.buy') },
            { value: 'sell', label: t('otc.my-ads.ad-info.sell') },
        ]

        const handleChangeSelectDirecion = (selectedOption) => {
            this.setState({ action: selectedOption.value as any })
            this.setState({ selectedOptionDirection: selectedOption})
        };

        const { selectedOptionCurrency } = this.state;
        const optionsCurrency  = [
            { value: 'rub', label: 'RUB' },
            // { value: 'kzt', label: 'KZT' },
            // { value: 'btc', label: DT' },
        ]

        const handleChangeSelectCurrency = (selectedOption) => {
            this.setState({ currency: selectedOption.value as any })
            this.setState({ selectedOptionCurrency: selectedOption})
        };

                // i18n.on("languageChanged", () => {
        //     console.log(selectedOptionDirection);
        //     if (selectedOptionDirection.value === "sell") {
        //         this.setState({selectedOptionDirection: { value: 'sell', label: t('otc.my-ads.ad-info.sell') }});
        //         console.log('gergreger');
        //     }
                
        //     if (selectedOptionDirection.value === "buy")
        //         this.setState({selectedOptionDirection: {value: 'buy', label: t('otc.my-ads.ad-info.buy')}});
        // });

        const onChangePercentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.currentTarget.value);
            console.log(value);
            if (this.state.action === 'sell') {
                console.log('sell');
                if (value === 0) {
                    this.setState({ priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Creator })
                } else if (value < 0) {
                    this.setState({ priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Creator })
                } else if (value > 0) {
                    this.setState({ priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Customer })
                }
            } else if (this.state.action === 'buy') {
                console.log('buy');
                if (value === 0) {
                    this.setState({ priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Creator })
                    console.log('direction 0');
                    console.log(this.state.priceExtraPercentDirection)
                } else if (value > 0) {
                    this.setState({ priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Creator })
                    console.log('direction > 0');
                    console.log(this.state.priceExtraPercentDirection)
                } else if (value < 0) {
                    this.setState({ priceExtraPercentDirection: AppOtcAdPriceExtraPercentDirectionChoices.Customer })
                    console.log('direction < 0...');
                    console.log(this.state.priceExtraPercentDirection)
                }
            }
            this.setState({ priceExtraPercent: e.target.value })
            // console.log(this.state.priceExtraPercentDirection)
        };

        return (
            <div className="tabs__content active">
                <div className="main-content">
                    <div className="main-block my-ad">
                        <h2 className="operation-title">{t('otc.my-ads.title')}</h2>
                        <div className="operation-wrap scroll-wrap">
                            {(myAds.length) === 0 ? 
                                <div className="no-my-ads">
                                    {t('otc.my-ads.no-my-ads')}
                                </div>
                                : null    
                            }
                            {myAds.map(ad => (
                                <div className="operation" key={ad.id}>
                                    <div className="operation__wrap">
                                        <div className="operation__block">
                                            <span className="operation__title">{ad.action === 'SELL' ? `${t('otc.my-ads.seller')}` : `${t('otc.my-ads.buyer')}`}</span>
                                            <span className="operation__value">{ad.user.name}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">{t('otc.my-ads.payment-method')}</span>
                                            <span className="operation__value">{ad.paymentMethod}</span>
                                        </div>
                                        <div className="operation__block operation__block--commission">
                                            <span className="operation__title">{t('otc.my-ads.commission')}</span>
                                            {ad.priceExtraPercent !== "0.00" ?
                                                <>
                                                    <span className="operation__value"  data-tooltip-id="commission-direction-my-ad-tooltip" data-tooltip-content={`${ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Customer ? 'Доплата вам' : ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? 'Вы доплачиваете' : null }`}>{ad.priceExtraPercentDirection === AppOtcAdPriceExtraPercentDirectionChoices.Creator ? '-' : null}{parseFloat(ad.priceExtraPercent) % 1000}%</span>
                                                    <Tooltip className="commission-tooltip" id="commission-direction-my-ad-tooltip" />
                                                </> :
                                                <span className="operation__value">0.0%</span>
                                            }
                                        </div>
                                        <div className="operation__block operation__block--amount">
                                            <span className="operation__title">{t('otc.my-ads.amount')}</span>
                                            <span className="operation__value">{ad.minSum} - {ad.maxSum} {ad.currency}</span>
                                        </div>
                                        <div className="operation__block operation__block--status">
                                            <span className="operation__title">{t('otc.my-ads.status')}</span>
                                            <span className="operation__value">
                                                {ad.isActive === true && t('otc.my-ads.status-active')}
                                                {ad.isActive === false && t('otc.my-ads.status-no-active')}
                                            </span>
                                        </div>

                                        <div className="operation__btn-wrap operation__btn-wrap_func">
                                            {editingId === ad.id ? (
                                                <button className="btn fourthly small" type="button" disabled>{t('otc.my-ads.ad-changing')}</button>
                                            ) : (
                                                (ad.status==='ACTIVE') ?
                                                    (
                                                        <>
                                                    <button className="btn fourthly small btn-edit-ad" type="button" onClick={() => {
                                                        this.setState({ editingId: ad.id, ...ad as any, action: ad.action.toLowerCase(), currency: ad.currency.toLowerCase() })
                                                        if (ad.action === 'SELL') {
                                                            this.setState({ selectedOptionDirection: { value: 'sell', label: t('otc.my-ads.ad-info.sell') }})
                                                        } else if (ad.action === 'BUY') {
                                                            this.setState({ selectedOptionDirection: { value: 'buy', label: t('otc.my-ads.ad-info.buy') }})
                                                        }
                                                    }}>{t('otc.my-ads.btn-edit')}</button>
                                                    {ad.isActive === true && 
                                                        <button className="btn fourthly small btn-close-ad" type="button" onClick={() => this.onCloseAd(ad.id)}>{t('otc.my-ads.btn-close')}</button>
                                                    }
                                                    {ad.isActive === false && 
                                                        <button className="btn fourthly small btn-open-ad" type="button" onClick={() => this.onOpenAd(ad.id)}>{t('otc.my-ads.btn-open')}</button>
                                                    }
                                                    
                                                    {deletingId === ad.id ? (
                                                        <Button
                                                            kind='tertiary'
                                                            small
                                                            type="button"
                                                            onClick={() => this.onDelete(ad.id)}
                                                            onMouseLeave={() => !deleteLoading && this.setState({ deletingId: undefined })}
                                                            loading={deleteLoading}
                                                        >
                                                            {t('otc.my-ads.you-sure')}
                                                        </Button>
                                                    ) : (
                                                        <button className="btn tertiary small" type="button" onClick={() => this.setState({ deletingId: ad.id })}>{t('otc.my-ads.btn-delete')}</button>
                                                    )}
                                                </>

                                                    ) : (<span className="operation__value">{t('otc.my-ads.deal-complited')}</span>)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="main-block">
                        <h2 className="operation-title">{editingId ? `${t('otc.my-ads.ad-info.edit-title')}` : `${t('otc.my-ads.ad-info.creature-title')}`}</h2>
                        <div className="create-wrap">
                            <form className="main-form" onSubmit={editingId ? this.onEdit : this.onCreate}>
                                <div className="main-form__wrap">
                                    <div className="main-form__field main-form__field_sel">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.direction')}</label>
                                        <Select
                                            options={optionsDirection}
                                            key={'direction-means'}
                                            classNamePrefix='direction-select'
                                            className='direction-select'
                                            onChange={handleChangeSelectDirecion}
                                            value={selectedOptionDirection}
                                            unstyled={true}
                                            required={true}
                                        />
                                    </div>
                                    <div className="main-form__field main-form__field_sel">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.currency')}</label>
                                        <Select
                                            options={optionsCurrency}
                                            key={'currency-means'}
                                            classNamePrefix='currency-select'
                                            className='currency-select'
                                            onChange={handleChangeSelectCurrency}
                                            value={selectedOptionCurrency}
                                            unstyled={true}
                                            required={true}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.min-amount')}</label>
                                        <InputMask
                                            className="main-form__input"
                                            type='number'
                                            value={minSum}
                                            min={0}
                                            step={.01}
                                            // required={true}
                                            formatChars={
                                                {'9': '[0-9]'}
                                            }
                                            placeholder='0,00'
                                            onChange={e => this.setState({ minSum: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.max-amount')}</label>
                                        <InputMask
                                            className="main-form__input"
                                            type='number'
                                            value={maxSum}
                                            min={minSum}
                                            step={.01}
                                            required={true}
                                            placeholder='0,00'
                                            formatChars={
                                                {'9': '[0-9]'}
                                            }
                                            onChange={e => this.setState({ maxSum: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.payment-method')}</label>
                                        <input
                                            className="main-form__input"
                                            type="text"
                                            required={true}
                                            value={paymentMethod}
                                            onChange={e => this.setState({ paymentMethod: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.description')}</label>
                                        <textarea
                                            className="main-form__textarea"
                                            name="#"
                                            value={description}
                                            onChange={e => this.setState({ description: e.target.value })}
                                        />
                                    </div>
                                    {
                                        action === 'sell' &&
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.payment-details')}</label>
                                            <InputMask
                                                className="main-form__input"
                                                type="text"
                                                value={bankCardNumber}
                                                onChange={e => this.setState({ bankCardNumber: e.target.value })}
                                            />
                                        </div>
                                    }
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">{t('otc.my-ads.ad-info.commission')}, %</label>
                                        <InputMask
                                            className="main-form__input"
                                            // type='text'
                                            // formatChars={
                                            //     {'9': '[0-9]'}
                                            // }
                                            type='number'
                                            value={priceExtraPercent}
                                            required={true}
                                            // min={0}
                                            step={.01}
                                            onChange={onChangePercentHandler}
                                        />
                                    </div>
                                    <div className="btn-wrap">
                                        {editingId && <button className="signin" type="submit" onClick={() => this.setState(
                                            {
                                                editingId: undefined,
                                                action: 'buy',
                                                currency: 'rub',
                                                minSum: '',
                                                maxSum: '',
                                                paymentMethod: '',
                                                description: '',
                                                priceExtraPercent: '0',
                                                selectedOptionDirection: {value: 'buy', label: this.props.t('otc.my-ads.ad-info.buy')},
                                                selectedOptionCurrency: { value: 'rub', label: 'RUB'},
                                            }
                                        )}>{t('otc.my-ads.ad-info.btn-cancel')}</button>}
                                        <Button kind='primary' type="submit" loading={loading}>{t('otc.my-ads.ad-info.btn-save')}</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OTCMyAds);
