import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import en from './locales/en/translation.json';
import ru from './locales/ru/translation.json';

const savedLanguage = localStorage.getItem('i18nLang') || 'defaultLanguage';
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ru: { translation: ru },
          },
        // debug: true,
        lng: 'ru',
        fallbackLng: 'ru',
        backend: {
            loadPath: '/public/locales/{{lng}}/translation.json',
        },
        detection: {
            order: ['cookie', 'localStorage'],
            caches: ['cookie'],
          },
    });
    i18n.changeLanguage(savedLanguage);
export default i18n;