import React, {FC, useState, useEffect} from 'react';

import {observer} from 'mobx-react-lite';
import {rootStore} from "../../Application";
import {tf, normalizeVolume, PrecisionCount} from "../../utils/utilities";

import {BalanceExchangeType, CoinType} from "../../utils/graphql";
import { toast } from "react-toastify"
import { useTranslation } from 'react-i18next';

interface IFormBuyProps  {
    symbol: string;
    mantissa?: number;
    typeOperation?: 'replenishment' | 'withdrawal';
}

const BalanceLine: FC<IFormBuyProps > = ({symbol,mantissa=2, typeOperation}) => {
    const { t } = useTranslation();
    return (
        <div className="balance-head">
            {
                typeOperation === 'replenishment' ? 
                <h2 className="operation-title">{t('assets.type-operation.replenishment')} {symbol}</h2>
                : ``
            }
            {
                typeOperation === 'withdrawal' ? 
                <h2 className="operation-title">{t('assets.type-operation.withdrawal')} {symbol}</h2>
                : ``
            }
        </div>
    )
}

export default observer(BalanceLine)
