import React, {FC, useState, useEffect} from 'react';
import {DateTime} from "luxon";
import {observer} from 'mobx-react-lite';
import classNames from "classnames";
import { useTranslation } from 'react-i18next';

import {rootStore} from "../../Application";

import {tf,processRequestError, divideNumberByPieces} from "../../utils/utilities";
import { getDataCandles } from '../../utils/apiCoinBase';

import {Button} from "../../components/Button";
import ChartComponent from '../Chart';
import SelectTimeChart from '../SelectTimeChart';

interface IHistoryOrdersProps {
    symbol: string;
    baseSymbol: string;
    coinSymbol: string;
}

const HistoryOrders: FC<IHistoryOrdersProps> = ({symbol, baseSymbol, coinSymbol}) => {
    const [list, setList] = React.useState([]);

    const [activeTab, setactiveTab] = useState<string>('chart')
    const [limit, setLimit] = useState<number>(50)
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)

    const { t } = useTranslation();

    const types = {
        "LIMIT": `${t('exchange.limit')}`,
        "MARKET": `${t('exchange.by-market')}`
    }

    useEffect(() => {
        rootStore.coinBaze.setTimeDataFraimChart("15m");
    }, []);

    useEffect(() => {
        const getData = async () => {
            const now = new Date();
            const dataCandles = getDataCandles(coinSymbol, baseSymbol, rootStore.coinBaze.getTimeDataFraimChart(), symbol);
            const response = await dataCandles;
            setList(response);
          };
          getData();
    }, [rootStore.coinBaze.timeDataFraimChart]);

    // @ts-ignore
    useEffect(async () => {
        await loadingOrderCurrent();
    }, []);

    // @ts-ignore
    useEffect(async () => {
            await loadingOrderHistory()
            await loadingOrderCurrent()
    }, [symbol]);

    // @ts-ignore
    useEffect(async () => {
        if (activeTab == "history") {
            await loadingOrderHistory()
        } else {
            await loadingOrderCurrent()
        }

    }, [activeTab]);

    const loadingOrderCurrent = async () => {
        await rootStore.ordersStore.getOrdersCurrent(symbol, limit, page)
    }
    const loadingOrderHistory = async () => {
        await rootStore.ordersStore.getOrdersHistory(symbol, limit, page)
    }
    const onCancelOrder = async (pk) => {
        setLoading(true)
        try {
            await rootStore.ordersStore.cancelOrder(pk)
            await rootStore.coinStore.getBalancesBuyOne(rootStore.coinStore.balanceBuySymbol)
            await rootStore.coinStore.getBalancesOne(rootStore.coinStore.balanceSellSymbol)

            await loadingOrderCurrent()
        } catch (e) {
            processRequestError(e);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="container">
            <div className="tabs js-tabs">
                <ul className="tabs__list js-tab-list">
                    <li className={classNames('tabs__item', {active: activeTab === 'chart'})}
                        onClick={() => setactiveTab('chart')}>{t('exchange.chart.title')}
                    </li>
                    <li className={classNames('tabs__item', {active: activeTab === 'orders'})}
                        onClick={() => setactiveTab('orders')}>{t('exchange.open-voucher.title')}
                    </li>
                    <li className={classNames('tabs__item', {active: activeTab === 'history'})}
                        onClick={() => setactiveTab('history')}>{t('exchange.history.title')}
                    </li>
                    
                </ul>
                <div className="tabs__content js-tab-content active chart-tabs">
                    <div className="table exchange history-orders scroll-wrap">
                        <div className="table__wrap">
                            {activeTab=="orders" &&
                                <div>
                                    <h2 className="operation-title"> {symbol}</h2>
                                    <div className="table__head">
                                    <div className="table__field"><span>{t('exchange.open-voucher.direction')}</span></div>
                                    <div className="table__field table__field_row"><span>{t('exchange.open-voucher.price')}</span></div>
                                    <div className="table__field"><span>{t('exchange.open-voucher.volume')} ({coinSymbol})</span></div>
                                    <div className="table__field"><span>{t('exchange.open-voucher.volume')} ({baseSymbol})</span></div>

                                    <div className="table__field"><span>{t('exchange.open-voucher.data')}</span></div>

                                    {activeTab === 'orders' && (
                                        <div className="table__field table__field--mini">
                                            <span></span>
                                        </div>
                                    )}
                                    </div>
                                    <div className="table__body scroll-wrap-inner">
                                        {rootStore.ordersStore.orderCurrent?.map((one) => (
                                            <div className="table__row" key={one.orderId}>
                                                <div className={classNames('table__field', {
                                                    'success-stock': one.direction === 'BUY',
                                                    'warning': one.direction === 'SELL',
                                                })}>
                                                    <span>
                                                        {(one.direction === 'SELL') && t('exchange.open-voucher.sell')}
                                                        {(one.direction === 'BUY') && t('exchange.open-voucher.buy')}
                                                    </span>
                                                </div>
                                                <div className={'table__field'}>
                                                    <span>{divideNumberByPieces(one.price)}</span>
                                                </div>
                                                <div className={'table__field'}>
                                                    <span>{divideNumberByPieces(one.amount)}/{one.remainingAmount}</span>
                                                </div>
                                                <div className={'table__field'}>
                                                    <span>{divideNumberByPieces(one.turnover)}</span>
                                                </div>
                                                <div className="table__field">
                                                    <span>
                                                        {(DateTime.fromISO(one.time).toFormat('D') === DateTime.now().toFormat('D')) && (DateTime.fromISO(one.time).toFormat('t'))}
                                                        {(DateTime.fromISO(one.time).toFormat('D') !== DateTime.now().toFormat('D')) && (DateTime.fromISO(one.time).toFormat('D t'))}
                                                    </span>
                                                </div>
                                                {activeTab === 'orders' && (
                                                    <div className="table__field table__field--mini"><span>
                                                        <Button kind='tertiary' small={true} type="button" loading={loading}
                                                                onClick={async () => {
                                                                    await onCancelOrder(one.orderId.toString())
                                                                }}>{t('exchange.open-voucher.button-cancel')}</Button>
                                                    </span></div>)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            {activeTab=="history" &&
                                <div>
                                    <h2 className="operation-title"> {symbol}</h2>
                                    <div className="table__head">
                                    <div className="table__field"><span>{t('exchange.open-voucher.type')}</span></div>
                                    <div className="table__field"><span>{t('exchange.open-voucher.direction')}</span></div>
                                    <div className="table__field table__field_row">
                                        <span>{t('exchange.open-voucher.price')}</span>
                                    </div>
                                    <div className="table__field"><span>{t('exchange.open-voucher.volume')} ({coinSymbol})</span></div>
                                    <div className="table__field"><span>{t('exchange.open-voucher.volume')} ({baseSymbol})</span></div>

                                    <div className="table__field"><span>{t('exchange.open-voucher.data')}</span></div>

                                    {/* {activeTab === 'history' && (
                                        <div className="table__field table__field--mini">
                                            <span></span>
                                        </div>
                                    )} */}
                                    </div>
                                    <div className="table__body history scroll-wrap-inner">
                                        {rootStore.ordersStore.orderHistory?.filter(_i=>(_i.tradedTurnover>0 && _i.tradedAmount>0)).map((one) => (
                                            <div className="table__row" key={one.orderId}>
                                                <div className="table__field"><span>{types[one.type]}</span></div>
                                                <div className={classNames('table__field', {
                                                    'success-stock': one.direction === 'BUY',
                                                    'warning': one.direction === 'SELL',
                                                })}>
                                                    <span>
                                                        {(one.direction === 'SELL') && t('exchange.open-voucher.sell')}
                                                        {(one.direction === 'BUY') && t('exchange.open-voucher.buy')}
                                                    </span>
                                                </div>
                                                {one.type === 'MARKET' && <div className={classNames('table__field', {
                                                    // success: one.direction === 'BUY',
                                                    // warning: one.direction === 'SELL',
                                                })}><span>{tf(one.price)}</span></div>}
                                                {one.type === 'LIMIT' && <div className={classNames('table__field', {
                                                    // success: one.direction === 'BUY',
                                                    // warning: one.direction === 'SELL',
                                                })}><span>{one.price}</span></div>}
                                                <div className={classNames('table__field', {
                                                    // success: one.direction === 'BUY',
                                                    // warning: one.direction === 'SELL',
                                                })}><span>{one.tradedAmount}</span></div>
                                                <div  className={classNames('table__field', {
                                                    // success: one.direction === 'BUY',
                                                    // warning: one.direction === 'SELL',
                                                })}><span>{one.tradedTurnover}</span></div>
                                                <div className="table__field">
                                                    <span>
                                                        {(DateTime.fromISO(one.time).toFormat('D') === DateTime.now().toFormat('D')) && (DateTime.fromISO(one.time).toFormat('t'))}
                                                        {(DateTime.fromISO(one.time).toFormat('D') !== DateTime.now().toFormat('D')) && (DateTime.fromISO(one.time).toFormat('D t'))}
                                                    </span>
                                                </div>
                                                {/* <div className="table__field">
                                                </div> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            {activeTab==="chart" &&
                            <div className="table__body scroll-wrap-inner">
                                <SelectTimeChart
                                    coinSymbol={coinSymbol}
                                    baseSymbol={baseSymbol}
                                />
                                {
                                    list ? (
                                        <ChartComponent
                                            data={list}
                                            timeFrameSelect={rootStore.coinBaze.getTimeDataFraimChart()}
                                        />
                                    ) : 'Нет данных'
                                }
                            </div>
                            }

                        </div>
                    </div>
                </div>
                <div className="tabs__content js-tab-content"/>

            </div>
        </div>
    )
}


export default observer(HistoryOrders)
