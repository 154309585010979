import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { withTranslation } from 'react-i18next';

import OTCIndex from "./otc/OTCIndex";
import OTCMyAds from "./otc/OTCMyAds";
import OTCDealsTab from "./otc/OTCDealsTab";
import OTCChatsTab from "./otc/OTCChatsTab";
import OTCDeal from "./otc/OTCDeal";
import { AuthStore } from "../stores";

interface IOTCPageProps {
    t: any;
}

interface IOTCPageState {
}

@observer
class OTCPage extends React.Component<IOTCPageProps, IOTCPageState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    render() {
        const { t } = this.props;
        return (
            <main className="main">
                <section className="otc-section">
                    <div className="container">
                        <div className="tabs">
                            <ul className="tabs__list tabs__list--otc">
                                <NavLink exact to='/otc' className="tabs__item">OTC</NavLink>
                                <NavLink to='/otc/ads' className="tabs__item">{t('otc.tabs.my-invitation')}</NavLink>
                                <NavLink to='/otc/deals' className={`tabs__item ${this.authStore.unreadMessages > 0 && 'tabs__item--active-message'}`}>{t('otc.tabs.my-trades')}</NavLink>
                                <NavLink to='/otc/chats' className="tabs__item">{t('otc.tabs.my-chat')}</NavLink>
                            </ul>
                            <Switch>
                                <Route exact path='/otc' component={OTCIndex} />
                                <Route path='/otc/ads' component={OTCMyAds} />
                                <Route exact path='/otc/deals' component={OTCDealsTab} />
                                <Route path='/otc/deals/:did' component={OTCDeal} />
                                <Route path='/otc/chats' component={OTCChatsTab} />
                                <Route path='/otc/chats/:cid' component={OTCChatsTab} />
                            </Switch>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}

export default withTranslation()(OTCPage);
