import {RootStore} from "./";
import {action, makeObservable, observable, runInAction} from "mobx";
import {injectable } from "inversify";
import {ExchangeOrdersType, ExchangeOrderType} from "../utils/graphql";
import { toUpper } from "lodash";


@injectable()
export class OrdersStore {

    @observable orderCurrent: ExchangeOrderType[];
    limit: number;
    pageNo: number;
    @observable orderHistory?: ExchangeOrderType[];
    @observable sell: ExchangeOrdersType[];
    @observable buy: ExchangeOrdersType[];
    @observable selectBuy: ExchangeOrdersType;
    @observable selectSell: ExchangeOrdersType;
    @observable maxCurrencyBuy: number;

    get api() {
        return this.rootStore.api;
    }

    public constructor(protected rootStore: RootStore) {
        makeObservable(this);
      
    }

    @action 
    setMaxCurrencyBuy(value) {
        this.maxCurrencyBuy = value;
    }

    
    @action 
    getMaxCurrencyBuy(symbol: string, limit: number) {

        this.maxCurrencyBuy;
    }

    @action
    async setSelectData(one: ExchangeOrdersType, direct: string) {
                var tt = {
                    amounts:0,
                    price:0,
                    turnovers:0
                }
                console.log(one)
            if (direct=="BUY") {    
               
                this.sell.map(item=>{
                        if (Number(item.price)<=Number(one.price)) {
                            tt.amounts += Number(item.amounts)
                            tt.price = item.price 
                            tt.turnovers += Number(item.turnovers)
                        }
                })
                tt.price = tt.turnovers / tt.amounts 

                runInAction(() => {
                    this.selectBuy = tt;
                })
            } else {
                this.buy.map(item=>{
                    if (Number(item.price)>=Number(one.price)) {
                        tt.amounts += Number(item.amounts)
                        tt.price = item.price 
                        tt.turnovers += Number(item.turnovers)
                    }
            })
            tt.price = tt.turnovers / tt.amounts 
                runInAction(() => {
                    this.selectSell = tt;
                })
            }
    }


    @action
    async getOrdersBook(symbol: string, limit: number) {
        const orders = await this.api.sdk.getBooks({symbol,limit})
        runInAction(() => {
                this.buy = orders.books.buy;
                this.sell = orders.books.sell;
            }
        );
    }


    @action
    async getOrdersCurrent(symbol: string, limit: number, pageNo: number) {
        const orders = await this.api.sdk.getOrderCurrent({symbol, limit, pageNo})
        this.orderCurrent = orders.orderCurrent.items;
        this.limit = orders.orderCurrent.limit;
        this.pageNo = orders.orderCurrent.pageNo;
        runInAction(() => {
                this.orderCurrent = orders.orderCurrent.items;
                this.limit = orders.orderCurrent.limit;
                this.pageNo = orders.orderCurrent.pageNo;
            }
        )
    }

    @action
    async getOrdersHistory(symbol: string, limit: number, pageNo: number) {
        const orders = await this.api.sdk.getOrderHistory({symbol, limit, pageNo})
        runInAction(() => {
                this.orderHistory = orders.orderHistory.items;
                this.limit = orders.orderHistory.limit;
                this.pageNo = orders.orderHistory.pageNo;
            }
        );
    }

    @action
    async cancelOrder(orderId: string) {
        let index = this.orderCurrent.findIndex((one)=>one.orderId==orderId)
        this.orderCurrent.slice(index);
        await this.api.sdk.onCancelOrder({pk: orderId})
    }

    @action
    async createOrder(symbol:string, direction: string, type: string, amount: number,price: number,turnover: number) {
        await this.api.sdk.createOrder({symbol, direction, type, amount,price,turnover})
    }

}
