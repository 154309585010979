import {observer} from 'mobx-react-lite';
import React, {FC, useEffect, useState} from 'react';

import { rootStore } from '../Application';

interface ISelectTimeChart {
    coinSymbol: string;
    baseSymbol: string;
}

const getFullNamesCurrency = (symbol) => {
    let result;
    switch(symbol) {
        case 'BTC':
            result = FullNamesCurrency.BTC;
            break
        case 'RUB':
            result = FullNamesCurrency.RUB;
            break
        case 'USDT':
            result = FullNamesCurrency.USDT;
            break
        case 'LTC':
            result = FullNamesCurrency.LTC;
            break
        case 'UZS':
            result = FullNamesCurrency.UZS;
            break
        case 'AED':
            result = FullNamesCurrency.AED;
            break
        case 'KZT':
            result = FullNamesCurrency.KZT;
            break
        case 'XMR':
            result = FullNamesCurrency.XMR;
            break
    }
        
    return result;
};

const FullNamesCurrency = {
    BTC: 'Bitcoin',
    RUB: 'Rouble',
    USDT: 'TetherUS',
    LTC: 'LiteCoin',
    UZS: 'Sum',
    AED: 'Dirham',
    KZT: 'Tenge',
    XMR: 'XMR'
}

const getComponentImageSymbol = (symbol) => {
    switch(symbol) {
        case 'BTC':
            return (
                <img src={require(`../images/btc.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'RUB':
            return (
                <img src={require(`../images/rub.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'USDT':
            return (
                <img src={require(`../images/usdt.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'LTC':
            return (
                <img src={require(`../images/ltc.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );  
        case 'UZS':
            return (
                <img src={require(`../images/uzs.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'AED':
            return (
                <img src={require(`../images/aed.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'KZT':
            return (
                <img src={require(`../images/kzt.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );
        case 'XMR':
            return (
                <img src={require(`../images/xmr.svg`)} width="14" height="14" className="chart-data__pair-img"/>
            );                        
    }
}

const timesFraim = ['15m', '15m', '30m', '1h', '1d'];

const SelectTimeChart: FC<ISelectTimeChart> = ({ coinSymbol, baseSymbol}) => {
    const [showOptionTime, setShowOptionTime] = useState(false);
    const [selectTitle, setSelectTitle] = useState('15M');
    const handleTitleSelect = () => {
        if (showOptionTime) {
            setShowOptionTime(false);
          } else {
            setShowOptionTime(true);
          }
    };

    const handleLabelSelect = (evt) => {
        setSelectTitle(evt.target.textContent);
        setShowOptionTime(() => (false));
    }

    const handleChange = (event) => {
        rootStore.coinBaze.setTimeDataFraimChart(event.target.value);
    };

    return (
        (<div className="chart-data">
            <div className="chart-data__pair">
                <div className="chart-data__pair-wrapper">
                    <div className="chart-data__pair-img-bottom">
                        {getComponentImageSymbol(baseSymbol)}
                    </div>
                    <div className="chart-data__pair-img-top">
                        {getComponentImageSymbol(coinSymbol)}
                    </div>
                </div>
                <span className="chart-data__pair-title">{getFullNamesCurrency(coinSymbol)} / {getFullNamesCurrency(baseSymbol)}</span>
            </div>
            <div className={`__select ${showOptionTime ? `__select--active` : ``}`}>
                <div onClick={handleTitleSelect}  className="__select__title">{selectTitle}</div>
                <div className="__select__content">
                {timesFraim.map((time, index) => {
                    return (
                        <React.Fragment key={`${time}-${index}`}>
                            <input onChange={handleChange} value={time} id={`singleSelect${index}`} className="__select__input" type="radio" name="singleSelect" />
                            <label onClick={handleLabelSelect} htmlFor={`singleSelect${index}`} className="__select__label">{time.toLocaleUpperCase()}</label>
                        </React.Fragment>
                    );
                })}
                </div>
            </div>
        </div>)
    );
};

export default observer(SelectTimeChart);